export const COUNTRIES_DETAILS = [
	{
		"COD_ID" : 259,
		"COD_CODE" : 840,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "21",
		"COD_FULL_BIRTHDAY" : 1
	},
	{
		"COD_ID" : 345,
		"COD_CODE" : 826,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 287,
		"COD_CODE" : 392,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "20",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 263,
		"COD_CODE" : 250,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 1
	},
	{
		"COD_ID" : 257,
		"COD_CODE" : 724,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 203,
		"COD_CODE" : 276,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 285,
		"COD_CODE" : 380,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 367,
		"COD_CODE" : 756,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 218,
		"COD_CODE" : 56,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 374,
		"COD_CODE" : 203,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 211,
		"COD_CODE" : 40,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 236,
		"COD_CODE" : 156,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 346,
		"COD_CODE" : 643,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 199,
		"COD_CODE" : 4,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 200,
		"COD_CODE" : 710,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 201,
		"COD_CODE" : 8,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 202,
		"COD_CODE" : 12,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 204,
		"COD_CODE" : 20,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 205,
		"COD_CODE" : 24,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 206,
		"COD_CODE" : 28,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 207,
		"COD_CODE" : 682,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "9999",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 208,
		"COD_CODE" : 32,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 209,
		"COD_CODE" : 51,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 210,
		"COD_CODE" : 36,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 212,
		"COD_CODE" : 31,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 213,
		"COD_CODE" : 44,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 214,
		"COD_CODE" : 48,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 215,
		"COD_CODE" : 50,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 216,
		"COD_CODE" : 52,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 217,
		"COD_CODE" : 112,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 219,
		"COD_CODE" : 84,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 220,
		"COD_CODE" : 204,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 221,
		"COD_CODE" : 64,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 222,
		"COD_CODE" : 68,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 223,
		"COD_CODE" : 70,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 224,
		"COD_CODE" : 72,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 225,
		"COD_CODE" : 76,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 226,
		"COD_CODE" : 96,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 227,
		"COD_CODE" : 100,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 228,
		"COD_CODE" : 854,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 229,
		"COD_CODE" : 108,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 230,
		"COD_CODE" : 116,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "21",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 231,
		"COD_CODE" : 120,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "21",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 232,
		"COD_CODE" : 124,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "19",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 233,
		"COD_CODE" : 132,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 234,
		"COD_CODE" : 140,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 235,
		"COD_CODE" : 152,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 237,
		"COD_CODE" : 196,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 238,
		"COD_CODE" : 170,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 239,
		"COD_CODE" : 174,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 240,
		"COD_CODE" : 178,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 241,
		"COD_CODE" : 180,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 242,
		"COD_CODE" : 410,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 243,
		"COD_CODE" : 408,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 244,
		"COD_CODE" : 188,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 245,
		"COD_CODE" : 384,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 246,
		"COD_CODE" : 191,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 247,
		"COD_CODE" : 192,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 248,
		"COD_CODE" : 208,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 249,
		"COD_CODE" : 262,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 250,
		"COD_CODE" : 214,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 251,
		"COD_CODE" : 212,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 252,
		"COD_CODE" : 818,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "21",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 253,
		"COD_CODE" : 222,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 254,
		"COD_CODE" : 784,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "21",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 255,
		"COD_CODE" : 218,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "21",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 256,
		"COD_CODE" : 232,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 258,
		"COD_CODE" : 233,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 260,
		"COD_CODE" : 231,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 261,
		"COD_CODE" : 242,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 262,
		"COD_CODE" : 246,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "20",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 264,
		"COD_CODE" : 266,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 265,
		"COD_CODE" : 270,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 266,
		"COD_CODE" : 268,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 267,
		"COD_CODE" : 288,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 268,
		"COD_CODE" : 300,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 269,
		"COD_CODE" : 308,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 270,
		"COD_CODE" : 320,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 271,
		"COD_CODE" : 324,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 272,
		"COD_CODE" : 624,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 273,
		"COD_CODE" : 226,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 274,
		"COD_CODE" : 328,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 275,
		"COD_CODE" : 332,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 338,
		"COD_CODE" : 528,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 276,
		"COD_CODE" : 340,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 392,
		"COD_CODE" : 348,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 277,
		"COD_CODE" : 711,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 278,
		"COD_CODE" : 356,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "9999",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 279,
		"COD_CODE" : 360,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "21",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 280,
		"COD_CODE" : 364,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 281,
		"COD_CODE" : 368,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 282,
		"COD_CODE" : 372,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 283,
		"COD_CODE" : 352,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 284,
		"COD_CODE" : 376,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 286,
		"COD_CODE" : 388,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 288,
		"COD_CODE" : 400,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 289,
		"COD_CODE" : 398,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 290,
		"COD_CODE" : 404,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 291,
		"COD_CODE" : 417,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 292,
		"COD_CODE" : 296,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 293,
		"COD_CODE" : 414,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 294,
		"COD_CODE" : 418,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 295,
		"COD_CODE" : 426,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 296,
		"COD_CODE" : 428,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 297,
		"COD_CODE" : 422,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 298,
		"COD_CODE" : 430,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 299,
		"COD_CODE" : 434,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 300,
		"COD_CODE" : 438,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 301,
		"COD_CODE" : 440,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 302,
		"COD_CODE" : 442,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 303,
		"COD_CODE" : 807,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 304,
		"COD_CODE" : 450,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 305,
		"COD_CODE" : 458,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "21",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 306,
		"COD_CODE" : 454,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 307,
		"COD_CODE" : 462,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 308,
		"COD_CODE" : 466,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 309,
		"COD_CODE" : 470,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 310,
		"COD_CODE" : 504,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 311,
		"COD_CODE" : 584,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 312,
		"COD_CODE" : 480,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 313,
		"COD_CODE" : 478,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 314,
		"COD_CODE" : 484,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 315,
		"COD_CODE" : 583,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 316,
		"COD_CODE" : 498,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 317,
		"COD_CODE" : 492,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 318,
		"COD_CODE" : 496,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 319,
		"COD_CODE" : 499,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 320,
		"COD_CODE" : 508,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 321,
		"COD_CODE" : 104,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 322,
		"COD_CODE" : 516,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 323,
		"COD_CODE" : 520,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 324,
		"COD_CODE" : 524,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 325,
		"COD_CODE" : 558,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "19",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 326,
		"COD_CODE" : 562,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 327,
		"COD_CODE" : 566,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 328,
		"COD_CODE" : 578,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "20",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 329,
		"COD_CODE" : 554,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 330,
		"COD_CODE" : 512,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 331,
		"COD_CODE" : 800,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 332,
		"COD_CODE" : 860,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 333,
		"COD_CODE" : 586,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 334,
		"COD_CODE" : 585,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 335,
		"COD_CODE" : 591,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 336,
		"COD_CODE" : 598,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 337,
		"COD_CODE" : 600,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	
	{
		"COD_ID" : 339,
		"COD_CODE" : 604,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 340,
		"COD_CODE" : 608,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 341,
		"COD_CODE" : 616,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 342,
		"COD_CODE" : 620,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 343,
		"COD_CODE" : 634,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "19",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 344,
		"COD_CODE" : 642,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 347,
		"COD_CODE" : 646,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 348,
		"COD_CODE" : 659,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 349,
		"COD_CODE" : 674,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 350,
		"COD_CODE" : 670,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 351,
		"COD_CODE" : 662,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 352,
		"COD_CODE" : 90,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 353,
		"COD_CODE" : 882,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 354,
		"COD_CODE" : 678,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 355,
		"COD_CODE" : 686,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 356,
		"COD_CODE" : 688,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 357,
		"COD_CODE" : 690,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 358,
		"COD_CODE" : 694,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 359,
		"COD_CODE" : 702,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "9999",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 360,
		"COD_CODE" : 703,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 361,
		"COD_CODE" : 705,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 362,
		"COD_CODE" : 706,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 363,
		"COD_CODE" : 729,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 364,
		"COD_CODE" : 728,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 365,
		"COD_CODE" : 144,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "21",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 366,
		"COD_CODE" : 752,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "20",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 368,
		"COD_CODE" : 740,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 369,
		"COD_CODE" : 748,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 370,
		"COD_CODE" : 760,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 371,
		"COD_CODE" : 762,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 393,
		"COD_CODE" : 118,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 372,
		"COD_CODE" : 834,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 373,
		"COD_CODE" : 148,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 375,
		"COD_CODE" : 764,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "20",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 376,
		"COD_CODE" : 626,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 377,
		"COD_CODE" : 768,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "21",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 378,
		"COD_CODE" : 776,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 379,
		"COD_CODE" : 780,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 380,
		"COD_CODE" : 788,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 381,
		"COD_CODE" : 795,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 382,
		"COD_CODE" : 792,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 383,
		"COD_CODE" : 798,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 384,
		"COD_CODE" : 804,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 385,
		"COD_CODE" : 858,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 386,
		"COD_CODE" : 548,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
  {
		"COD_ID" : 387,
		"COD_CODE" : 862,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 388,
		"COD_CODE" : 704,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 389,
		"COD_CODE" : 887,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 390,
		"COD_CODE" : 894,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	{
		"COD_ID" : 391,
		"COD_CODE" : 716,
		"COD_ADDRESS" : 1,
		"COD_DATE" : "JJ/MM/AAAA",
		"COD_AGE_ALCOOL" : "18",
		"COD_FULL_BIRTHDAY" : 0
	},
	];

export const COUNTRIES = [
	{
		"COU_CODE" : 4,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Afghanistan"
	},
	{
		"COU_CODE" : 4,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Afghanistan"
	},
	{
		"COU_CODE" : 4,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Afghanistan"
	},
	{
		"COU_CODE" : 4,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Afganistán"
	},
	{
		"COU_CODE" : 4,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Afghanistan"
	},
	{
		"COU_CODE" : 4,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Afeganistão"
	},
	{
		"COU_CODE" : 4,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Afghanistan"
	},
	{
		"COU_CODE" : 4,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Afganistan"
	},
	{
		"COU_CODE" : 4,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Афганистан"
	},
	{
		"COU_CODE" : 4,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "阿富汗"
	},
	{
		"COU_CODE" : 4,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "アフガニスタン"
	},
	{
		"COU_CODE" : 4,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "أفغانستان"
	},
	{
		"COU_CODE" : 4,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "아프가니스탄"
	},
	{
		"COU_CODE" : 4,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "อัฟกานิสถาน"
	},
	{
		"COU_CODE" : 8,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Albanie"
	},
	{
		"COU_CODE" : 8,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Albania"
	},
	{
		"COU_CODE" : 8,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Albanië"
	},
	{
		"COU_CODE" : 8,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Albania"
	},
	{
		"COU_CODE" : 8,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Albania"
	},
	{
		"COU_CODE" : 8,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Albânia"
	},
	{
		"COU_CODE" : 8,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Albanien"
	},
	{
		"COU_CODE" : 8,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Albania"
	},
	{
		"COU_CODE" : 8,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Албания"
	},
	{
		"COU_CODE" : 8,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "阿尔巴尼亚"
	},
	{
		"COU_CODE" : 8,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "アルバニア"
	},
	{
		"COU_CODE" : 8,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "ألبانيا"
	},
	{
		"COU_CODE" : 8,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "알바니아"
	},
	{
		"COU_CODE" : 8,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "แอลเบเนีย"
	},
	{
		"COU_CODE" : 12,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Algérie"
	},
	{
		"COU_CODE" : 12,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Algeria"
	},
	{
		"COU_CODE" : 12,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Algerije"
	},
	{
		"COU_CODE" : 12,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Argelia"
	},
	{
		"COU_CODE" : 12,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Algeria"
	},
	{
		"COU_CODE" : 12,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Argélia"
	},
	{
		"COU_CODE" : 12,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Algerien"
	},
	{
		"COU_CODE" : 12,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Algieria"
	},
	{
		"COU_CODE" : 12,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Алжир"
	},
	{
		"COU_CODE" : 12,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "阿尔及利亚"
	},
	{
		"COU_CODE" : 12,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "アルジェリア"
	},
	{
		"COU_CODE" : 12,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "الجزائر"
	},
	{
		"COU_CODE" : 12,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "알제리"
	},
	{
		"COU_CODE" : 12,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "แอลจีเรีย"
	},
	{
		"COU_CODE" : 20,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Andorre"
	},
	{
		"COU_CODE" : 20,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Andorra"
	},
	{
		"COU_CODE" : 20,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Andorra"
	},
	{
		"COU_CODE" : 20,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Andorra"
	},
	{
		"COU_CODE" : 20,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Andorra"
	},
	{
		"COU_CODE" : 20,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Andorra"
	},
	{
		"COU_CODE" : 20,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Andorra"
	},
	{
		"COU_CODE" : 20,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Andora"
	},
	{
		"COU_CODE" : 20,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Андорра"
	},
	{
		"COU_CODE" : 20,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "安道尔"
	},
	{
		"COU_CODE" : 20,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "アンドラ"
	},
	{
		"COU_CODE" : 20,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "أندورا"
	},
	{
		"COU_CODE" : 20,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "안도라"
	},
	{
		"COU_CODE" : 20,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "อันดอร์รา"
	},
	{
		"COU_CODE" : 24,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Angola"
	},
	{
		"COU_CODE" : 24,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Angola"
	},
	{
		"COU_CODE" : 24,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Angola"
	},
	{
		"COU_CODE" : 24,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Angola"
	},
	{
		"COU_CODE" : 24,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Angola"
	},
	{
		"COU_CODE" : 24,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Angola"
	},
	{
		"COU_CODE" : 24,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Angola"
	},
	{
		"COU_CODE" : 24,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Angola"
	},
	{
		"COU_CODE" : 24,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Ангола"
	},
	{
		"COU_CODE" : 24,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "安哥拉"
	},
	{
		"COU_CODE" : 24,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "アンゴラ"
	},
	{
		"COU_CODE" : 24,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "أنغولا"
	},
	{
		"COU_CODE" : 24,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "앙골라"
	},
	{
		"COU_CODE" : 24,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "แองโกลา"
	},
	{
		"COU_CODE" : 28,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Antigua-et-Barbuda"
	},
	{
		"COU_CODE" : 28,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Antigua and Barbuda"
	},
	{
		"COU_CODE" : 28,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Antigua en Barbuda"
	},
	{
		"COU_CODE" : 28,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Antigua y Barbuda"
	},
	{
		"COU_CODE" : 28,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Antigua e Barbuda"
	},
	{
		"COU_CODE" : 28,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Antígua e Barbuda"
	},
	{
		"COU_CODE" : 28,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Antigua und Barbuda"
	},
	{
		"COU_CODE" : 28,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Antigua i Barbuda"
	},
	{
		"COU_CODE" : 28,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Антигуа и Барбуда"
	},
	{
		"COU_CODE" : 28,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "安提瓜和巴布达"
	},
	{
		"COU_CODE" : 28,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "アンティグア・バーブーダ"
	},
	{
		"COU_CODE" : 28,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "أنتيغوا وباربودا"
	},
	{
		"COU_CODE" : 28,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "앤티가 바부다"
	},
	{
		"COU_CODE" : 28,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "แอนติกาและบาร์บูดา"
	},
	{
		"COU_CODE" : 31,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Azerbaïdjan"
	},
	{
		"COU_CODE" : 31,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Azerbaijan"
	},
	{
		"COU_CODE" : 31,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Azerbeidzjan"
	},
	{
		"COU_CODE" : 31,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Azerbaiyán"
	},
	{
		"COU_CODE" : 31,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Azerbaigian"
	},
	{
		"COU_CODE" : 31,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Azerbaijão"
	},
	{
		"COU_CODE" : 31,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Aserbaidschan"
	},
	{
		"COU_CODE" : 31,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Azerbejdżan"
	},
	{
		"COU_CODE" : 31,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Азербайджан"
	},
	{
		"COU_CODE" : 31,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "阿塞拜疆"
	},
	{
		"COU_CODE" : 31,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "アゼルバイジャン"
	},
	{
		"COU_CODE" : 31,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "أذربيجان"
	},
	{
		"COU_CODE" : 31,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "아제르바이잔"
	},
	{
		"COU_CODE" : 31,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "อาเซอร์ไบจาน"
	},
	{
		"COU_CODE" : 32,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Argentine"
	},
	{
		"COU_CODE" : 32,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Argentina"
	},
	{
		"COU_CODE" : 32,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Argentinië"
	},
	{
		"COU_CODE" : 32,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Argentina"
	},
	{
		"COU_CODE" : 32,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Argentina"
	},
	{
		"COU_CODE" : 32,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Argentina"
	},
	{
		"COU_CODE" : 32,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Argentinien"
	},
	{
		"COU_CODE" : 32,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Argentyna"
	},
	{
		"COU_CODE" : 32,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Аргентина"
	},
	{
		"COU_CODE" : 32,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "阿根廷"
	},
	{
		"COU_CODE" : 32,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "アルゼンチン"
	},
	{
		"COU_CODE" : 32,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "الأرجنتين"
	},
	{
		"COU_CODE" : 32,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "아르헨티나"
	},
	{
		"COU_CODE" : 32,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "อาร์เจนตินา"
	},
	{
		"COU_CODE" : 36,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Australie"
	},
	{
		"COU_CODE" : 36,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Australia"
	},
	{
		"COU_CODE" : 36,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Australië"
	},
	{
		"COU_CODE" : 36,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Australia"
	},
	{
		"COU_CODE" : 36,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Australia"
	},
	{
		"COU_CODE" : 36,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Austrália"
	},
	{
		"COU_CODE" : 36,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Australien"
	},
	{
		"COU_CODE" : 36,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Australia"
	},
	{
		"COU_CODE" : 36,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Австралия"
	},
	{
		"COU_CODE" : 36,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "澳大利亚"
	},
	{
		"COU_CODE" : 36,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "オーストラリア"
	},
	{
		"COU_CODE" : 36,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "أستراليا"
	},
	{
		"COU_CODE" : 36,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "오스트레일리아"
	},
	{
		"COU_CODE" : 36,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ออสเตรเลีย"
	},
	{
		"COU_CODE" : 40,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Autriche"
	},
	{
		"COU_CODE" : 40,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Austria"
	},
	{
		"COU_CODE" : 40,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Oostenrijk"
	},
	{
		"COU_CODE" : 40,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Austria"
	},
	{
		"COU_CODE" : 40,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Austria"
	},
	{
		"COU_CODE" : 40,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Áustria"
	},
	{
		"COU_CODE" : 40,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Österreich"
	},
	{
		"COU_CODE" : 40,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Austria"
	},
	{
		"COU_CODE" : 40,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Австрия"
	},
	{
		"COU_CODE" : 40,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "奥地利"
	},
	{
		"COU_CODE" : 40,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "オーストリア"
	},
	{
		"COU_CODE" : 40,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "النمسا"
	},
	{
		"COU_CODE" : 40,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "오스트리아"
	},
	{
		"COU_CODE" : 40,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ออสเตรีย"
	},
	{
		"COU_CODE" : 44,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Bahamas"
	},
	{
		"COU_CODE" : 44,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Bahamas"
	},
	{
		"COU_CODE" : 44,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Bahama's"
	},
	{
		"COU_CODE" : 44,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Bahamas"
	},
	{
		"COU_CODE" : 44,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Bahamas"
	},
	{
		"COU_CODE" : 44,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Bahamas"
	},
	{
		"COU_CODE" : 44,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Bahamas"
	},
	{
		"COU_CODE" : 44,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Bahamy"
	},
	{
		"COU_CODE" : 44,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Багамские Острова"
	},
	{
		"COU_CODE" : 44,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "巴哈马"
	},
	{
		"COU_CODE" : 44,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "バハマ"
	},
	{
		"COU_CODE" : 44,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "باهاماس"
	},
	{
		"COU_CODE" : 44,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "바하마"
	},
	{
		"COU_CODE" : 44,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "บาฮามาส"
	},
	{
		"COU_CODE" : 48,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Bahreïn"
	},
	{
		"COU_CODE" : 48,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Bahrain"
	},
	{
		"COU_CODE" : 48,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Bahrein"
	},
	{
		"COU_CODE" : 48,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Baréin"
	},
	{
		"COU_CODE" : 48,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Bahrein"
	},
	{
		"COU_CODE" : 48,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Bahrein"
	},
	{
		"COU_CODE" : 48,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Bahrain"
	},
	{
		"COU_CODE" : 48,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Bahrajn"
	},
	{
		"COU_CODE" : 48,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Бахрейн"
	},
	{
		"COU_CODE" : 48,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "巴林"
	},
	{
		"COU_CODE" : 48,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "バーレーン"
	},
	{
		"COU_CODE" : 48,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "البحرين"
	},
	{
		"COU_CODE" : 48,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "바레인"
	},
	{
		"COU_CODE" : 48,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "บาห์เรน"
	},
	{
		"COU_CODE" : 50,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Bangladesh"
	},
	{
		"COU_CODE" : 50,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Bangladesh"
	},
	{
		"COU_CODE" : 50,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Bangladesh"
	},
	{
		"COU_CODE" : 50,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Bangladés"
	},
	{
		"COU_CODE" : 50,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Bangladesh"
	},
	{
		"COU_CODE" : 50,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Bangladesh"
	},
	{
		"COU_CODE" : 50,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Bangladesch"
	},
	{
		"COU_CODE" : 50,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Bangladesz"
	},
	{
		"COU_CODE" : 50,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Бангладеш"
	},
	{
		"COU_CODE" : 50,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "孟加拉国"
	},
	{
		"COU_CODE" : 50,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "バングラデシュ"
	},
	{
		"COU_CODE" : 50,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "بنغلاديش"
	},
	{
		"COU_CODE" : 50,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "방글라데시"
	},
	{
		"COU_CODE" : 50,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "บังกลาเทศ"
	},
	{
		"COU_CODE" : 51,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Arménie"
	},
	{
		"COU_CODE" : 51,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Armenia"
	},
	{
		"COU_CODE" : 51,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Armenië"
	},
	{
		"COU_CODE" : 51,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Armenia"
	},
	{
		"COU_CODE" : 51,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Armenia"
	},
	{
		"COU_CODE" : 51,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Armênia"
	},
	{
		"COU_CODE" : 51,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Armenien"
	},
	{
		"COU_CODE" : 51,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Armenia"
	},
	{
		"COU_CODE" : 51,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Армения"
	},
	{
		"COU_CODE" : 51,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "亚美尼亚"
	},
	{
		"COU_CODE" : 51,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "アルメニア"
	},
	{
		"COU_CODE" : 51,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "أرمينيا"
	},
	{
		"COU_CODE" : 51,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "아르메니아"
	},
	{
		"COU_CODE" : 51,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "อาร์มีเนีย"
	},
	{
		"COU_CODE" : 52,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Barbade"
	},
	{
		"COU_CODE" : 52,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Barbados"
	},
	{
		"COU_CODE" : 52,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Barbados"
	},
	{
		"COU_CODE" : 52,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Barbados"
	},
	{
		"COU_CODE" : 52,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Barbados"
	},
	{
		"COU_CODE" : 52,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Barbados"
	},
	{
		"COU_CODE" : 52,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Barbados"
	},
	{
		"COU_CODE" : 52,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Barbados"
	},
	{
		"COU_CODE" : 52,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Барбадос"
	},
	{
		"COU_CODE" : 52,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "巴巴多斯"
	},
	{
		"COU_CODE" : 52,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "バルバドス"
	},
	{
		"COU_CODE" : 52,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "باربادوس"
	},
	{
		"COU_CODE" : 52,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "바베이도스"
	},
	{
		"COU_CODE" : 52,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "บาร์เบโดส"
	},
	{
		"COU_CODE" : 56,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Belgique"
	},
	{
		"COU_CODE" : 56,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Belgium"
	},
	{
		"COU_CODE" : 56,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "België"
	},
	{
		"COU_CODE" : 56,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Bélgica"
	},
	{
		"COU_CODE" : 56,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Belgio"
	},
	{
		"COU_CODE" : 56,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Bélgica"
	},
	{
		"COU_CODE" : 56,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Belgien"
	},
	{
		"COU_CODE" : 56,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Belgia"
	},
	{
		"COU_CODE" : 56,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Бельгия"
	},
	{
		"COU_CODE" : 56,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "比利时"
	},
	{
		"COU_CODE" : 56,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ベルギー"
	},
	{
		"COU_CODE" : 56,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "بلجيكا"
	},
	{
		"COU_CODE" : 56,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "벨기에"
	},
	{
		"COU_CODE" : 56,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เบลเยียม"
	},
	{
		"COU_CODE" : 64,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Bhoutan"
	},
	{
		"COU_CODE" : 64,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Bhutan"
	},
	{
		"COU_CODE" : 64,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Bhutan"
	},
	{
		"COU_CODE" : 64,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Bután"
	},
	{
		"COU_CODE" : 64,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Bhutan"
	},
	{
		"COU_CODE" : 64,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Butão"
	},
	{
		"COU_CODE" : 64,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Bhutan"
	},
	{
		"COU_CODE" : 64,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Bhutan"
	},
	{
		"COU_CODE" : 64,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Бутан"
	},
	{
		"COU_CODE" : 64,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "不丹"
	},
	{
		"COU_CODE" : 64,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ブータン"
	},
	{
		"COU_CODE" : 64,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "بوتان"
	},
	{
		"COU_CODE" : 64,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "부탄"
	},
	{
		"COU_CODE" : 64,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ภูฏาน"
	},
	{
		"COU_CODE" : 68,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Bolivie"
	},
	{
		"COU_CODE" : 68,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Bolivia (Plurinational State of)"
	},
	{
		"COU_CODE" : 68,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Bolivia"
	},
	{
		"COU_CODE" : 68,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Bolivia"
	},
	{
		"COU_CODE" : 68,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Bolivia"
	},
	{
		"COU_CODE" : 68,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Bolívia"
	},
	{
		"COU_CODE" : 68,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Bolivien"
	},
	{
		"COU_CODE" : 68,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Boliwia"
	},
	{
		"COU_CODE" : 68,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Боливия"
	},
	{
		"COU_CODE" : 68,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "玻利维亚"
	},
	{
		"COU_CODE" : 68,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ボリビア多民族国"
	},
	{
		"COU_CODE" : 68,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "بوليفيا"
	},
	{
		"COU_CODE" : 68,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "볼리비아"
	},
	{
		"COU_CODE" : 68,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "โบลิเวีย"
	},
	{
		"COU_CODE" : 70,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Bosnie-Herzégovine"
	},
	{
		"COU_CODE" : 70,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Bosnia and Herzegovina"
	},
	{
		"COU_CODE" : 70,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Bosnië en Herzegovina"
	},
	{
		"COU_CODE" : 70,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Bosnia y Herzegovina"
	},
	{
		"COU_CODE" : 70,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Bosnia ed Erzegovina"
	},
	{
		"COU_CODE" : 70,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Bósnia e Herzegovina"
	},
	{
		"COU_CODE" : 70,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Bosnien und Herzegowina"
	},
	{
		"COU_CODE" : 70,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Bośnia i Hercegowina"
	},
	{
		"COU_CODE" : 70,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Босния и Герцеговина"
	},
	{
		"COU_CODE" : 70,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "波黑"
	},
	{
		"COU_CODE" : 70,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ボスニア・ヘルツェゴビナ"
	},
	{
		"COU_CODE" : 70,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "البوسنة والهرسك"
	},
	{
		"COU_CODE" : 70,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "보스니아 헤르체고비나"
	},
	{
		"COU_CODE" : 70,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "บอสเนียและเฮอร์เซโกวีนา"
	},
	{
		"COU_CODE" : 72,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Botswana"
	},
	{
		"COU_CODE" : 72,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Botswana"
	},
	{
		"COU_CODE" : 72,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Botswana"
	},
	{
		"COU_CODE" : 72,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Botsuana"
	},
	{
		"COU_CODE" : 72,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Botswana"
	},
	{
		"COU_CODE" : 72,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Botswana"
	},
	{
		"COU_CODE" : 72,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Botswana"
	},
	{
		"COU_CODE" : 72,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Botswana"
	},
	{
		"COU_CODE" : 72,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Ботсвана"
	},
	{
		"COU_CODE" : 72,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "博茨瓦纳"
	},
	{
		"COU_CODE" : 72,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ボツワナ"
	},
	{
		"COU_CODE" : 72,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "بوتسوانا"
	},
	{
		"COU_CODE" : 72,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "보츠와나"
	},
	{
		"COU_CODE" : 72,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "บอตสวานา"
	},
	{
		"COU_CODE" : 76,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Brésil"
	},
	{
		"COU_CODE" : 76,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Brazil"
	},
	{
		"COU_CODE" : 76,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Brazilië"
	},
	{
		"COU_CODE" : 76,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Brasil"
	},
	{
		"COU_CODE" : 76,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Brasile"
	},
	{
		"COU_CODE" : 76,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Brasil"
	},
	{
		"COU_CODE" : 76,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Brasilien"
	},
	{
		"COU_CODE" : 76,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Brazylia"
	},
	{
		"COU_CODE" : 76,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Бразилия"
	},
	{
		"COU_CODE" : 76,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "巴西"
	},
	{
		"COU_CODE" : 76,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ブラジル"
	},
	{
		"COU_CODE" : 76,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "البرازيل"
	},
	{
		"COU_CODE" : 76,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "브라질"
	},
	{
		"COU_CODE" : 76,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "บราซิล"
	},
	{
		"COU_CODE" : 84,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Belize"
	},
	{
		"COU_CODE" : 84,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Belize"
	},
	{
		"COU_CODE" : 84,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Belize"
	},
	{
		"COU_CODE" : 84,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Belice"
	},
	{
		"COU_CODE" : 84,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Belize"
	},
	{
		"COU_CODE" : 84,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Belize"
	},
	{
		"COU_CODE" : 84,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Belize"
	},
	{
		"COU_CODE" : 84,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Belize"
	},
	{
		"COU_CODE" : 84,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Белиз"
	},
	{
		"COU_CODE" : 84,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "伯利兹"
	},
	{
		"COU_CODE" : 84,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ベリーズ"
	},
	{
		"COU_CODE" : 84,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "بليز"
	},
	{
		"COU_CODE" : 84,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "벨리즈"
	},
	{
		"COU_CODE" : 84,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เบลีซ"
	},
	{
		"COU_CODE" : 90,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Salomon"
	},
	{
		"COU_CODE" : 90,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Solomon Islands"
	},
	{
		"COU_CODE" : 90,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Salomonseilanden"
	},
	{
		"COU_CODE" : 90,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Islas Salomón"
	},
	{
		"COU_CODE" : 90,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Isole Salomone"
	},
	{
		"COU_CODE" : 90,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Ilhas Salomão"
	},
	{
		"COU_CODE" : 90,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Salomonen"
	},
	{
		"COU_CODE" : 90,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Wyspy Salomona"
	},
	{
		"COU_CODE" : 90,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Соломоновы Острова"
	},
	{
		"COU_CODE" : 90,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "所罗门群岛"
	},
	{
		"COU_CODE" : 90,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ソロモン諸島"
	},
	{
		"COU_CODE" : 90,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "جزر سليمان"
	},
	{
		"COU_CODE" : 90,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "솔로몬 제도"
	},
	{
		"COU_CODE" : 90,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "หมู่เกาะโซโลมอน"
	},
	{
		"COU_CODE" : 96,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Brunei"
	},
	{
		"COU_CODE" : 96,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Brunei Darussalam"
	},
	{
		"COU_CODE" : 96,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Brunei"
	},
	{
		"COU_CODE" : 96,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Brunéi"
	},
	{
		"COU_CODE" : 96,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Brunei"
	},
	{
		"COU_CODE" : 96,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Brunei"
	},
	{
		"COU_CODE" : 96,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Brunei"
	},
	{
		"COU_CODE" : 96,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Brunei"
	},
	{
		"COU_CODE" : 96,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Бруней"
	},
	{
		"COU_CODE" : 96,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "文莱"
	},
	{
		"COU_CODE" : 96,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ブルネイ・ダルサラーム"
	},
	{
		"COU_CODE" : 96,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "بروناي"
	},
	{
		"COU_CODE" : 96,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "브루나이"
	},
	{
		"COU_CODE" : 96,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "บรูไน"
	},
	{
		"COU_CODE" : 100,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Bulgarie"
	},
	{
		"COU_CODE" : 100,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Bulgaria"
	},
	{
		"COU_CODE" : 100,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Bulgarije"
	},
	{
		"COU_CODE" : 100,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Bulgaria"
	},
	{
		"COU_CODE" : 100,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Bulgaria"
	},
	{
		"COU_CODE" : 100,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Bulgária"
	},
	{
		"COU_CODE" : 100,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Bulgarien"
	},
	{
		"COU_CODE" : 100,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Bułgaria"
	},
	{
		"COU_CODE" : 100,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Болгария"
	},
	{
		"COU_CODE" : 100,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "保加利亚"
	},
	{
		"COU_CODE" : 100,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ブルガリア"
	},
	{
		"COU_CODE" : 100,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "بلغاريا"
	},
	{
		"COU_CODE" : 100,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "불가리아"
	},
	{
		"COU_CODE" : 100,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "บัลแกเรีย"
	},
	{
		"COU_CODE" : 104,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Birmanie"
	},
	{
		"COU_CODE" : 104,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Myanmar"
	},
	{
		"COU_CODE" : 104,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Myanmar"
	},
	{
		"COU_CODE" : 104,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Birmania"
	},
	{
		"COU_CODE" : 104,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Birmania"
	},
	{
		"COU_CODE" : 104,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Mianmar"
	},
	{
		"COU_CODE" : 104,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Myanmar"
	},
	{
		"COU_CODE" : 104,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Mjanma"
	},
	{
		"COU_CODE" : 104,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Мьянма"
	},
	{
		"COU_CODE" : 104,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "缅甸"
	},
	{
		"COU_CODE" : 104,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ミャンマー"
	},
	{
		"COU_CODE" : 104,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "ميانمار"
	},
	{
		"COU_CODE" : 104,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "미얀마"
	},
	{
		"COU_CODE" : 104,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "พม่า"
	},
	{
		"COU_CODE" : 108,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Burundi"
	},
	{
		"COU_CODE" : 108,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Burundi"
	},
	{
		"COU_CODE" : 108,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Burundi"
	},
	{
		"COU_CODE" : 108,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Burundi"
	},
	{
		"COU_CODE" : 108,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Burundi"
	},
	{
		"COU_CODE" : 108,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Burundi"
	},
	{
		"COU_CODE" : 108,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Burundi"
	},
	{
		"COU_CODE" : 108,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Burundi"
	},
	{
		"COU_CODE" : 108,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Бурунди"
	},
	{
		"COU_CODE" : 108,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "布隆迪"
	},
	{
		"COU_CODE" : 108,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ブルンジ"
	},
	{
		"COU_CODE" : 108,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "بوروندي"
	},
	{
		"COU_CODE" : 108,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "부룬디"
	},
	{
		"COU_CODE" : 108,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "บุรุนดี"
	},
	{
		"COU_CODE" : 112,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Biélorussie"
	},
	{
		"COU_CODE" : 112,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Belarus"
	},
	{
		"COU_CODE" : 112,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Wit-Rusland"
	},
	{
		"COU_CODE" : 112,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Bielorrusia"
	},
	{
		"COU_CODE" : 112,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Bielorussia"
	},
	{
		"COU_CODE" : 112,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Bielorrússia"
	},
	{
		"COU_CODE" : 112,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Weißrussland"
	},
	{
		"COU_CODE" : 112,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Białoruś"
	},
	{
		"COU_CODE" : 112,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Белоруссия"
	},
	{
		"COU_CODE" : 112,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "白俄罗斯"
	},
	{
		"COU_CODE" : 112,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ベラルーシ"
	},
	{
		"COU_CODE" : 112,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "روسيا البيضاء"
	},
	{
		"COU_CODE" : 112,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "벨라루스"
	},
	{
		"COU_CODE" : 112,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เบลารุส"
	},
	{
		"COU_CODE" : 116,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Cambodge"
	},
	{
		"COU_CODE" : 116,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Cambodia"
	},
	{
		"COU_CODE" : 116,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Cambodja"
	},
	{
		"COU_CODE" : 116,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Camboya"
	},
	{
		"COU_CODE" : 116,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Cambogia"
	},
	{
		"COU_CODE" : 116,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Camboja"
	},
	{
		"COU_CODE" : 116,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Kambodscha"
	},
	{
		"COU_CODE" : 116,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Kambodża"
	},
	{
		"COU_CODE" : 116,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Камбоджа"
	},
	{
		"COU_CODE" : 116,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "柬埔寨"
	},
	{
		"COU_CODE" : 116,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "カンボジア"
	},
	{
		"COU_CODE" : 116,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "كمبوديا"
	},
	{
		"COU_CODE" : 116,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "캄보디아"
	},
	{
		"COU_CODE" : 116,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "กัมพูชา"
	},
	{
		"COU_CODE" : 120,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Cameroun"
	},
	{
		"COU_CODE" : 120,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Cameroon"
	},
	{
		"COU_CODE" : 120,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Kameroen"
	},
	{
		"COU_CODE" : 120,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Camerún"
	},
	{
		"COU_CODE" : 120,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Camerun"
	},
	{
		"COU_CODE" : 120,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Camarões"
	},
	{
		"COU_CODE" : 120,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Kamerun"
	},
	{
		"COU_CODE" : 120,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Kamerun"
	},
	{
		"COU_CODE" : 120,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Камерун"
	},
	{
		"COU_CODE" : 120,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "喀麦隆"
	},
	{
		"COU_CODE" : 120,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "カメルーン"
	},
	{
		"COU_CODE" : 120,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "الكاميرون"
	},
	{
		"COU_CODE" : 120,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "카메룬"
	},
	{
		"COU_CODE" : 120,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "แคเมอรูน"
	},
	{
		"COU_CODE" : 124,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Canada"
	},
	{
		"COU_CODE" : 124,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Canada"
	},
	{
		"COU_CODE" : 124,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Canada"
	},
	{
		"COU_CODE" : 124,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Canadá"
	},
	{
		"COU_CODE" : 124,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Canada"
	},
	{
		"COU_CODE" : 124,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Canadá"
	},
	{
		"COU_CODE" : 124,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Kanada"
	},
	{
		"COU_CODE" : 124,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Kanada"
	},
	{
		"COU_CODE" : 124,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Канада"
	},
	{
		"COU_CODE" : 124,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "加拿大"
	},
	{
		"COU_CODE" : 124,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "カナダ"
	},
	{
		"COU_CODE" : 124,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "كندا"
	},
	{
		"COU_CODE" : 124,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "캐나다"
	},
	{
		"COU_CODE" : 124,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "แคนาดา"
	},
	{
		"COU_CODE" : 132,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Cap-Vert"
	},
	{
		"COU_CODE" : 132,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Cabo Verde"
	},
	{
		"COU_CODE" : 132,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Kaapverdië"
	},
	{
		"COU_CODE" : 132,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Cabo Verde"
	},
	{
		"COU_CODE" : 132,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Capo Verde"
	},
	{
		"COU_CODE" : 132,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Cabo Verde"
	},
	{
		"COU_CODE" : 132,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Kap Verde"
	},
	{
		"COU_CODE" : 132,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Republika Zielonego Przylądka"
	},
	{
		"COU_CODE" : 132,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Кабо-Верде"
	},
	{
		"COU_CODE" : 132,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "佛得角"
	},
	{
		"COU_CODE" : 132,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "カーボベルデ"
	},
	{
		"COU_CODE" : 132,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "الرأس الأخضر"
	},
	{
		"COU_CODE" : 132,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "카보베르데"
	},
	{
		"COU_CODE" : 132,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "กาบูเวร์ดี"
	},
	{
		"COU_CODE" : 140,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "République centrafricaine"
	},
	{
		"COU_CODE" : 140,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Central African Republic"
	},
	{
		"COU_CODE" : 140,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Centraal-Afrikaanse Republiek"
	},
	{
		"COU_CODE" : 140,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "República Centroafricana"
	},
	{
		"COU_CODE" : 140,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Rep. Centrafricana"
	},
	{
		"COU_CODE" : 140,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "República Centro-Africana"
	},
	{
		"COU_CODE" : 140,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Zentral­afrikanische Republik"
	},
	{
		"COU_CODE" : 140,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Republika Środkowoafrykańska"
	},
	{
		"COU_CODE" : 140,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "ЦАР"
	},
	{
		"COU_CODE" : 140,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "中非"
	},
	{
		"COU_CODE" : 140,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "中央アフリカ共和国"
	},
	{
		"COU_CODE" : 140,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "جمهورية أفريقيا الوسطى"
	},
	{
		"COU_CODE" : 140,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "중앙아프리카 공화국"
	},
	{
		"COU_CODE" : 140,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "สาธารณรัฐแอฟริกากลาง"
	},
	{
		"COU_CODE" : 144,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Sri Lanka"
	},
	{
		"COU_CODE" : 144,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Sri Lanka"
	},
	{
		"COU_CODE" : 144,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Sri Lanka"
	},
	{
		"COU_CODE" : 144,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Sri Lanka"
	},
	{
		"COU_CODE" : 144,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Sri Lanka"
	},
	{
		"COU_CODE" : 144,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Sri Lanka"
	},
	{
		"COU_CODE" : 144,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Sri Lanka"
	},
	{
		"COU_CODE" : 144,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Sri Lanka"
	},
	{
		"COU_CODE" : 144,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Шри-Ланка"
	},
	{
		"COU_CODE" : 144,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "斯里兰卡"
	},
	{
		"COU_CODE" : 144,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "スリランカ"
	},
	{
		"COU_CODE" : 144,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "سريلانكا"
	},
	{
		"COU_CODE" : 144,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "스리랑카"
	},
	{
		"COU_CODE" : 144,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ศรีลังกา"
	},
	{
		"COU_CODE" : 148,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Tchad"
	},
	{
		"COU_CODE" : 148,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Chad"
	},
	{
		"COU_CODE" : 148,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Tsjaad"
	},
	{
		"COU_CODE" : 148,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Chad"
	},
	{
		"COU_CODE" : 148,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Ciad"
	},
	{
		"COU_CODE" : 148,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Chade"
	},
	{
		"COU_CODE" : 148,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Tschad"
	},
	{
		"COU_CODE" : 148,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Czad"
	},
	{
		"COU_CODE" : 148,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Чад"
	},
	{
		"COU_CODE" : 148,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "乍得"
	},
	{
		"COU_CODE" : 148,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "チャド"
	},
	{
		"COU_CODE" : 148,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "تشاد"
	},
	{
		"COU_CODE" : 148,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "차드"
	},
	{
		"COU_CODE" : 148,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ชาด"
	},
	{
		"COU_CODE" : 152,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Chili"
	},
	{
		"COU_CODE" : 152,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Chile"
	},
	{
		"COU_CODE" : 152,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Chili"
	},
	{
		"COU_CODE" : 152,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Chile"
	},
	{
		"COU_CODE" : 152,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Cile"
	},
	{
		"COU_CODE" : 152,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Chile"
	},
	{
		"COU_CODE" : 152,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Chile"
	},
	{
		"COU_CODE" : 152,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Chile"
	},
	{
		"COU_CODE" : 152,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Чили"
	},
	{
		"COU_CODE" : 152,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "智利"
	},
	{
		"COU_CODE" : 152,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "チリ"
	},
	{
		"COU_CODE" : 152,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "تشيلي"
	},
	{
		"COU_CODE" : 152,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "칠레"
	},
	{
		"COU_CODE" : 152,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ชิลี"
	},
	{
		"COU_CODE" : 156,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Chine"
	},
	{
		"COU_CODE" : 156,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "China"
	},
	{
		"COU_CODE" : 156,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "China"
	},
	{
		"COU_CODE" : 156,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "China"
	},
	{
		"COU_CODE" : 156,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Cina"
	},
	{
		"COU_CODE" : 156,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "China"
	},
	{
		"COU_CODE" : 156,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Volksrepublik China"
	},
	{
		"COU_CODE" : 156,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Chiny"
	},
	{
		"COU_CODE" : 156,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Китай (Китайская Народная Республика)"
	},
	{
		"COU_CODE" : 156,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "中国"
	},
	{
		"COU_CODE" : 156,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "中華人民共和国"
	},
	{
		"COU_CODE" : 156,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "الصين"
	},
	{
		"COU_CODE" : 156,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "중국"
	},
	{
		"COU_CODE" : 156,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "จีน"
	},
	{
		"COU_CODE" : 170,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Colombie"
	},
	{
		"COU_CODE" : 170,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Colombia"
	},
	{
		"COU_CODE" : 170,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Colombia"
	},
	{
		"COU_CODE" : 170,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Colombia"
	},
	{
		"COU_CODE" : 170,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Colombia"
	},
	{
		"COU_CODE" : 170,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Colômbia"
	},
	{
		"COU_CODE" : 170,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Kolumbien"
	},
	{
		"COU_CODE" : 170,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Kolumbia"
	},
	{
		"COU_CODE" : 170,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Колумбия"
	},
	{
		"COU_CODE" : 170,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "哥伦比亚"
	},
	{
		"COU_CODE" : 170,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "コロンビア"
	},
	{
		"COU_CODE" : 170,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "كولومبيا"
	},
	{
		"COU_CODE" : 170,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "콜롬비아"
	},
	{
		"COU_CODE" : 170,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "โคลอมเบีย"
	},
	{
		"COU_CODE" : 174,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Comores (pays)"
	},
	{
		"COU_CODE" : 174,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Comoros"
	},
	{
		"COU_CODE" : 174,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Comoren"
	},
	{
		"COU_CODE" : 174,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Comoras"
	},
	{
		"COU_CODE" : 174,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Comore"
	},
	{
		"COU_CODE" : 174,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Comores"
	},
	{
		"COU_CODE" : 174,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Komoren"
	},
	{
		"COU_CODE" : 174,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Komory"
	},
	{
		"COU_CODE" : 174,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Коморы"
	},
	{
		"COU_CODE" : 174,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "科摩罗"
	},
	{
		"COU_CODE" : 174,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "コモロ"
	},
	{
		"COU_CODE" : 174,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "جزر القمر"
	},
	{
		"COU_CODE" : 174,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "코모로"
	},
	{
		"COU_CODE" : 174,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "คอโมโรส"
	},
	{
		"COU_CODE" : 178,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "République du Congo"
	},
	{
		"COU_CODE" : 178,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Congo"
	},
	{
		"COU_CODE" : 178,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Congo-Brazzaville"
	},
	{
		"COU_CODE" : 178,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "República del Congo"
	},
	{
		"COU_CODE" : 178,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Rep. del Congo"
	},
	{
		"COU_CODE" : 178,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "República do Congo"
	},
	{
		"COU_CODE" : 178,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Kongo, Republik"
	},
	{
		"COU_CODE" : 178,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Kongo"
	},
	{
		"COU_CODE" : 178,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Республика Конго"
	},
	{
		"COU_CODE" : 178,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "刚果共和国"
	},
	{
		"COU_CODE" : 178,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "コンゴ共和国"
	},
	{
		"COU_CODE" : 178,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "جمهورية الكونغو"
	},
	{
		"COU_CODE" : 178,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "콩고 공화국"
	},
	{
		"COU_CODE" : 178,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "สาธารณรัฐคองโก"
	},
	{
		"COU_CODE" : 180,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "République démocratique du Congo"
	},
	{
		"COU_CODE" : 180,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Congo, Democratic Republic of the"
	},
	{
		"COU_CODE" : 180,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Congo-Kinshasa"
	},
	{
		"COU_CODE" : 180,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "República Democrática del Congo"
	},
	{
		"COU_CODE" : 180,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "RD del Congo"
	},
	{
		"COU_CODE" : 180,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "República Democrática do Congo"
	},
	{
		"COU_CODE" : 180,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Kongo, Demokratische Republik"
	},
	{
		"COU_CODE" : 180,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Demokratyczna Republika Konga"
	},
	{
		"COU_CODE" : 180,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "ДР Конго"
	},
	{
		"COU_CODE" : 180,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "刚果民主共和国"
	},
	{
		"COU_CODE" : 180,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "コンゴ民主共和国"
	},
	{
		"COU_CODE" : 180,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "جمهورية الكونغو الديمقراطية"
	},
	{
		"COU_CODE" : 180,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "콩고 민주 공화국"
	},
	{
		"COU_CODE" : 180,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "สาธารณรัฐประชาธิปไตยคองโก"
	},
	{
		"COU_CODE" : 188,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Costa Rica"
	},
	{
		"COU_CODE" : 188,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Costa Rica"
	},
	{
		"COU_CODE" : 188,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Costa Rica"
	},
	{
		"COU_CODE" : 188,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Costa Rica"
	},
	{
		"COU_CODE" : 188,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Costa Rica"
	},
	{
		"COU_CODE" : 188,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Costa Rica"
	},
	{
		"COU_CODE" : 188,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Costa Rica"
	},
	{
		"COU_CODE" : 188,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Kostaryka"
	},
	{
		"COU_CODE" : 188,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Коста-Рика"
	},
	{
		"COU_CODE" : 188,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "哥斯达黎加"
	},
	{
		"COU_CODE" : 188,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "コスタリカ"
	},
	{
		"COU_CODE" : 188,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "كوستاريكا"
	},
	{
		"COU_CODE" : 188,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "코스타리카"
	},
	{
		"COU_CODE" : 188,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "คอสตาริกา"
	},
	{
		"COU_CODE" : 191,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Croatie"
	},
	{
		"COU_CODE" : 191,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Croatia"
	},
	{
		"COU_CODE" : 191,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Kroatië"
	},
	{
		"COU_CODE" : 191,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Croacia"
	},
	{
		"COU_CODE" : 191,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Croazia"
	},
	{
		"COU_CODE" : 191,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Croácia"
	},
	{
		"COU_CODE" : 191,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Kroatien"
	},
	{
		"COU_CODE" : 191,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Chorwacja"
	},
	{
		"COU_CODE" : 191,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Хорватия"
	},
	{
		"COU_CODE" : 191,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "克罗地亚"
	},
	{
		"COU_CODE" : 191,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "クロアチア"
	},
	{
		"COU_CODE" : 191,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "كرواتيا"
	},
	{
		"COU_CODE" : 191,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "크로아티아"
	},
	{
		"COU_CODE" : 191,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "โครเอเชีย"
	},
	{
		"COU_CODE" : 192,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Cuba"
	},
	{
		"COU_CODE" : 192,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Cuba"
	},
	{
		"COU_CODE" : 192,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Cuba"
	},
	{
		"COU_CODE" : 192,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Cuba"
	},
	{
		"COU_CODE" : 192,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Cuba"
	},
	{
		"COU_CODE" : 192,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Cuba"
	},
	{
		"COU_CODE" : 192,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Kuba"
	},
	{
		"COU_CODE" : 192,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Kuba"
	},
	{
		"COU_CODE" : 192,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Куба"
	},
	{
		"COU_CODE" : 192,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "古巴"
	},
	{
		"COU_CODE" : 192,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "キューバ"
	},
	{
		"COU_CODE" : 192,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "كوبا"
	},
	{
		"COU_CODE" : 192,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "쿠바"
	},
	{
		"COU_CODE" : 192,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "คิวบา"
	},
	{
		"COU_CODE" : 196,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Chypre (pays)"
	},
	{
		"COU_CODE" : 196,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Cyprus"
	},
	{
		"COU_CODE" : 196,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Cyprus"
	},
	{
		"COU_CODE" : 196,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Chipre"
	},
	{
		"COU_CODE" : 196,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Cipro"
	},
	{
		"COU_CODE" : 196,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Chipre"
	},
	{
		"COU_CODE" : 196,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Zypern"
	},
	{
		"COU_CODE" : 196,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Cypr"
	},
	{
		"COU_CODE" : 196,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Кипр"
	},
	{
		"COU_CODE" : 196,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "塞浦路斯"
	},
	{
		"COU_CODE" : 196,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "キプロス"
	},
	{
		"COU_CODE" : 196,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "قبرص"
	},
	{
		"COU_CODE" : 196,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "키프로스"
	},
	{
		"COU_CODE" : 196,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ไซปรัส"
	},
	{
		"COU_CODE" : 203,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Tchéquie"
	},
	{
		"COU_CODE" : 203,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Czechia"
	},
	{
		"COU_CODE" : 203,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Tsjechië"
	},
	{
		"COU_CODE" : 203,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "República Checa"
	},
	{
		"COU_CODE" : 203,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Rep. Ceca"
	},
	{
		"COU_CODE" : 203,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Chéquia"
	},
	{
		"COU_CODE" : 203,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Tschechien"
	},
	{
		"COU_CODE" : 203,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Czechy"
	},
	{
		"COU_CODE" : 203,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Чехия"
	},
	{
		"COU_CODE" : 203,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "捷克"
	},
	{
		"COU_CODE" : 203,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "チェコ"
	},
	{
		"COU_CODE" : 203,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "جمهورية التشيك"
	},
	{
		"COU_CODE" : 203,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "체코"
	},
	{
		"COU_CODE" : 203,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เช็กเกีย"
	},
	{
		"COU_CODE" : 204,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Bénin"
	},
	{
		"COU_CODE" : 204,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Benin"
	},
	{
		"COU_CODE" : 204,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Benin"
	},
	{
		"COU_CODE" : 204,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Benín"
	},
	{
		"COU_CODE" : 204,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Benin"
	},
	{
		"COU_CODE" : 204,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Benim"
	},
	{
		"COU_CODE" : 204,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Benin"
	},
	{
		"COU_CODE" : 204,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Benin"
	},
	{
		"COU_CODE" : 204,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Бенин"
	},
	{
		"COU_CODE" : 204,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "贝宁"
	},
	{
		"COU_CODE" : 204,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ベナン"
	},
	{
		"COU_CODE" : 204,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "بنين"
	},
	{
		"COU_CODE" : 204,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "베냉"
	},
	{
		"COU_CODE" : 204,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เบนิน"
	},
	{
		"COU_CODE" : 208,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Danemark"
	},
	{
		"COU_CODE" : 208,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Denmark"
	},
	{
		"COU_CODE" : 208,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Denemarken"
	},
	{
		"COU_CODE" : 208,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Dinamarca"
	},
	{
		"COU_CODE" : 208,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Danimarca"
	},
	{
		"COU_CODE" : 208,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Dinamarca"
	},
	{
		"COU_CODE" : 208,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Dänemark"
	},
	{
		"COU_CODE" : 208,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Dania"
	},
	{
		"COU_CODE" : 208,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Дания"
	},
	{
		"COU_CODE" : 208,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "丹麦"
	},
	{
		"COU_CODE" : 208,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "デンマーク"
	},
	{
		"COU_CODE" : 208,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "الدنمارك"
	},
	{
		"COU_CODE" : 208,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "덴마크"
	},
	{
		"COU_CODE" : 208,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เดนมาร์ก"
	},
	{
		"COU_CODE" : 212,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Dominique"
	},
	{
		"COU_CODE" : 212,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Dominica"
	},
	{
		"COU_CODE" : 212,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Dominica"
	},
	{
		"COU_CODE" : 212,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Dominica"
	},
	{
		"COU_CODE" : 212,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Dominica"
	},
	{
		"COU_CODE" : 212,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Dominica"
	},
	{
		"COU_CODE" : 212,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Dominica"
	},
	{
		"COU_CODE" : 212,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Dominika"
	},
	{
		"COU_CODE" : 212,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Доминика"
	},
	{
		"COU_CODE" : 212,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "多米尼克"
	},
	{
		"COU_CODE" : 212,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ドミニカ国"
	},
	{
		"COU_CODE" : 212,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "دومينيكا"
	},
	{
		"COU_CODE" : 212,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "도미니카 연방"
	},
	{
		"COU_CODE" : 212,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ดอมินีกา"
	},
	{
		"COU_CODE" : 214,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "République dominicaine"
	},
	{
		"COU_CODE" : 214,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Dominican Republic"
	},
	{
		"COU_CODE" : 214,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Dominicaanse Republiek"
	},
	{
		"COU_CODE" : 214,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "República Dominicana"
	},
	{
		"COU_CODE" : 214,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Rep. Dominicana"
	},
	{
		"COU_CODE" : 214,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "República Dominicana"
	},
	{
		"COU_CODE" : 214,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Dominikanische Republik"
	},
	{
		"COU_CODE" : 214,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Dominikana"
	},
	{
		"COU_CODE" : 214,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Доминиканская Республика"
	},
	{
		"COU_CODE" : 214,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "多米尼加"
	},
	{
		"COU_CODE" : 214,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ドミニカ共和国"
	},
	{
		"COU_CODE" : 214,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "جمهورية الدومينيكان"
	},
	{
		"COU_CODE" : 214,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "도미니카 공화국"
	},
	{
		"COU_CODE" : 214,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "สาธารณรัฐโดมินิกัน"
	},
	{
		"COU_CODE" : 218,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Équateur (pays)"
	},
	{
		"COU_CODE" : 218,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Ecuador"
	},
	{
		"COU_CODE" : 218,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Ecuador"
	},
	{
		"COU_CODE" : 218,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Ecuador"
	},
	{
		"COU_CODE" : 218,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Ecuador"
	},
	{
		"COU_CODE" : 218,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Equador"
	},
	{
		"COU_CODE" : 218,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Ecuador"
	},
	{
		"COU_CODE" : 218,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Ekwador"
	},
	{
		"COU_CODE" : 218,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Эквадор"
	},
	{
		"COU_CODE" : 218,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "厄瓜多尔"
	},
	{
		"COU_CODE" : 218,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "エクアドル"
	},
	{
		"COU_CODE" : 218,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "الإكوادور"
	},
	{
		"COU_CODE" : 218,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "에콰도르"
	},
	{
		"COU_CODE" : 218,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เอกวาดอร์"
	},
	{
		"COU_CODE" : 222,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Salvador"
	},
	{
		"COU_CODE" : 222,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "El Salvador"
	},
	{
		"COU_CODE" : 222,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "El Salvador"
	},
	{
		"COU_CODE" : 222,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "El Salvador"
	},
	{
		"COU_CODE" : 222,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "El Salvador"
	},
	{
		"COU_CODE" : 222,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "El Salvador"
	},
	{
		"COU_CODE" : 222,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "El Salvador"
	},
	{
		"COU_CODE" : 222,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Salwador"
	},
	{
		"COU_CODE" : 222,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Сальвадор"
	},
	{
		"COU_CODE" : 222,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "萨尔瓦多"
	},
	{
		"COU_CODE" : 222,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "エルサルバドル"
	},
	{
		"COU_CODE" : 222,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "السلفادور"
	},
	{
		"COU_CODE" : 222,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "엘살바도르"
	},
	{
		"COU_CODE" : 222,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เอลซัลวาดอร์"
	},
	{
		"COU_CODE" : 226,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Guinée équatoriale"
	},
	{
		"COU_CODE" : 226,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Equatorial Guinea"
	},
	{
		"COU_CODE" : 226,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Equatoriaal-Guinea"
	},
	{
		"COU_CODE" : 226,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Guinea Ecuatorial"
	},
	{
		"COU_CODE" : 226,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Guinea Equatoriale"
	},
	{
		"COU_CODE" : 226,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Guiné Equatorial"
	},
	{
		"COU_CODE" : 226,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Äquatorialguinea"
	},
	{
		"COU_CODE" : 226,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Gwinea Równikowa"
	},
	{
		"COU_CODE" : 226,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Экваториальная Гвинея"
	},
	{
		"COU_CODE" : 226,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "赤道几内亚"
	},
	{
		"COU_CODE" : 226,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "赤道ギニア"
	},
	{
		"COU_CODE" : 226,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "غينيا الاستوائية"
	},
	{
		"COU_CODE" : 226,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "적도 기니"
	},
	{
		"COU_CODE" : 226,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "อิเควทอเรียลกินี"
	},
	{
		"COU_CODE" : 231,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Éthiopie"
	},
	{
		"COU_CODE" : 231,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Ethiopia"
	},
	{
		"COU_CODE" : 231,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Ethiopië"
	},
	{
		"COU_CODE" : 231,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Etiopía"
	},
	{
		"COU_CODE" : 231,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Etiopia"
	},
	{
		"COU_CODE" : 231,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Etiópia"
	},
	{
		"COU_CODE" : 231,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Äthiopien"
	},
	{
		"COU_CODE" : 231,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Etiopia"
	},
	{
		"COU_CODE" : 231,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Эфиопия"
	},
	{
		"COU_CODE" : 231,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "埃塞俄比亚"
	},
	{
		"COU_CODE" : 231,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "エチオピア"
	},
	{
		"COU_CODE" : 231,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "إثيوبيا"
	},
	{
		"COU_CODE" : 231,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "에티오피아"
	},
	{
		"COU_CODE" : 231,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เอธิโอเปีย"
	},
	{
		"COU_CODE" : 232,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Érythrée"
	},
	{
		"COU_CODE" : 232,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Eritrea"
	},
	{
		"COU_CODE" : 232,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Eritrea"
	},
	{
		"COU_CODE" : 232,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Eritrea"
	},
	{
		"COU_CODE" : 232,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Eritrea"
	},
	{
		"COU_CODE" : 232,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Eritreia"
	},
	{
		"COU_CODE" : 232,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Eritrea"
	},
	{
		"COU_CODE" : 232,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Erytrea"
	},
	{
		"COU_CODE" : 232,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Эритрея"
	},
	{
		"COU_CODE" : 232,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "厄立特里亚"
	},
	{
		"COU_CODE" : 232,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "エリトリア"
	},
	{
		"COU_CODE" : 232,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "إريتريا"
	},
	{
		"COU_CODE" : 232,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "에리트레아"
	},
	{
		"COU_CODE" : 232,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เอริเทรีย"
	},
	{
		"COU_CODE" : 233,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Estonie"
	},
	{
		"COU_CODE" : 233,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Estonia"
	},
	{
		"COU_CODE" : 233,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Estland"
	},
	{
		"COU_CODE" : 233,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Estonia"
	},
	{
		"COU_CODE" : 233,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Estonia"
	},
	{
		"COU_CODE" : 233,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Estónia"
	},
	{
		"COU_CODE" : 233,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Estland"
	},
	{
		"COU_CODE" : 233,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Estonia"
	},
	{
		"COU_CODE" : 233,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Эстония"
	},
	{
		"COU_CODE" : 233,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "爱沙尼亚"
	},
	{
		"COU_CODE" : 233,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "エストニア"
	},
	{
		"COU_CODE" : 233,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "إستونيا"
	},
	{
		"COU_CODE" : 233,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "에스토니아"
	},
	{
		"COU_CODE" : 233,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เอสโตเนีย"
	},
	{
		"COU_CODE" : 242,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Fidji"
	},
	{
		"COU_CODE" : 242,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Fiji"
	},
	{
		"COU_CODE" : 242,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Fiji"
	},
	{
		"COU_CODE" : 242,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Fiyi"
	},
	{
		"COU_CODE" : 242,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Figi"
	},
	{
		"COU_CODE" : 242,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Fiji"
	},
	{
		"COU_CODE" : 242,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Fidschi"
	},
	{
		"COU_CODE" : 242,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Fidżi"
	},
	{
		"COU_CODE" : 242,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Фиджи"
	},
	{
		"COU_CODE" : 242,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "斐济"
	},
	{
		"COU_CODE" : 242,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "フィジー"
	},
	{
		"COU_CODE" : 242,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "فيجي"
	},
	{
		"COU_CODE" : 242,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "피지"
	},
	{
		"COU_CODE" : 242,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ฟีจี"
	},
	{
		"COU_CODE" : 246,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Finlande"
	},
	{
		"COU_CODE" : 246,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Finland"
	},
	{
		"COU_CODE" : 246,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Finland"
	},
	{
		"COU_CODE" : 246,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Finlandia"
	},
	{
		"COU_CODE" : 246,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Finlandia"
	},
	{
		"COU_CODE" : 246,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Finlândia"
	},
	{
		"COU_CODE" : 246,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Finnland"
	},
	{
		"COU_CODE" : 246,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Finlandia"
	},
	{
		"COU_CODE" : 246,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Финляндия"
	},
	{
		"COU_CODE" : 246,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "芬兰"
	},
	{
		"COU_CODE" : 246,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "フィンランド"
	},
	{
		"COU_CODE" : 246,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "فنلندا"
	},
	{
		"COU_CODE" : 246,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "핀란드"
	},
	{
		"COU_CODE" : 246,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ฟินแลนด์"
	},
	{
		"COU_CODE" : 250,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "France"
	},
	{
		"COU_CODE" : 250,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "France"
	},
	{
		"COU_CODE" : 250,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Frankrijk"
	},
	{
		"COU_CODE" : 250,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Francia"
	},
	{
		"COU_CODE" : 250,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Francia"
	},
	{
		"COU_CODE" : 250,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "França"
	},
	{
		"COU_CODE" : 250,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Frankreich"
	},
	{
		"COU_CODE" : 250,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Francja"
	},
	{
		"COU_CODE" : 250,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Франция"
	},
	{
		"COU_CODE" : 250,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "法国"
	},
	{
		"COU_CODE" : 250,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "フランス"
	},
	{
		"COU_CODE" : 250,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "فرنسا"
	},
	{
		"COU_CODE" : 250,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "프랑스"
	},
	{
		"COU_CODE" : 250,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ฝรั่งเศส"
	},
	{
		"COU_CODE" : 262,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Djibouti"
	},
	{
		"COU_CODE" : 262,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Djibouti"
	},
	{
		"COU_CODE" : 262,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Djibouti"
	},
	{
		"COU_CODE" : 262,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Yibuti"
	},
	{
		"COU_CODE" : 262,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Gibuti"
	},
	{
		"COU_CODE" : 262,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Djibouti"
	},
	{
		"COU_CODE" : 262,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Dschibuti"
	},
	{
		"COU_CODE" : 262,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Dżibuti"
	},
	{
		"COU_CODE" : 262,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Джибути"
	},
	{
		"COU_CODE" : 262,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "吉布提"
	},
	{
		"COU_CODE" : 262,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ジブチ"
	},
	{
		"COU_CODE" : 262,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "جيبوتي"
	},
	{
		"COU_CODE" : 262,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "지부티"
	},
	{
		"COU_CODE" : 262,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "จิบูตี"
	},
	{
		"COU_CODE" : 266,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Gabon"
	},
	{
		"COU_CODE" : 266,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Gabon"
	},
	{
		"COU_CODE" : 266,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Gabon"
	},
	{
		"COU_CODE" : 266,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Gabón"
	},
	{
		"COU_CODE" : 266,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Gabon"
	},
	{
		"COU_CODE" : 266,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Gabão"
	},
	{
		"COU_CODE" : 266,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Gabun"
	},
	{
		"COU_CODE" : 266,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Gabon"
	},
	{
		"COU_CODE" : 266,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Габон"
	},
	{
		"COU_CODE" : 266,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "加蓬"
	},
	{
		"COU_CODE" : 266,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ガボン"
	},
	{
		"COU_CODE" : 266,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "الغابون"
	},
	{
		"COU_CODE" : 266,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "가봉"
	},
	{
		"COU_CODE" : 266,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "กาบอง"
	},
	{
		"COU_CODE" : 268,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Géorgie (pays)"
	},
	{
		"COU_CODE" : 268,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Georgia"
	},
	{
		"COU_CODE" : 268,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Georgië"
	},
	{
		"COU_CODE" : 268,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Georgia"
	},
	{
		"COU_CODE" : 268,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Georgia"
	},
	{
		"COU_CODE" : 268,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Geórgia"
	},
	{
		"COU_CODE" : 268,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Georgien"
	},
	{
		"COU_CODE" : 268,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Gruzja"
	},
	{
		"COU_CODE" : 268,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Грузия"
	},
	{
		"COU_CODE" : 268,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "格鲁吉亚"
	},
	{
		"COU_CODE" : 268,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ジョージア"
	},
	{
		"COU_CODE" : 268,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "جورجيا"
	},
	{
		"COU_CODE" : 268,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "조지아"
	},
	{
		"COU_CODE" : 268,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "จอร์เจีย"
	},
	{
		"COU_CODE" : 270,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Gambie"
	},
	{
		"COU_CODE" : 270,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Gambia"
	},
	{
		"COU_CODE" : 270,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Gambia"
	},
	{
		"COU_CODE" : 270,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Gambia"
	},
	{
		"COU_CODE" : 270,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Gambia"
	},
	{
		"COU_CODE" : 270,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Gâmbia"
	},
	{
		"COU_CODE" : 270,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Gambia"
	},
	{
		"COU_CODE" : 270,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Gambia"
	},
	{
		"COU_CODE" : 270,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Гамбия"
	},
	{
		"COU_CODE" : 270,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "冈比亚"
	},
	{
		"COU_CODE" : 270,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ガンビア"
	},
	{
		"COU_CODE" : 270,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "غامبيا"
	},
	{
		"COU_CODE" : 270,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "감비아"
	},
	{
		"COU_CODE" : 270,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "แกมเบีย"
	},
	{
		"COU_CODE" : 276,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Allemagne"
	},
	{
		"COU_CODE" : 276,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Germany"
	},
	{
		"COU_CODE" : 276,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Duitsland"
	},
	{
		"COU_CODE" : 276,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Alemania"
	},
	{
		"COU_CODE" : 276,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Germania"
	},
	{
		"COU_CODE" : 276,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Alemanha"
	},
	{
		"COU_CODE" : 276,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Deutschland"
	},
	{
		"COU_CODE" : 276,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Niemcy"
	},
	{
		"COU_CODE" : 276,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Германия"
	},
	{
		"COU_CODE" : 276,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "德国"
	},
	{
		"COU_CODE" : 276,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ドイツ"
	},
	{
		"COU_CODE" : 276,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "ألمانيا"
	},
	{
		"COU_CODE" : 276,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "독일"
	},
	{
		"COU_CODE" : 276,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เยอรมนี"
	},
	{
		"COU_CODE" : 288,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Ghana"
	},
	{
		"COU_CODE" : 288,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Ghana"
	},
	{
		"COU_CODE" : 288,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Ghana"
	},
	{
		"COU_CODE" : 288,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Ghana"
	},
	{
		"COU_CODE" : 288,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Ghana"
	},
	{
		"COU_CODE" : 288,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Gana"
	},
	{
		"COU_CODE" : 288,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Ghana"
	},
	{
		"COU_CODE" : 288,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Ghana"
	},
	{
		"COU_CODE" : 288,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Гана"
	},
	{
		"COU_CODE" : 288,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "加纳"
	},
	{
		"COU_CODE" : 288,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ガーナ"
	},
	{
		"COU_CODE" : 288,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "غانا"
	},
	{
		"COU_CODE" : 288,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "가나"
	},
	{
		"COU_CODE" : 288,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "กานา"
	},
	{
		"COU_CODE" : 296,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Kiribati"
	},
	{
		"COU_CODE" : 296,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Kiribati"
	},
	{
		"COU_CODE" : 296,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Kiribati"
	},
	{
		"COU_CODE" : 296,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Kiribati"
	},
	{
		"COU_CODE" : 296,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Kiribati"
	},
	{
		"COU_CODE" : 296,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Kiribati"
	},
	{
		"COU_CODE" : 296,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Kiribati"
	},
	{
		"COU_CODE" : 296,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Kiribati"
	},
	{
		"COU_CODE" : 296,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Кирибати"
	},
	{
		"COU_CODE" : 296,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "基里巴斯"
	},
	{
		"COU_CODE" : 296,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "キリバス"
	},
	{
		"COU_CODE" : 296,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "كيريباتي"
	},
	{
		"COU_CODE" : 296,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "키리바시"
	},
	{
		"COU_CODE" : 296,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "คิริบาส"
	},
	{
		"COU_CODE" : 300,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Grèce"
	},
	{
		"COU_CODE" : 300,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Greece"
	},
	{
		"COU_CODE" : 300,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Griekenland"
	},
	{
		"COU_CODE" : 300,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Grecia"
	},
	{
		"COU_CODE" : 300,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Grecia"
	},
	{
		"COU_CODE" : 300,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Grécia"
	},
	{
		"COU_CODE" : 300,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Griechenland"
	},
	{
		"COU_CODE" : 300,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Grecja"
	},
	{
		"COU_CODE" : 300,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Греция"
	},
	{
		"COU_CODE" : 300,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "希腊"
	},
	{
		"COU_CODE" : 300,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ギリシャ"
	},
	{
		"COU_CODE" : 300,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "اليونان"
	},
	{
		"COU_CODE" : 300,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "그리스"
	},
	{
		"COU_CODE" : 300,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "กรีซ"
	},
	{
		"COU_CODE" : 308,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Grenade (pays)"
	},
	{
		"COU_CODE" : 308,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Grenada"
	},
	{
		"COU_CODE" : 308,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Grenada"
	},
	{
		"COU_CODE" : 308,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Granada"
	},
	{
		"COU_CODE" : 308,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Grenada"
	},
	{
		"COU_CODE" : 308,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Granada"
	},
	{
		"COU_CODE" : 308,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Grenada"
	},
	{
		"COU_CODE" : 308,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Grenada"
	},
	{
		"COU_CODE" : 308,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Гренада"
	},
	{
		"COU_CODE" : 308,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "格林纳达"
	},
	{
		"COU_CODE" : 308,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "グレナダ"
	},
	{
		"COU_CODE" : 308,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "غرينادا"
	},
	{
		"COU_CODE" : 308,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "그레나다"
	},
	{
		"COU_CODE" : 308,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เกรเนดา"
	},
	{
		"COU_CODE" : 320,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Guatemala"
	},
	{
		"COU_CODE" : 320,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Guatemala"
	},
	{
		"COU_CODE" : 320,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Guatemala"
	},
	{
		"COU_CODE" : 320,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Guatemala"
	},
	{
		"COU_CODE" : 320,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Guatemala"
	},
	{
		"COU_CODE" : 320,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Guatemala"
	},
	{
		"COU_CODE" : 320,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Guatemala"
	},
	{
		"COU_CODE" : 320,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Gwatemala"
	},
	{
		"COU_CODE" : 320,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Гватемала"
	},
	{
		"COU_CODE" : 320,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "危地马拉"
	},
	{
		"COU_CODE" : 320,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "グアテマラ"
	},
	{
		"COU_CODE" : 320,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "غواتيمالا"
	},
	{
		"COU_CODE" : 320,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "과테말라"
	},
	{
		"COU_CODE" : 320,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "กัวเตมาลา"
	},
	{
		"COU_CODE" : 324,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Guinée"
	},
	{
		"COU_CODE" : 324,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Guinea"
	},
	{
		"COU_CODE" : 324,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Guinee"
	},
	{
		"COU_CODE" : 324,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Guinea"
	},
	{
		"COU_CODE" : 324,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Guinea"
	},
	{
		"COU_CODE" : 324,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Guiné"
	},
	{
		"COU_CODE" : 324,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Guinea"
	},
	{
		"COU_CODE" : 324,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Gwinea"
	},
	{
		"COU_CODE" : 324,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Гвинея"
	},
	{
		"COU_CODE" : 324,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "几内亚"
	},
	{
		"COU_CODE" : 324,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ギニア"
	},
	{
		"COU_CODE" : 324,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "غينيا"
	},
	{
		"COU_CODE" : 324,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "기니"
	},
	{
		"COU_CODE" : 324,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "กินี"
	},
	{
		"COU_CODE" : 328,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Guyana"
	},
	{
		"COU_CODE" : 328,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Guyana"
	},
	{
		"COU_CODE" : 328,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Guyana"
	},
	{
		"COU_CODE" : 328,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Guyana"
	},
	{
		"COU_CODE" : 328,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Guyana"
	},
	{
		"COU_CODE" : 328,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Guiana"
	},
	{
		"COU_CODE" : 328,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Guyana"
	},
	{
		"COU_CODE" : 328,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Gujana"
	},
	{
		"COU_CODE" : 328,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Гайана"
	},
	{
		"COU_CODE" : 328,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "圭亚那"
	},
	{
		"COU_CODE" : 328,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ガイアナ"
	},
	{
		"COU_CODE" : 328,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "غيانا"
	},
	{
		"COU_CODE" : 328,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "가이아나"
	},
	{
		"COU_CODE" : 328,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "กายอานา"
	},
	{
		"COU_CODE" : 332,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Haïti"
	},
	{
		"COU_CODE" : 332,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Haiti"
	},
	{
		"COU_CODE" : 332,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Haïti"
	},
	{
		"COU_CODE" : 332,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Haití"
	},
	{
		"COU_CODE" : 332,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Haiti"
	},
	{
		"COU_CODE" : 332,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Haiti"
	},
	{
		"COU_CODE" : 332,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Haiti"
	},
	{
		"COU_CODE" : 332,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Haiti"
	},
	{
		"COU_CODE" : 332,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Гаити"
	},
	{
		"COU_CODE" : 332,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "海地"
	},
	{
		"COU_CODE" : 332,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ハイチ"
	},
	{
		"COU_CODE" : 332,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "هايتي"
	},
	{
		"COU_CODE" : 332,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "아이티"
	},
	{
		"COU_CODE" : 332,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เฮติ"
	},
	{
		"COU_CODE" : 528,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Hollande"
	},
	{
		"COU_CODE" : 528,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Netherland"
	},
	{
		"COU_CODE" : 528,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Netherland"
	},
	{
		"COU_CODE" : 340,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Honduras"
	},
	{
		"COU_CODE" : 340,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Honduras"
	},
	{
		"COU_CODE" : 340,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Honduras"
	},
	{
		"COU_CODE" : 340,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Honduras"
	},
	{
		"COU_CODE" : 340,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Honduras"
	},
	{
		"COU_CODE" : 340,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Honduras"
	},
	{
		"COU_CODE" : 340,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Honduras"
	},
	{
		"COU_CODE" : 340,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Honduras"
	},
	{
		"COU_CODE" : 340,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Гондурас"
	},
	{
		"COU_CODE" : 340,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "洪都拉斯"
	},
	{
		"COU_CODE" : 340,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ホンジュラス"
	},
	{
		"COU_CODE" : 340,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "هندوراس"
	},
	{
		"COU_CODE" : 340,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "온두라스"
	},
	{
		"COU_CODE" : 340,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ฮอนดูรัส"
	},
	{
		"COU_CODE" : 348,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Hongrie"
	},
	{
		"COU_CODE" : 348,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Hungary"
	},
	{
		"COU_CODE" : 348,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Hongarije"
	},
	{
		"COU_CODE" : 348,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Hungría"
	},
	{
		"COU_CODE" : 348,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Ungheria"
	},
	{
		"COU_CODE" : 348,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Hungria"
	},
	{
		"COU_CODE" : 348,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Ungarn"
	},
	{
		"COU_CODE" : 348,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Węgry"
	},
	{
		"COU_CODE" : 348,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Венгрия"
	},
	{
		"COU_CODE" : 348,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "匈牙利"
	},
	{
		"COU_CODE" : 348,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ハンガリー"
	},
	{
		"COU_CODE" : 348,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "المجر"
	},
	{
		"COU_CODE" : 348,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "헝가리"
	},
	{
		"COU_CODE" : 348,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ฮังการี"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 6,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 15,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 18,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 19,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 20,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 711,
		"COU_LANGUAGE" : 21,
		"COU_NAME" : "Hong Kong"
	},
	{
		"COU_CODE" : 352,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Islande"
	},
	{
		"COU_CODE" : 352,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Iceland"
	},
	{
		"COU_CODE" : 352,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "IJsland"
	},
	{
		"COU_CODE" : 352,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Islandia"
	},
	{
		"COU_CODE" : 352,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Islanda"
	},
	{
		"COU_CODE" : 352,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Islândia"
	},
	{
		"COU_CODE" : 352,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Island"
	},
	{
		"COU_CODE" : 352,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Islandia"
	},
	{
		"COU_CODE" : 352,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Исландия"
	},
	{
		"COU_CODE" : 352,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "冰岛"
	},
	{
		"COU_CODE" : 352,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "アイスランド"
	},
	{
		"COU_CODE" : 352,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "آيسلندا"
	},
	{
		"COU_CODE" : 352,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "아이슬란드"
	},
	{
		"COU_CODE" : 352,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ไอซ์แลนด์"
	},
	{
		"COU_CODE" : 356,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Inde"
	},
	{
		"COU_CODE" : 356,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "India"
	},
	{
		"COU_CODE" : 356,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "India"
	},
	{
		"COU_CODE" : 356,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "India"
	},
	{
		"COU_CODE" : 356,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "India"
	},
	{
		"COU_CODE" : 356,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Índia"
	},
	{
		"COU_CODE" : 356,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Indien"
	},
	{
		"COU_CODE" : 356,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Indie"
	},
	{
		"COU_CODE" : 356,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Индия"
	},
	{
		"COU_CODE" : 356,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "印度"
	},
	{
		"COU_CODE" : 356,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "インド"
	},
	{
		"COU_CODE" : 356,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "الهند"
	},
	{
		"COU_CODE" : 356,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "인도"
	},
	{
		"COU_CODE" : 356,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "อินเดีย"
	},
	{
		"COU_CODE" : 360,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Indonésie"
	},
	{
		"COU_CODE" : 360,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Indonesia"
	},
	{
		"COU_CODE" : 360,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Indonesië"
	},
	{
		"COU_CODE" : 360,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Indonesia"
	},
	{
		"COU_CODE" : 360,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Indonesia"
	},
	{
		"COU_CODE" : 360,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Indonésia"
	},
	{
		"COU_CODE" : 360,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Indonesien"
	},
	{
		"COU_CODE" : 360,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Indonezja"
	},
	{
		"COU_CODE" : 360,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Индонезия"
	},
	{
		"COU_CODE" : 360,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "印尼"
	},
	{
		"COU_CODE" : 360,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "インドネシア"
	},
	{
		"COU_CODE" : 360,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "إندونيسيا"
	},
	{
		"COU_CODE" : 360,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "인도네시아"
	},
	{
		"COU_CODE" : 360,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "อินโดนีเซีย"
	},
	{
		"COU_CODE" : 364,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Iran"
	},
	{
		"COU_CODE" : 364,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Iran (Islamic Republic of)"
	},
	{
		"COU_CODE" : 364,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Iran"
	},
	{
		"COU_CODE" : 364,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Irán"
	},
	{
		"COU_CODE" : 364,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Iran"
	},
	{
		"COU_CODE" : 364,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Irão"
	},
	{
		"COU_CODE" : 364,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Iran"
	},
	{
		"COU_CODE" : 364,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Iran"
	},
	{
		"COU_CODE" : 364,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Иран"
	},
	{
		"COU_CODE" : 364,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "伊朗"
	},
	{
		"COU_CODE" : 364,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "イラン・イスラム共和国"
	},
	{
		"COU_CODE" : 364,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "إيران"
	},
	{
		"COU_CODE" : 364,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "이란"
	},
	{
		"COU_CODE" : 364,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "อิหร่าน"
	},
	{
		"COU_CODE" : 368,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Irak"
	},
	{
		"COU_CODE" : 368,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Iraq"
	},
	{
		"COU_CODE" : 368,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Irak"
	},
	{
		"COU_CODE" : 368,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Irak"
	},
	{
		"COU_CODE" : 368,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Iraq"
	},
	{
		"COU_CODE" : 368,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Iraque"
	},
	{
		"COU_CODE" : 368,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Irak"
	},
	{
		"COU_CODE" : 368,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Irak"
	},
	{
		"COU_CODE" : 368,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Ирак"
	},
	{
		"COU_CODE" : 368,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "伊拉克"
	},
	{
		"COU_CODE" : 368,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "イラク"
	},
	{
		"COU_CODE" : 368,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "العراق"
	},
	{
		"COU_CODE" : 368,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "이라크"
	},
	{
		"COU_CODE" : 368,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "อิรัก"
	},
	{
		"COU_CODE" : 372,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Irlande (pays)"
	},
	{
		"COU_CODE" : 372,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Ireland"
	},
	{
		"COU_CODE" : 372,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Ierland"
	},
	{
		"COU_CODE" : 372,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Irlanda"
	},
	{
		"COU_CODE" : 372,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Irlanda"
	},
	{
		"COU_CODE" : 372,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Irlanda"
	},
	{
		"COU_CODE" : 372,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Irland"
	},
	{
		"COU_CODE" : 372,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Irlandia"
	},
	{
		"COU_CODE" : 372,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Ирландия"
	},
	{
		"COU_CODE" : 372,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "爱尔兰"
	},
	{
		"COU_CODE" : 372,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "アイルランド"
	},
	{
		"COU_CODE" : 372,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "أيرلندا"
	},
	{
		"COU_CODE" : 372,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "아일랜드"
	},
	{
		"COU_CODE" : 372,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ไอร์แลนด์"
	},
	{
		"COU_CODE" : 376,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Israël"
	},
	{
		"COU_CODE" : 376,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Israel"
	},
	{
		"COU_CODE" : 376,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Israël"
	},
	{
		"COU_CODE" : 376,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Israel"
	},
	{
		"COU_CODE" : 376,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Israele"
	},
	{
		"COU_CODE" : 376,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Israel"
	},
	{
		"COU_CODE" : 376,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Israel"
	},
	{
		"COU_CODE" : 376,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Izrael"
	},
	{
		"COU_CODE" : 376,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Израиль"
	},
	{
		"COU_CODE" : 376,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "以色列"
	},
	{
		"COU_CODE" : 376,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "イスラエル"
	},
	{
		"COU_CODE" : 376,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "إسرائيل"
	},
	{
		"COU_CODE" : 376,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "이스라엘"
	},
	{
		"COU_CODE" : 376,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "อิสราเอล"
	},
	{
		"COU_CODE" : 380,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Italie"
	},
	{
		"COU_CODE" : 380,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Italy"
	},
	{
		"COU_CODE" : 380,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Italië"
	},
	{
		"COU_CODE" : 380,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Italia"
	},
	{
		"COU_CODE" : 380,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Italia"
	},
	{
		"COU_CODE" : 380,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Itália"
	},
	{
		"COU_CODE" : 380,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Italien"
	},
	{
		"COU_CODE" : 380,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Włochy"
	},
	{
		"COU_CODE" : 380,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Италия"
	},
	{
		"COU_CODE" : 380,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "意大利"
	},
	{
		"COU_CODE" : 380,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "イタリア"
	},
	{
		"COU_CODE" : 380,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "إيطاليا"
	},
	{
		"COU_CODE" : 380,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "이탈리아"
	},
	{
		"COU_CODE" : 380,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "อิตาลี"
	},
	{
		"COU_CODE" : 384,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Côte d'Ivoire"
	},
	{
		"COU_CODE" : 384,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Côte d'Ivoire"
	},
	{
		"COU_CODE" : 384,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Ivoorkust"
	},
	{
		"COU_CODE" : 384,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Costa de Marfil"
	},
	{
		"COU_CODE" : 384,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Costa d'Avorio"
	},
	{
		"COU_CODE" : 384,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Costa do Marfim"
	},
	{
		"COU_CODE" : 384,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Elfenbeinküste"
	},
	{
		"COU_CODE" : 384,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Wybrzeże Kości Słoniowej"
	},
	{
		"COU_CODE" : 384,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Кот-д’Ивуар"
	},
	{
		"COU_CODE" : 384,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "科特迪瓦"
	},
	{
		"COU_CODE" : 384,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "コートジボワール"
	},
	{
		"COU_CODE" : 384,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "ساحل العاج"
	},
	{
		"COU_CODE" : 384,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "코트디부아르"
	},
	{
		"COU_CODE" : 384,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "โกตดิวัวร์"
	},
	{
		"COU_CODE" : 388,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Jamaïque"
	},
	{
		"COU_CODE" : 388,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Jamaica"
	},
	{
		"COU_CODE" : 388,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Jamaica"
	},
	{
		"COU_CODE" : 388,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Jamaica"
	},
	{
		"COU_CODE" : 388,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Giamaica"
	},
	{
		"COU_CODE" : 388,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Jamaica"
	},
	{
		"COU_CODE" : 388,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Jamaika"
	},
	{
		"COU_CODE" : 388,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Jamajka"
	},
	{
		"COU_CODE" : 388,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Ямайка"
	},
	{
		"COU_CODE" : 388,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "牙买加"
	},
	{
		"COU_CODE" : 388,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ジャマイカ"
	},
	{
		"COU_CODE" : 388,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "جامايكا"
	},
	{
		"COU_CODE" : 388,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "자메이카"
	},
	{
		"COU_CODE" : 388,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "จาเมกา"
	},
	{
		"COU_CODE" : 392,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Japon"
	},
	{
		"COU_CODE" : 392,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Japan"
	},
	{
		"COU_CODE" : 392,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Japan"
	},
	{
		"COU_CODE" : 392,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Japón"
	},
	{
		"COU_CODE" : 392,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Giappone"
	},
	{
		"COU_CODE" : 392,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Japão"
	},
	{
		"COU_CODE" : 392,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Japan"
	},
	{
		"COU_CODE" : 392,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Japonia"
	},
	{
		"COU_CODE" : 392,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Япония"
	},
	{
		"COU_CODE" : 392,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "日本"
	},
	{
		"COU_CODE" : 392,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "日本"
	},
	{
		"COU_CODE" : 392,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "اليابان"
	},
	{
		"COU_CODE" : 392,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "일본"
	},
	{
		"COU_CODE" : 392,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ญี่ปุ่น"
	},
	{
		"COU_CODE" : 398,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Kazakhstan"
	},
	{
		"COU_CODE" : 398,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Kazakhstan"
	},
	{
		"COU_CODE" : 398,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Kazachstan"
	},
	{
		"COU_CODE" : 398,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Kazajistán"
	},
	{
		"COU_CODE" : 398,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Kazakistan"
	},
	{
		"COU_CODE" : 398,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Cazaquistão"
	},
	{
		"COU_CODE" : 398,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Kasachstan"
	},
	{
		"COU_CODE" : 398,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Kazachstan"
	},
	{
		"COU_CODE" : 398,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Казахстан"
	},
	{
		"COU_CODE" : 398,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "哈萨克斯坦"
	},
	{
		"COU_CODE" : 398,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "カザフスタン"
	},
	{
		"COU_CODE" : 398,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "كازاخستان"
	},
	{
		"COU_CODE" : 398,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "카자흐스탄"
	},
	{
		"COU_CODE" : 398,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "คาซัคสถาน"
	},
	{
		"COU_CODE" : 400,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Jordanie"
	},
	{
		"COU_CODE" : 400,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Jordan"
	},
	{
		"COU_CODE" : 400,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Jordanië"
	},
	{
		"COU_CODE" : 400,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Jordania"
	},
	{
		"COU_CODE" : 400,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Giordania"
	},
	{
		"COU_CODE" : 400,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Jordânia"
	},
	{
		"COU_CODE" : 400,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Jordanien"
	},
	{
		"COU_CODE" : 400,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Jordania"
	},
	{
		"COU_CODE" : 400,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Иордания"
	},
	{
		"COU_CODE" : 400,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "约旦"
	},
	{
		"COU_CODE" : 400,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ヨルダン"
	},
	{
		"COU_CODE" : 400,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "الأردن"
	},
	{
		"COU_CODE" : 400,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "요르단"
	},
	{
		"COU_CODE" : 400,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "จอร์แดน"
	},
	{
		"COU_CODE" : 404,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Kenya"
	},
	{
		"COU_CODE" : 404,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Kenya"
	},
	{
		"COU_CODE" : 404,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Kenia"
	},
	{
		"COU_CODE" : 404,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Kenia"
	},
	{
		"COU_CODE" : 404,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Kenya"
	},
	{
		"COU_CODE" : 404,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Quênia"
	},
	{
		"COU_CODE" : 404,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Kenia"
	},
	{
		"COU_CODE" : 404,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Kenia"
	},
	{
		"COU_CODE" : 404,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Кения"
	},
	{
		"COU_CODE" : 404,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "肯尼亚"
	},
	{
		"COU_CODE" : 404,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ケニア"
	},
	{
		"COU_CODE" : 404,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "كينيا"
	},
	{
		"COU_CODE" : 404,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "케냐"
	},
	{
		"COU_CODE" : 404,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เคนยา"
	},
	{
		"COU_CODE" : 408,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Corée du Nord"
	},
	{
		"COU_CODE" : 408,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Korea (Democratic People's Republic of)"
	},
	{
		"COU_CODE" : 408,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Noord-Korea"
	},
	{
		"COU_CODE" : 408,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Corea del Norte"
	},
	{
		"COU_CODE" : 408,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Corea del Nord"
	},
	{
		"COU_CODE" : 408,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Coreia do Norte"
	},
	{
		"COU_CODE" : 408,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Korea, Nord"
	},
	{
		"COU_CODE" : 408,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Korea Północna"
	},
	{
		"COU_CODE" : 408,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "КНДР (Корейская Народно-Демократическая Республика)"
	},
	{
		"COU_CODE" : 408,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "朝鲜"
	},
	{
		"COU_CODE" : 408,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "朝鮮民主主義人民共和国"
	},
	{
		"COU_CODE" : 408,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "كوريا الشمالية"
	},
	{
		"COU_CODE" : 408,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "조선민주주의인민공화국"
	},
	{
		"COU_CODE" : 408,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เกาหลีเหนือ"
	},
	{
		"COU_CODE" : 410,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Corée du Sud"
	},
	{
		"COU_CODE" : 410,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Korea, Republic of"
	},
	{
		"COU_CODE" : 410,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Zuid-Korea"
	},
	{
		"COU_CODE" : 410,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Corea del Sur"
	},
	{
		"COU_CODE" : 410,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Corea del Sud"
	},
	{
		"COU_CODE" : 410,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Coreia do Sul"
	},
	{
		"COU_CODE" : 410,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Korea, Süd"
	},
	{
		"COU_CODE" : 410,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Korea Południowa"
	},
	{
		"COU_CODE" : 410,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Республика Корея"
	},
	{
		"COU_CODE" : 410,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "韩国"
	},
	{
		"COU_CODE" : 410,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "大韓民国"
	},
	{
		"COU_CODE" : 410,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "كوريا الجنوبية"
	},
	{
		"COU_CODE" : 410,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "대한민국"
	},
	{
		"COU_CODE" : 410,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เกาหลีใต้"
	},
	{
		"COU_CODE" : 414,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Koweït"
	},
	{
		"COU_CODE" : 414,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Kuwait"
	},
	{
		"COU_CODE" : 414,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Koeweit"
	},
	{
		"COU_CODE" : 414,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Kuwait"
	},
	{
		"COU_CODE" : 414,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Kuwait"
	},
	{
		"COU_CODE" : 414,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Kuwait"
	},
	{
		"COU_CODE" : 414,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Kuwait"
	},
	{
		"COU_CODE" : 414,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Kuwejt"
	},
	{
		"COU_CODE" : 414,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Кувейт"
	},
	{
		"COU_CODE" : 414,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "科威特"
	},
	{
		"COU_CODE" : 414,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "クウェート"
	},
	{
		"COU_CODE" : 414,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "الكويت"
	},
	{
		"COU_CODE" : 414,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "쿠웨이트"
	},
	{
		"COU_CODE" : 414,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "คูเวต"
	},
	{
		"COU_CODE" : 417,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Kirghizistan"
	},
	{
		"COU_CODE" : 417,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Kyrgyzstan"
	},
	{
		"COU_CODE" : 417,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Kirgizië"
	},
	{
		"COU_CODE" : 417,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Kirguistán"
	},
	{
		"COU_CODE" : 417,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Kirghizistan"
	},
	{
		"COU_CODE" : 417,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Quirguistão"
	},
	{
		"COU_CODE" : 417,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Kirgisistan"
	},
	{
		"COU_CODE" : 417,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Kirgistan"
	},
	{
		"COU_CODE" : 417,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Киргизия"
	},
	{
		"COU_CODE" : 417,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "吉尔吉斯斯坦"
	},
	{
		"COU_CODE" : 417,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "キルギス"
	},
	{
		"COU_CODE" : 417,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "قيرغيزستان"
	},
	{
		"COU_CODE" : 417,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "키르기스스탄"
	},
	{
		"COU_CODE" : 417,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "คีร์กีซสถาน"
	},
	{
		"COU_CODE" : 418,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Laos"
	},
	{
		"COU_CODE" : 418,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Lao People's Democratic Republic"
	},
	{
		"COU_CODE" : 418,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Laos"
	},
	{
		"COU_CODE" : 418,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Laos"
	},
	{
		"COU_CODE" : 418,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Laos"
	},
	{
		"COU_CODE" : 418,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Laos"
	},
	{
		"COU_CODE" : 418,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Laos"
	},
	{
		"COU_CODE" : 418,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Laos"
	},
	{
		"COU_CODE" : 418,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Лаос"
	},
	{
		"COU_CODE" : 418,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "老挝"
	},
	{
		"COU_CODE" : 418,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ラオス人民民主共和国"
	},
	{
		"COU_CODE" : 418,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "لاوس"
	},
	{
		"COU_CODE" : 418,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "라오스"
	},
	{
		"COU_CODE" : 418,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ลาว"
	},
	{
		"COU_CODE" : 422,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Liban"
	},
	{
		"COU_CODE" : 422,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Lebanon"
	},
	{
		"COU_CODE" : 422,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Libanon"
	},
	{
		"COU_CODE" : 422,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Líbano"
	},
	{
		"COU_CODE" : 422,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Libano"
	},
	{
		"COU_CODE" : 422,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Líbano"
	},
	{
		"COU_CODE" : 422,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Libanon"
	},
	{
		"COU_CODE" : 422,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Liban"
	},
	{
		"COU_CODE" : 422,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Ливан"
	},
	{
		"COU_CODE" : 422,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "黎巴嫩"
	},
	{
		"COU_CODE" : 422,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "レバノン"
	},
	{
		"COU_CODE" : 422,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "لبنان"
	},
	{
		"COU_CODE" : 422,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "레바논"
	},
	{
		"COU_CODE" : 422,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เลบานอน"
	},
	{
		"COU_CODE" : 426,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Lesotho"
	},
	{
		"COU_CODE" : 426,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Lesotho"
	},
	{
		"COU_CODE" : 426,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Lesotho"
	},
	{
		"COU_CODE" : 426,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Lesoto"
	},
	{
		"COU_CODE" : 426,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Lesotho"
	},
	{
		"COU_CODE" : 426,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Lesoto"
	},
	{
		"COU_CODE" : 426,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Lesotho"
	},
	{
		"COU_CODE" : 426,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Lesotho"
	},
	{
		"COU_CODE" : 426,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Лесото"
	},
	{
		"COU_CODE" : 426,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "莱索托"
	},
	{
		"COU_CODE" : 426,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "レソト"
	},
	{
		"COU_CODE" : 426,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "ليسوتو"
	},
	{
		"COU_CODE" : 426,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "레소토"
	},
	{
		"COU_CODE" : 426,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เลโซโท"
	},
	{
		"COU_CODE" : 428,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Lettonie"
	},
	{
		"COU_CODE" : 428,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Latvia"
	},
	{
		"COU_CODE" : 428,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Letland"
	},
	{
		"COU_CODE" : 428,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Letonia"
	},
	{
		"COU_CODE" : 428,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Lettonia"
	},
	{
		"COU_CODE" : 428,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Letônia"
	},
	{
		"COU_CODE" : 428,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Lettland"
	},
	{
		"COU_CODE" : 428,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Łotwa"
	},
	{
		"COU_CODE" : 428,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Латвия"
	},
	{
		"COU_CODE" : 428,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "拉脱维亚"
	},
	{
		"COU_CODE" : 428,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ラトビア"
	},
	{
		"COU_CODE" : 428,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "لاتفيا"
	},
	{
		"COU_CODE" : 428,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "라트비아"
	},
	{
		"COU_CODE" : 428,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ลัตเวีย"
	},
	{
		"COU_CODE" : 430,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Liberia"
	},
	{
		"COU_CODE" : 430,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Liberia"
	},
	{
		"COU_CODE" : 430,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Liberia"
	},
	{
		"COU_CODE" : 430,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Liberia"
	},
	{
		"COU_CODE" : 430,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Liberia"
	},
	{
		"COU_CODE" : 430,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Libéria"
	},
	{
		"COU_CODE" : 430,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Liberia"
	},
	{
		"COU_CODE" : 430,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Liberia"
	},
	{
		"COU_CODE" : 430,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Либерия"
	},
	{
		"COU_CODE" : 430,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "利比里亚"
	},
	{
		"COU_CODE" : 430,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "リベリア"
	},
	{
		"COU_CODE" : 430,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "ليبيريا"
	},
	{
		"COU_CODE" : 430,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "라이베리아"
	},
	{
		"COU_CODE" : 430,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ไลบีเรีย"
	},
	{
		"COU_CODE" : 434,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Libye"
	},
	{
		"COU_CODE" : 434,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Libya"
	},
	{
		"COU_CODE" : 434,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Libië"
	},
	{
		"COU_CODE" : 434,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Libia"
	},
	{
		"COU_CODE" : 434,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Libia"
	},
	{
		"COU_CODE" : 434,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Líbia"
	},
	{
		"COU_CODE" : 434,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Libyen"
	},
	{
		"COU_CODE" : 434,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Libia"
	},
	{
		"COU_CODE" : 434,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Ливия"
	},
	{
		"COU_CODE" : 434,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "利比亚"
	},
	{
		"COU_CODE" : 434,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "リビア"
	},
	{
		"COU_CODE" : 434,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "ليبيا"
	},
	{
		"COU_CODE" : 434,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "리비아"
	},
	{
		"COU_CODE" : 434,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ลิเบีย"
	},
	{
		"COU_CODE" : 438,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Liechtenstein"
	},
	{
		"COU_CODE" : 438,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Liechtenstein"
	},
	{
		"COU_CODE" : 438,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Liechtenstein"
	},
	{
		"COU_CODE" : 438,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Liechtenstein"
	},
	{
		"COU_CODE" : 438,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Liechtenstein"
	},
	{
		"COU_CODE" : 438,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Liechtenstein"
	},
	{
		"COU_CODE" : 438,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Liechtenstein"
	},
	{
		"COU_CODE" : 438,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Liechtenstein"
	},
	{
		"COU_CODE" : 438,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Лихтенштейн"
	},
	{
		"COU_CODE" : 438,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "列支敦士登"
	},
	{
		"COU_CODE" : 438,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "リヒテンシュタイン"
	},
	{
		"COU_CODE" : 438,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "ليختنشتاين"
	},
	{
		"COU_CODE" : 438,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "리히텐슈타인"
	},
	{
		"COU_CODE" : 438,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ลิกเตนสไตน์"
	},
	{
		"COU_CODE" : 440,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Lituanie"
	},
	{
		"COU_CODE" : 440,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Lithuania"
	},
	{
		"COU_CODE" : 440,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Litouwen"
	},
	{
		"COU_CODE" : 440,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Lituania"
	},
	{
		"COU_CODE" : 440,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Lituania"
	},
	{
		"COU_CODE" : 440,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Lituânia"
	},
	{
		"COU_CODE" : 440,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Litauen"
	},
	{
		"COU_CODE" : 440,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Litwa"
	},
	{
		"COU_CODE" : 440,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Литва"
	},
	{
		"COU_CODE" : 440,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "立陶宛"
	},
	{
		"COU_CODE" : 440,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "リトアニア"
	},
	{
		"COU_CODE" : 440,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "ليتوانيا"
	},
	{
		"COU_CODE" : 440,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "리투아니아"
	},
	{
		"COU_CODE" : 440,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ลิทัวเนีย"
	},
	{
		"COU_CODE" : 442,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Luxembourg (pays)"
	},
	{
		"COU_CODE" : 442,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Luxembourg"
	},
	{
		"COU_CODE" : 442,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Luxemburg"
	},
	{
		"COU_CODE" : 442,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Luxemburgo"
	},
	{
		"COU_CODE" : 442,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Lussemburgo"
	},
	{
		"COU_CODE" : 442,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Luxemburgo"
	},
	{
		"COU_CODE" : 442,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Luxemburg"
	},
	{
		"COU_CODE" : 442,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Luksemburg"
	},
	{
		"COU_CODE" : 442,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Люксембург"
	},
	{
		"COU_CODE" : 442,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "卢森堡"
	},
	{
		"COU_CODE" : 442,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ルクセンブルク"
	},
	{
		"COU_CODE" : 442,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "لوكسمبورغ"
	},
	{
		"COU_CODE" : 442,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "룩셈부르크"
	},
	{
		"COU_CODE" : 442,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ลักเซมเบิร์ก"
	},
	{
		"COU_CODE" : 450,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Madagascar"
	},
	{
		"COU_CODE" : 450,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Madagascar"
	},
	{
		"COU_CODE" : 450,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Madagaskar"
	},
	{
		"COU_CODE" : 450,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Madagascar"
	},
	{
		"COU_CODE" : 450,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Madagascar"
	},
	{
		"COU_CODE" : 450,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Madagáscar"
	},
	{
		"COU_CODE" : 450,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Madagaskar"
	},
	{
		"COU_CODE" : 450,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Madagaskar"
	},
	{
		"COU_CODE" : 450,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Мадагаскар"
	},
	{
		"COU_CODE" : 450,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "马达加斯加"
	},
	{
		"COU_CODE" : 450,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "マダガスカル"
	},
	{
		"COU_CODE" : 450,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "مدغشقر"
	},
	{
		"COU_CODE" : 450,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "마다가스카르"
	},
	{
		"COU_CODE" : 450,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "มาดากัสการ์"
	},
	{
		"COU_CODE" : 454,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Malawi"
	},
	{
		"COU_CODE" : 454,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Malawi"
	},
	{
		"COU_CODE" : 454,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Malawi"
	},
	{
		"COU_CODE" : 454,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Malaui"
	},
	{
		"COU_CODE" : 454,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Malawi"
	},
	{
		"COU_CODE" : 454,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Malawi"
	},
	{
		"COU_CODE" : 454,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Malawi"
	},
	{
		"COU_CODE" : 454,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Malawi"
	},
	{
		"COU_CODE" : 454,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Малави"
	},
	{
		"COU_CODE" : 454,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "马拉维"
	},
	{
		"COU_CODE" : 454,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "マラウイ"
	},
	{
		"COU_CODE" : 454,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "مالاوي"
	},
	{
		"COU_CODE" : 454,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "말라위"
	},
	{
		"COU_CODE" : 454,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "มาลาวี"
	},
	{
		"COU_CODE" : 458,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Malaisie"
	},
	{
		"COU_CODE" : 458,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Malaysia"
	},
	{
		"COU_CODE" : 458,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Maleisië"
	},
	{
		"COU_CODE" : 458,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Malasia"
	},
	{
		"COU_CODE" : 458,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Malaysia"
	},
	{
		"COU_CODE" : 458,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Malásia"
	},
	{
		"COU_CODE" : 458,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Malaysia"
	},
	{
		"COU_CODE" : 458,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Malezja"
	},
	{
		"COU_CODE" : 458,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Малайзия"
	},
	{
		"COU_CODE" : 458,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "马来西亚"
	},
	{
		"COU_CODE" : 458,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "マレーシア"
	},
	{
		"COU_CODE" : 458,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "ماليزيا"
	},
	{
		"COU_CODE" : 458,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "말레이시아"
	},
	{
		"COU_CODE" : 458,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "มาเลเซีย"
	},
	{
		"COU_CODE" : 462,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Maldives"
	},
	{
		"COU_CODE" : 462,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Maldives"
	},
	{
		"COU_CODE" : 462,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Maldiven"
	},
	{
		"COU_CODE" : 462,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Maldivas"
	},
	{
		"COU_CODE" : 462,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Maldive"
	},
	{
		"COU_CODE" : 462,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Maldivas"
	},
	{
		"COU_CODE" : 462,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Malediven"
	},
	{
		"COU_CODE" : 462,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Malediwy"
	},
	{
		"COU_CODE" : 462,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Мальдивы"
	},
	{
		"COU_CODE" : 462,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "马尔代夫"
	},
	{
		"COU_CODE" : 462,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "モルディブ"
	},
	{
		"COU_CODE" : 462,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "جزر المالديف"
	},
	{
		"COU_CODE" : 462,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "몰디브"
	},
	{
		"COU_CODE" : 462,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "มัลดีฟส์"
	},
	{
		"COU_CODE" : 466,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Mali"
	},
	{
		"COU_CODE" : 466,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Mali"
	},
	{
		"COU_CODE" : 466,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Mali"
	},
	{
		"COU_CODE" : 466,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Malí"
	},
	{
		"COU_CODE" : 466,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Mali"
	},
	{
		"COU_CODE" : 466,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Mali"
	},
	{
		"COU_CODE" : 466,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Mali"
	},
	{
		"COU_CODE" : 466,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Mali"
	},
	{
		"COU_CODE" : 466,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Мали"
	},
	{
		"COU_CODE" : 466,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "马里"
	},
	{
		"COU_CODE" : 466,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "マリ"
	},
	{
		"COU_CODE" : 466,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "مالي"
	},
	{
		"COU_CODE" : 466,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "말리"
	},
	{
		"COU_CODE" : 466,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "มาลี"
	},
	{
		"COU_CODE" : 470,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Malte"
	},
	{
		"COU_CODE" : 470,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Malta"
	},
	{
		"COU_CODE" : 470,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Malta"
	},
	{
		"COU_CODE" : 470,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Malta"
	},
	{
		"COU_CODE" : 470,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Malta"
	},
	{
		"COU_CODE" : 470,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Malta"
	},
	{
		"COU_CODE" : 470,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Malta"
	},
	{
		"COU_CODE" : 470,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Malta"
	},
	{
		"COU_CODE" : 470,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Мальта"
	},
	{
		"COU_CODE" : 470,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "马耳他"
	},
	{
		"COU_CODE" : 470,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "マルタ"
	},
	{
		"COU_CODE" : 470,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "مالطا"
	},
	{
		"COU_CODE" : 470,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "몰타"
	},
	{
		"COU_CODE" : 470,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "มอลตา"
	},
	{
		"COU_CODE" : 478,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Mauritanie"
	},
	{
		"COU_CODE" : 478,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Mauritania"
	},
	{
		"COU_CODE" : 478,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Mauritanië"
	},
	{
		"COU_CODE" : 478,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Mauritania"
	},
	{
		"COU_CODE" : 478,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Mauritania"
	},
	{
		"COU_CODE" : 478,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Mauritânia"
	},
	{
		"COU_CODE" : 478,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Mauretanien"
	},
	{
		"COU_CODE" : 478,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Mauretania"
	},
	{
		"COU_CODE" : 478,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Мавритания"
	},
	{
		"COU_CODE" : 478,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "毛里塔尼亚"
	},
	{
		"COU_CODE" : 478,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "モーリタニア"
	},
	{
		"COU_CODE" : 478,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "موريتانيا"
	},
	{
		"COU_CODE" : 478,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "모리타니"
	},
	{
		"COU_CODE" : 478,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "มอริเตเนีย"
	},
	{
		"COU_CODE" : 480,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Maurice (pays)"
	},
	{
		"COU_CODE" : 480,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Mauritius"
	},
	{
		"COU_CODE" : 480,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Mauritius"
	},
	{
		"COU_CODE" : 480,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Mauricio"
	},
	{
		"COU_CODE" : 480,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Mauritius"
	},
	{
		"COU_CODE" : 480,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Maurícia"
	},
	{
		"COU_CODE" : 480,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Mauritius"
	},
	{
		"COU_CODE" : 480,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Mauritius"
	},
	{
		"COU_CODE" : 480,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Маврикий"
	},
	{
		"COU_CODE" : 480,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "毛里求斯"
	},
	{
		"COU_CODE" : 480,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "モーリシャス"
	},
	{
		"COU_CODE" : 480,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "موريشيوس"
	},
	{
		"COU_CODE" : 480,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "모리셔스"
	},
	{
		"COU_CODE" : 480,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "มอริเชียส"
	},
	{
		"COU_CODE" : 484,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Mexique"
	},
	{
		"COU_CODE" : 484,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Mexico"
	},
	{
		"COU_CODE" : 484,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Mexico"
	},
	{
		"COU_CODE" : 484,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "México"
	},
	{
		"COU_CODE" : 484,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Messico"
	},
	{
		"COU_CODE" : 484,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "México"
	},
	{
		"COU_CODE" : 484,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Mexiko"
	},
	{
		"COU_CODE" : 484,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Meksyk"
	},
	{
		"COU_CODE" : 484,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Мексика"
	},
	{
		"COU_CODE" : 484,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "墨西哥"
	},
	{
		"COU_CODE" : 484,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "メキシコ"
	},
	{
		"COU_CODE" : 484,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "المكسيك"
	},
	{
		"COU_CODE" : 484,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "멕시코"
	},
	{
		"COU_CODE" : 484,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เม็กซิโก"
	},
	{
		"COU_CODE" : 492,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Monaco"
	},
	{
		"COU_CODE" : 492,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Monaco"
	},
	{
		"COU_CODE" : 492,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Monaco"
	},
	{
		"COU_CODE" : 492,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Mónaco"
	},
	{
		"COU_CODE" : 492,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Monaco"
	},
	{
		"COU_CODE" : 492,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Mónaco"
	},
	{
		"COU_CODE" : 492,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Monaco"
	},
	{
		"COU_CODE" : 492,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Monako"
	},
	{
		"COU_CODE" : 492,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Монако"
	},
	{
		"COU_CODE" : 492,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "摩纳哥"
	},
	{
		"COU_CODE" : 492,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "モナコ"
	},
	{
		"COU_CODE" : 492,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "موناكو"
	},
	{
		"COU_CODE" : 492,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "모나코"
	},
	{
		"COU_CODE" : 492,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "โมนาโก"
	},
	{
		"COU_CODE" : 496,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Mongolie"
	},
	{
		"COU_CODE" : 496,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Mongolia"
	},
	{
		"COU_CODE" : 496,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Mongolië"
	},
	{
		"COU_CODE" : 496,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Mongolia"
	},
	{
		"COU_CODE" : 496,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Mongolia"
	},
	{
		"COU_CODE" : 496,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Mongólia"
	},
	{
		"COU_CODE" : 496,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Mongolei"
	},
	{
		"COU_CODE" : 496,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Mongolia"
	},
	{
		"COU_CODE" : 496,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Монголия"
	},
	{
		"COU_CODE" : 496,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "蒙古国"
	},
	{
		"COU_CODE" : 496,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "モンゴル"
	},
	{
		"COU_CODE" : 496,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "منغوليا"
	},
	{
		"COU_CODE" : 496,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "몽골"
	},
	{
		"COU_CODE" : 496,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "มองโกเลีย"
	},
	{
		"COU_CODE" : 498,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Moldavie"
	},
	{
		"COU_CODE" : 498,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Moldova, Republic of"
	},
	{
		"COU_CODE" : 498,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Moldavië"
	},
	{
		"COU_CODE" : 498,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Moldavia"
	},
	{
		"COU_CODE" : 498,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Moldavia"
	},
	{
		"COU_CODE" : 498,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Moldávia"
	},
	{
		"COU_CODE" : 498,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Moldau"
	},
	{
		"COU_CODE" : 498,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Mołdawia"
	},
	{
		"COU_CODE" : 498,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Молдавия"
	},
	{
		"COU_CODE" : 498,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "摩尔多瓦"
	},
	{
		"COU_CODE" : 498,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "モルドバ共和国"
	},
	{
		"COU_CODE" : 498,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "مولدوفا"
	},
	{
		"COU_CODE" : 498,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "몰도바"
	},
	{
		"COU_CODE" : 498,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "มอลโดวา"
	},
	{
		"COU_CODE" : 499,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Monténégro"
	},
	{
		"COU_CODE" : 499,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Montenegro"
	},
	{
		"COU_CODE" : 499,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Montenegro"
	},
	{
		"COU_CODE" : 499,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Montenegro"
	},
	{
		"COU_CODE" : 499,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Montenegro"
	},
	{
		"COU_CODE" : 499,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Montenegro"
	},
	{
		"COU_CODE" : 499,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Montenegro"
	},
	{
		"COU_CODE" : 499,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Czarnogóra"
	},
	{
		"COU_CODE" : 499,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Черногория"
	},
	{
		"COU_CODE" : 499,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "黑山"
	},
	{
		"COU_CODE" : 499,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "モンテネグロ"
	},
	{
		"COU_CODE" : 499,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "الجبل الأسود"
	},
	{
		"COU_CODE" : 499,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "몬테네그로"
	},
	{
		"COU_CODE" : 499,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "มอนเตเนโกร"
	},
	{
		"COU_CODE" : 504,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Maroc"
	},
	{
		"COU_CODE" : 504,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Morocco"
	},
	{
		"COU_CODE" : 504,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Marokko"
	},
	{
		"COU_CODE" : 504,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Marruecos"
	},
	{
		"COU_CODE" : 504,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Marocco"
	},
	{
		"COU_CODE" : 504,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Marrocos"
	},
	{
		"COU_CODE" : 504,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Marokko"
	},
	{
		"COU_CODE" : 504,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Maroko"
	},
	{
		"COU_CODE" : 504,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Марокко"
	},
	{
		"COU_CODE" : 504,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "摩洛哥"
	},
	{
		"COU_CODE" : 504,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "モロッコ"
	},
	{
		"COU_CODE" : 504,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "المغرب"
	},
	{
		"COU_CODE" : 504,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "모로코"
	},
	{
		"COU_CODE" : 504,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "โมร็อกโก"
	},
	{
		"COU_CODE" : 508,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Mozambique"
	},
	{
		"COU_CODE" : 508,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Mozambique"
	},
	{
		"COU_CODE" : 508,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Mozambique"
	},
	{
		"COU_CODE" : 508,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Mozambique"
	},
	{
		"COU_CODE" : 508,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Mozambico"
	},
	{
		"COU_CODE" : 508,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Moçambique"
	},
	{
		"COU_CODE" : 508,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Mosambik"
	},
	{
		"COU_CODE" : 508,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Mozambik"
	},
	{
		"COU_CODE" : 508,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Мозамбик"
	},
	{
		"COU_CODE" : 508,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "莫桑比克"
	},
	{
		"COU_CODE" : 508,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "モザンビーク"
	},
	{
		"COU_CODE" : 508,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "موزمبيق"
	},
	{
		"COU_CODE" : 508,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "모잠비크"
	},
	{
		"COU_CODE" : 508,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "โมซัมบิก"
	},
	{
		"COU_CODE" : 512,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Oman"
	},
	{
		"COU_CODE" : 512,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Oman"
	},
	{
		"COU_CODE" : 512,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Oman"
	},
	{
		"COU_CODE" : 512,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Omán"
	},
	{
		"COU_CODE" : 512,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Oman"
	},
	{
		"COU_CODE" : 512,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Omã"
	},
	{
		"COU_CODE" : 512,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Oman"
	},
	{
		"COU_CODE" : 512,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Oman"
	},
	{
		"COU_CODE" : 512,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Оман"
	},
	{
		"COU_CODE" : 512,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "阿曼"
	},
	{
		"COU_CODE" : 512,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "オマーン"
	},
	{
		"COU_CODE" : 512,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "عمان"
	},
	{
		"COU_CODE" : 512,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "오만"
	},
	{
		"COU_CODE" : 512,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "โอมาน"
	},
	{
		"COU_CODE" : 516,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Namibie"
	},
	{
		"COU_CODE" : 516,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Namibia"
	},
	{
		"COU_CODE" : 516,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Namibië"
	},
	{
		"COU_CODE" : 516,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Namibia"
	},
	{
		"COU_CODE" : 516,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Namibia"
	},
	{
		"COU_CODE" : 516,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Namíbia"
	},
	{
		"COU_CODE" : 516,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Namibia"
	},
	{
		"COU_CODE" : 516,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Namibia"
	},
	{
		"COU_CODE" : 516,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Намибия"
	},
	{
		"COU_CODE" : 516,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "纳米比亚"
	},
	{
		"COU_CODE" : 516,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ナミビア"
	},
	{
		"COU_CODE" : 516,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "ناميبيا"
	},
	{
		"COU_CODE" : 516,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "나미비아"
	},
	{
		"COU_CODE" : 516,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "นามิเบีย"
	},
	{
		"COU_CODE" : 520,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Nauru"
	},
	{
		"COU_CODE" : 520,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Nauru"
	},
	{
		"COU_CODE" : 520,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Nauru"
	},
	{
		"COU_CODE" : 520,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Nauru"
	},
	{
		"COU_CODE" : 520,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Nauru"
	},
	{
		"COU_CODE" : 520,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Nauru"
	},
	{
		"COU_CODE" : 520,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Nauru"
	},
	{
		"COU_CODE" : 520,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Nauru"
	},
	{
		"COU_CODE" : 520,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Науру"
	},
	{
		"COU_CODE" : 520,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "瑙鲁"
	},
	{
		"COU_CODE" : 520,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ナウル"
	},
	{
		"COU_CODE" : 520,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "ناورو"
	},
	{
		"COU_CODE" : 520,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "나우루"
	},
	{
		"COU_CODE" : 520,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "นาอูรู"
	},
	{
		"COU_CODE" : 524,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Népal"
	},
	{
		"COU_CODE" : 524,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Nepal"
	},
	{
		"COU_CODE" : 524,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Nepal"
	},
	{
		"COU_CODE" : 524,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Nepal"
	},
	{
		"COU_CODE" : 524,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Nepal"
	},
	{
		"COU_CODE" : 524,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Nepal"
	},
	{
		"COU_CODE" : 524,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Nepal"
	},
	{
		"COU_CODE" : 524,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Nepal"
	},
	{
		"COU_CODE" : 524,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Непал"
	},
	{
		"COU_CODE" : 524,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "尼泊尔"
	},
	{
		"COU_CODE" : 524,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ネパール"
	},
	{
		"COU_CODE" : 524,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "نيبال"
	},
	{
		"COU_CODE" : 524,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "네팔"
	},
	{
		"COU_CODE" : 524,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เนปาล"
	},
	{
		"COU_CODE" : 528,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Países Bajos"
	},
	{
		"COU_CODE" : 528,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Paesi Bassi"
	},
	{
		"COU_CODE" : 528,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Países Baixos"
	},
	{
		"COU_CODE" : 528,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Niederlande"
	},
	{
		"COU_CODE" : 528,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Holandia"
	},
	{
		"COU_CODE" : 528,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Нидерланды"
	},
	{
		"COU_CODE" : 528,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "荷兰"
	},
	{
		"COU_CODE" : 528,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "オランダ"
	},
	{
		"COU_CODE" : 528,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "هولندا"
	},
	{
		"COU_CODE" : 528,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "네덜란드"
	},
	{
		"COU_CODE" : 528,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เนเธอร์แลนด์"
	},
	{
		"COU_CODE" : 548,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Vanuatu"
	},
	{
		"COU_CODE" : 548,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Vanuatu"
	},
	{
		"COU_CODE" : 548,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Vanuatu"
	},
	{
		"COU_CODE" : 548,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Vanuatu"
	},
	{
		"COU_CODE" : 548,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Vanuatu"
	},
	{
		"COU_CODE" : 548,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Vanuatu"
	},
	{
		"COU_CODE" : 548,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Vanuatu"
	},
	{
		"COU_CODE" : 548,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Vanuatu"
	},
	{
		"COU_CODE" : 548,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Вануату"
	},
	{
		"COU_CODE" : 548,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "瓦努阿图"
	},
	{
		"COU_CODE" : 548,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "バヌアツ"
	},
	{
		"COU_CODE" : 548,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "فانواتو"
	},
	{
		"COU_CODE" : 548,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "바누아투"
	},
	{
		"COU_CODE" : 548,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "วานูอาตู"
	},
	{
		"COU_CODE" : 554,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Nouvelle-Zélande"
	},
	{
		"COU_CODE" : 554,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "New Zealand"
	},
	{
		"COU_CODE" : 554,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Nieuw-Zeeland"
	},
	{
		"COU_CODE" : 554,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Nueva Zelanda"
	},
	{
		"COU_CODE" : 554,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Nuova Zelanda"
	},
	{
		"COU_CODE" : 554,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Nova Zelândia"
	},
	{
		"COU_CODE" : 554,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Neuseeland"
	},
	{
		"COU_CODE" : 554,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Nowa Zelandia"
	},
	{
		"COU_CODE" : 554,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Новая Зеландия"
	},
	{
		"COU_CODE" : 554,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "新西兰"
	},
	{
		"COU_CODE" : 554,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ニュージーランド"
	},
	{
		"COU_CODE" : 554,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "نيوزيلندا"
	},
	{
		"COU_CODE" : 554,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "뉴질랜드"
	},
	{
		"COU_CODE" : 554,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "นิวซีแลนด์"
	},
	{
		"COU_CODE" : 558,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Nicaragua"
	},
	{
		"COU_CODE" : 558,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Nicaragua"
	},
	{
		"COU_CODE" : 558,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Nicaragua"
	},
	{
		"COU_CODE" : 558,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Nicaragua"
	},
	{
		"COU_CODE" : 558,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Nicaragua"
	},
	{
		"COU_CODE" : 558,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Nicarágua"
	},
	{
		"COU_CODE" : 558,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Nicaragua"
	},
	{
		"COU_CODE" : 558,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Nikaragua"
	},
	{
		"COU_CODE" : 558,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Никарагуа"
	},
	{
		"COU_CODE" : 558,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "尼加拉瓜"
	},
	{
		"COU_CODE" : 558,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ニカラグア"
	},
	{
		"COU_CODE" : 558,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "نيكاراغوا"
	},
	{
		"COU_CODE" : 558,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "니카라과"
	},
	{
		"COU_CODE" : 558,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "นิการากัว"
	},
	{
		"COU_CODE" : 562,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Niger"
	},
	{
		"COU_CODE" : 562,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Niger"
	},
	{
		"COU_CODE" : 562,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Niger"
	},
	{
		"COU_CODE" : 562,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Níger"
	},
	{
		"COU_CODE" : 562,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Niger"
	},
	{
		"COU_CODE" : 562,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Níger"
	},
	{
		"COU_CODE" : 562,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Niger"
	},
	{
		"COU_CODE" : 562,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Niger"
	},
	{
		"COU_CODE" : 562,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Нигер"
	},
	{
		"COU_CODE" : 562,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "尼日尔"
	},
	{
		"COU_CODE" : 562,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ニジェール"
	},
	{
		"COU_CODE" : 562,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "النيجر"
	},
	{
		"COU_CODE" : 562,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "니제르"
	},
	{
		"COU_CODE" : 562,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ไนเจอร์"
	},
	{
		"COU_CODE" : 566,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Nigeria"
	},
	{
		"COU_CODE" : 566,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Nigeria"
	},
	{
		"COU_CODE" : 566,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Nigeria"
	},
	{
		"COU_CODE" : 566,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Nigeria"
	},
	{
		"COU_CODE" : 566,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Nigeria"
	},
	{
		"COU_CODE" : 566,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Nigéria"
	},
	{
		"COU_CODE" : 566,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Nigeria"
	},
	{
		"COU_CODE" : 566,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Nigeria"
	},
	{
		"COU_CODE" : 566,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Нигерия"
	},
	{
		"COU_CODE" : 566,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "尼日利亚"
	},
	{
		"COU_CODE" : 566,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ナイジェリア"
	},
	{
		"COU_CODE" : 566,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "نيجيريا"
	},
	{
		"COU_CODE" : 566,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "나이지리아"
	},
	{
		"COU_CODE" : 566,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ไนจีเรีย"
	},
	{
		"COU_CODE" : 578,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Norvège"
	},
	{
		"COU_CODE" : 578,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Norway"
	},
	{
		"COU_CODE" : 578,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Noorwegen"
	},
	{
		"COU_CODE" : 578,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Noruega"
	},
	{
		"COU_CODE" : 578,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Norvegia"
	},
	{
		"COU_CODE" : 578,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Noruega"
	},
	{
		"COU_CODE" : 578,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Norwegen"
	},
	{
		"COU_CODE" : 578,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Norwegia"
	},
	{
		"COU_CODE" : 578,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Норвегия"
	},
	{
		"COU_CODE" : 578,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "挪威"
	},
	{
		"COU_CODE" : 578,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ノルウェー"
	},
	{
		"COU_CODE" : 578,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "النرويج"
	},
	{
		"COU_CODE" : 578,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "노르웨이"
	},
	{
		"COU_CODE" : 578,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "นอร์เวย์"
	},
	{
		"COU_CODE" : 583,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "États fédérés de Micronésie (pays)"
	},
	{
		"COU_CODE" : 583,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Micronesia (Federated States of)"
	},
	{
		"COU_CODE" : 583,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Micronesia"
	},
	{
		"COU_CODE" : 583,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Micronesia"
	},
	{
		"COU_CODE" : 583,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Micronesia"
	},
	{
		"COU_CODE" : 583,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Estados Federados da Micronésia"
	},
	{
		"COU_CODE" : 583,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Mikronesien"
	},
	{
		"COU_CODE" : 583,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Mikronezja"
	},
	{
		"COU_CODE" : 583,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Микронезия"
	},
	{
		"COU_CODE" : 583,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "密克罗尼西亚联邦"
	},
	{
		"COU_CODE" : 583,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ミクロネシア連邦"
	},
	{
		"COU_CODE" : 583,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "ولايات ميكرونيسيا المتحدة"
	},
	{
		"COU_CODE" : 583,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "미크로네시아 연방"
	},
	{
		"COU_CODE" : 583,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ไมโครนีเซีย"
	},
	{
		"COU_CODE" : 584,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Îles Marshall (pays)"
	},
	{
		"COU_CODE" : 584,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Marshall Islands"
	},
	{
		"COU_CODE" : 584,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Marshalleilanden"
	},
	{
		"COU_CODE" : 584,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Islas Marshall"
	},
	{
		"COU_CODE" : 584,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Isole Marshall"
	},
	{
		"COU_CODE" : 584,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Ilhas Marshall"
	},
	{
		"COU_CODE" : 584,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Marshallinseln"
	},
	{
		"COU_CODE" : 584,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Wyspy Marshalla"
	},
	{
		"COU_CODE" : 584,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Маршалловы Острова"
	},
	{
		"COU_CODE" : 584,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "马绍尔群岛"
	},
	{
		"COU_CODE" : 584,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "マーシャル諸島"
	},
	{
		"COU_CODE" : 584,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "جزر مارشال"
	},
	{
		"COU_CODE" : 584,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "마셜 제도"
	},
	{
		"COU_CODE" : 584,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "หมู่เกาะมาร์แชลล์"
	},
	{
		"COU_CODE" : 585,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Palaos"
	},
	{
		"COU_CODE" : 585,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Palau"
	},
	{
		"COU_CODE" : 585,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Palau"
	},
	{
		"COU_CODE" : 585,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Palaos"
	},
	{
		"COU_CODE" : 585,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Palau"
	},
	{
		"COU_CODE" : 585,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Palau"
	},
	{
		"COU_CODE" : 585,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Palau"
	},
	{
		"COU_CODE" : 585,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Palau"
	},
	{
		"COU_CODE" : 585,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Палау"
	},
	{
		"COU_CODE" : 585,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "帕劳"
	},
	{
		"COU_CODE" : 585,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "パラオ"
	},
	{
		"COU_CODE" : 585,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "بالاو"
	},
	{
		"COU_CODE" : 585,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "팔라우"
	},
	{
		"COU_CODE" : 585,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ปาเลา"
	},
	{
		"COU_CODE" : 586,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Pakistan"
	},
	{
		"COU_CODE" : 586,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Pakistan"
	},
	{
		"COU_CODE" : 586,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Pakistan"
	},
	{
		"COU_CODE" : 586,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Pakistán"
	},
	{
		"COU_CODE" : 586,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Pakistan"
	},
	{
		"COU_CODE" : 586,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Paquistão"
	},
	{
		"COU_CODE" : 586,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Pakistan"
	},
	{
		"COU_CODE" : 586,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Pakistan"
	},
	{
		"COU_CODE" : 586,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Пакистан"
	},
	{
		"COU_CODE" : 586,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "巴基斯坦"
	},
	{
		"COU_CODE" : 586,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "パキスタン"
	},
	{
		"COU_CODE" : 586,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "باكستان"
	},
	{
		"COU_CODE" : 586,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "파키스탄"
	},
	{
		"COU_CODE" : 586,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ปากีสถาน"
	},
	{
		"COU_CODE" : 591,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Panama"
	},
	{
		"COU_CODE" : 591,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Panama"
	},
	{
		"COU_CODE" : 591,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Panama"
	},
	{
		"COU_CODE" : 591,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Panamá"
	},
	{
		"COU_CODE" : 591,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Panama"
	},
	{
		"COU_CODE" : 591,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Panamá"
	},
	{
		"COU_CODE" : 591,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Panama"
	},
	{
		"COU_CODE" : 591,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Panama"
	},
	{
		"COU_CODE" : 591,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Панама"
	},
	{
		"COU_CODE" : 591,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "巴拿马"
	},
	{
		"COU_CODE" : 591,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "パナマ"
	},
	{
		"COU_CODE" : 591,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "بنما"
	},
	{
		"COU_CODE" : 591,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "파나마"
	},
	{
		"COU_CODE" : 591,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ปานามา"
	},
	{
		"COU_CODE" : 598,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Papouasie-Nouvelle-Guinée"
	},
	{
		"COU_CODE" : 598,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Papua New Guinea"
	},
	{
		"COU_CODE" : 598,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Papoea-Nieuw-Guinea"
	},
	{
		"COU_CODE" : 598,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Papúa Nueva Guinea"
	},
	{
		"COU_CODE" : 598,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Papua Nuova Guinea"
	},
	{
		"COU_CODE" : 598,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Papua-Nova Guiné"
	},
	{
		"COU_CODE" : 598,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Papua-Neuguinea"
	},
	{
		"COU_CODE" : 598,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Papua-Nowa Gwinea"
	},
	{
		"COU_CODE" : 598,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Папуа — Новая Гвинея"
	},
	{
		"COU_CODE" : 598,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "巴布亚新几内亚"
	},
	{
		"COU_CODE" : 598,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "パプアニューギニア"
	},
	{
		"COU_CODE" : 598,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "بابوا غينيا الجديدة"
	},
	{
		"COU_CODE" : 598,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "파푸아뉴기니"
	},
	{
		"COU_CODE" : 598,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ปาปัวนิวกินี"
	},
	{
		"COU_CODE" : 600,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Paraguay"
	},
	{
		"COU_CODE" : 600,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Paraguay"
	},
	{
		"COU_CODE" : 600,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Paraguay"
	},
	{
		"COU_CODE" : 600,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Paraguay"
	},
	{
		"COU_CODE" : 600,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Paraguay"
	},
	{
		"COU_CODE" : 600,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Paraguai"
	},
	{
		"COU_CODE" : 600,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Paraguay"
	},
	{
		"COU_CODE" : 600,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Paragwaj"
	},
	{
		"COU_CODE" : 600,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Парагвай"
	},
	{
		"COU_CODE" : 600,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "巴拉圭"
	},
	{
		"COU_CODE" : 600,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "パラグアイ"
	},
	{
		"COU_CODE" : 600,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "باراغواي"
	},
	{
		"COU_CODE" : 600,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "파라과이"
	},
	{
		"COU_CODE" : 600,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ปารากวัย"
	},
	{
		"COU_CODE" : 604,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Pérou"
	},
	{
		"COU_CODE" : 604,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Peru"
	},
	{
		"COU_CODE" : 604,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Peru"
	},
	{
		"COU_CODE" : 604,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Perú"
	},
	{
		"COU_CODE" : 604,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Perù"
	},
	{
		"COU_CODE" : 604,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Peru"
	},
	{
		"COU_CODE" : 604,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Peru"
	},
	{
		"COU_CODE" : 604,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Peru"
	},
	{
		"COU_CODE" : 604,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Перу"
	},
	{
		"COU_CODE" : 604,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "秘鲁"
	},
	{
		"COU_CODE" : 604,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ペルー"
	},
	{
		"COU_CODE" : 604,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "بيرو"
	},
	{
		"COU_CODE" : 604,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "페루"
	},
	{
		"COU_CODE" : 604,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เปรู"
	},
	{
		"COU_CODE" : 608,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Philippines"
	},
	{
		"COU_CODE" : 608,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Philippines"
	},
	{
		"COU_CODE" : 608,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Filipijnen"
	},
	{
		"COU_CODE" : 608,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Filipinas"
	},
	{
		"COU_CODE" : 608,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Filippine"
	},
	{
		"COU_CODE" : 608,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Filipinas"
	},
	{
		"COU_CODE" : 608,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Philippinen"
	},
	{
		"COU_CODE" : 608,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Filipiny"
	},
	{
		"COU_CODE" : 608,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Филиппины"
	},
	{
		"COU_CODE" : 608,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "菲律宾"
	},
	{
		"COU_CODE" : 608,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "フィリピン"
	},
	{
		"COU_CODE" : 608,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "الفلبين"
	},
	{
		"COU_CODE" : 608,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "필리핀"
	},
	{
		"COU_CODE" : 608,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ฟิลิปปินส์"
	},
	{
		"COU_CODE" : 616,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Pologne"
	},
	{
		"COU_CODE" : 616,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Poland"
	},
	{
		"COU_CODE" : 616,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Polen"
	},
	{
		"COU_CODE" : 616,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Polonia"
	},
	{
		"COU_CODE" : 616,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Polonia"
	},
	{
		"COU_CODE" : 616,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Polónia"
	},
	{
		"COU_CODE" : 616,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Polen"
	},
	{
		"COU_CODE" : 616,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Polska"
	},
	{
		"COU_CODE" : 616,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Польша"
	},
	{
		"COU_CODE" : 616,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "波兰"
	},
	{
		"COU_CODE" : 616,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ポーランド"
	},
	{
		"COU_CODE" : 616,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "بولندا"
	},
	{
		"COU_CODE" : 616,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "폴란드"
	},
	{
		"COU_CODE" : 616,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "โปแลนด์"
	},
	{
		"COU_CODE" : 620,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Portugal"
	},
	{
		"COU_CODE" : 620,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Portugal"
	},
	{
		"COU_CODE" : 620,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Portugal"
	},
	{
		"COU_CODE" : 620,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Portugal"
	},
	{
		"COU_CODE" : 620,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Portogallo"
	},
	{
		"COU_CODE" : 620,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Portugal"
	},
	{
		"COU_CODE" : 620,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Portugal"
	},
	{
		"COU_CODE" : 620,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Portugalia"
	},
	{
		"COU_CODE" : 620,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Португалия"
	},
	{
		"COU_CODE" : 620,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "葡萄牙"
	},
	{
		"COU_CODE" : 620,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ポルトガル"
	},
	{
		"COU_CODE" : 620,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "البرتغال"
	},
	{
		"COU_CODE" : 620,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "포르투갈"
	},
	{
		"COU_CODE" : 620,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "โปรตุเกส"
	},
	{
		"COU_CODE" : 624,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Guinée-Bissau"
	},
	{
		"COU_CODE" : 624,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Guinea-Bissau"
	},
	{
		"COU_CODE" : 624,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Guinee-Bissau"
	},
	{
		"COU_CODE" : 624,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Guinea-Bisáu"
	},
	{
		"COU_CODE" : 624,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Guinea-Bissau"
	},
	{
		"COU_CODE" : 624,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Guiné-Bissau"
	},
	{
		"COU_CODE" : 624,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Guinea-Bissau"
	},
	{
		"COU_CODE" : 624,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Gwinea Bissau"
	},
	{
		"COU_CODE" : 624,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Гвинея-Бисау"
	},
	{
		"COU_CODE" : 624,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "几内亚比绍"
	},
	{
		"COU_CODE" : 624,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ギニアビサウ"
	},
	{
		"COU_CODE" : 624,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "غينيا بيساو"
	},
	{
		"COU_CODE" : 624,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "기니비사우"
	},
	{
		"COU_CODE" : 624,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "กินี-บิสเซา"
	},
	{
		"COU_CODE" : 626,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Timor oriental"
	},
	{
		"COU_CODE" : 626,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Timor-Leste"
	},
	{
		"COU_CODE" : 626,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Oost-Timor"
	},
	{
		"COU_CODE" : 626,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Timor Oriental"
	},
	{
		"COU_CODE" : 626,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Timor Est"
	},
	{
		"COU_CODE" : 626,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Timor-Leste"
	},
	{
		"COU_CODE" : 626,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Osttimor"
	},
	{
		"COU_CODE" : 626,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Timor Wschodni"
	},
	{
		"COU_CODE" : 626,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Восточный Тимор"
	},
	{
		"COU_CODE" : 626,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "东帝汶"
	},
	{
		"COU_CODE" : 626,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "東ティモール"
	},
	{
		"COU_CODE" : 626,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "تيمور الشرقية"
	},
	{
		"COU_CODE" : 626,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "동티모르"
	},
	{
		"COU_CODE" : 626,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ติมอร์-เลสเต"
	},
	{
		"COU_CODE" : 634,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Qatar"
	},
	{
		"COU_CODE" : 634,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Qatar"
	},
	{
		"COU_CODE" : 634,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Qatar"
	},
	{
		"COU_CODE" : 634,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Catar"
	},
	{
		"COU_CODE" : 634,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Qatar"
	},
	{
		"COU_CODE" : 634,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Catar"
	},
	{
		"COU_CODE" : 634,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Katar"
	},
	{
		"COU_CODE" : 634,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Katar"
	},
	{
		"COU_CODE" : 634,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Катар"
	},
	{
		"COU_CODE" : 634,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "卡塔尔"
	},
	{
		"COU_CODE" : 634,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "カタール"
	},
	{
		"COU_CODE" : 634,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "قطر"
	},
	{
		"COU_CODE" : 634,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "카타르"
	},
	{
		"COU_CODE" : 634,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "กาตาร์"
	},
	{
		"COU_CODE" : 642,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Roumanie"
	},
	{
		"COU_CODE" : 642,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Romania"
	},
	{
		"COU_CODE" : 642,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Roemenië"
	},
	{
		"COU_CODE" : 642,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Rumania"
	},
	{
		"COU_CODE" : 642,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Romania"
	},
	{
		"COU_CODE" : 642,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Roménia"
	},
	{
		"COU_CODE" : 642,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Rumänien"
	},
	{
		"COU_CODE" : 642,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Rumunia"
	},
	{
		"COU_CODE" : 642,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Румыния"
	},
	{
		"COU_CODE" : 642,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "罗马尼亚"
	},
	{
		"COU_CODE" : 642,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ルーマニア"
	},
	{
		"COU_CODE" : 642,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "رومانيا"
	},
	{
		"COU_CODE" : 642,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "루마니아"
	},
	{
		"COU_CODE" : 642,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "โรมาเนีย"
	},
	{
		"COU_CODE" : 643,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Russie"
	},
	{
		"COU_CODE" : 643,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Russian Federation"
	},
	{
		"COU_CODE" : 643,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Rusland"
	},
	{
		"COU_CODE" : 643,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Rusia"
	},
	{
		"COU_CODE" : 643,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Russia"
	},
	{
		"COU_CODE" : 643,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Rússia"
	},
	{
		"COU_CODE" : 643,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Russland"
	},
	{
		"COU_CODE" : 643,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Rosja"
	},
	{
		"COU_CODE" : 643,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Россия"
	},
	{
		"COU_CODE" : 643,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "俄罗斯"
	},
	{
		"COU_CODE" : 643,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ロシア連邦"
	},
	{
		"COU_CODE" : 643,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "روسيا"
	},
	{
		"COU_CODE" : 643,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "러시아"
	},
	{
		"COU_CODE" : 643,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "รัสเซีย"
	},
	{
		"COU_CODE" : 646,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Rwanda"
	},
	{
		"COU_CODE" : 646,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Rwanda"
	},
	{
		"COU_CODE" : 646,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Rwanda"
	},
	{
		"COU_CODE" : 646,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Ruanda"
	},
	{
		"COU_CODE" : 646,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Ruanda"
	},
	{
		"COU_CODE" : 646,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Ruanda"
	},
	{
		"COU_CODE" : 646,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Ruanda"
	},
	{
		"COU_CODE" : 646,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Rwanda"
	},
	{
		"COU_CODE" : 646,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Руанда"
	},
	{
		"COU_CODE" : 646,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "卢旺达"
	},
	{
		"COU_CODE" : 646,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ルワンダ"
	},
	{
		"COU_CODE" : 646,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "رواندا"
	},
	{
		"COU_CODE" : 646,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "르완다"
	},
	{
		"COU_CODE" : 646,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "รวันดา"
	},
	{
		"COU_CODE" : 659,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Saint-Christophe-et-Niévès"
	},
	{
		"COU_CODE" : 659,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Saint Kitts and Nevis"
	},
	{
		"COU_CODE" : 659,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Saint Kitts en Nevis"
	},
	{
		"COU_CODE" : 659,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "San Cristóbal y Nieves"
	},
	{
		"COU_CODE" : 659,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Saint Kitts e Nevis"
	},
	{
		"COU_CODE" : 659,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "São Cristóvão e Névis"
	},
	{
		"COU_CODE" : 659,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "St. Kitts und Nevis"
	},
	{
		"COU_CODE" : 659,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Saint Kitts i Nevis"
	},
	{
		"COU_CODE" : 659,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Сент-Китс и Невис"
	},
	{
		"COU_CODE" : 659,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "圣基茨和尼维斯"
	},
	{
		"COU_CODE" : 659,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "セントクリストファー・ネイビス"
	},
	{
		"COU_CODE" : 659,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "سانت كيتس ونيفيس"
	},
	{
		"COU_CODE" : 659,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "세인트키츠 네비스"
	},
	{
		"COU_CODE" : 659,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เซนต์คิตส์และเนวิส"
	},
	{
		"COU_CODE" : 662,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Sainte-Lucie"
	},
	{
		"COU_CODE" : 662,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Saint Lucia"
	},
	{
		"COU_CODE" : 662,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Saint Lucia"
	},
	{
		"COU_CODE" : 662,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Santa Lucía"
	},
	{
		"COU_CODE" : 662,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Saint Lucia"
	},
	{
		"COU_CODE" : 662,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Santa Lúcia"
	},
	{
		"COU_CODE" : 662,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "St. Lucia"
	},
	{
		"COU_CODE" : 662,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Saint Lucia"
	},
	{
		"COU_CODE" : 662,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Сент-Люсия"
	},
	{
		"COU_CODE" : 662,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "圣卢西亚"
	},
	{
		"COU_CODE" : 662,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "セントルシア"
	},
	{
		"COU_CODE" : 662,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "سانت لوسيا"
	},
	{
		"COU_CODE" : 662,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "세인트루시아"
	},
	{
		"COU_CODE" : 662,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เซนต์ลูเชีย"
	},
	{
		"COU_CODE" : 670,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Saint-Vincent-et-les-Grenadines"
	},
	{
		"COU_CODE" : 670,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Saint Vincent and the Grenadines"
	},
	{
		"COU_CODE" : 670,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Saint Vincent en de Grenadines"
	},
	{
		"COU_CODE" : 670,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "San Vicente y las Granadinas"
	},
	{
		"COU_CODE" : 670,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Saint Vincent e Grenadine"
	},
	{
		"COU_CODE" : 670,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "São Vicente e Granadinas"
	},
	{
		"COU_CODE" : 670,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "St. Vincent und die Grenadinen"
	},
	{
		"COU_CODE" : 670,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Saint Vincent i Grenadyny"
	},
	{
		"COU_CODE" : 670,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Сент-Винсент и Гренадины"
	},
	{
		"COU_CODE" : 670,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "圣文森特和格林纳丁斯"
	},
	{
		"COU_CODE" : 670,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "セントビンセントおよびグレナディーン諸島"
	},
	{
		"COU_CODE" : 670,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "سانت فينسنت والغرينادين"
	},
	{
		"COU_CODE" : 670,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "세인트빈센트 그레나딘"
	},
	{
		"COU_CODE" : 670,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เซนต์วินเซนต์และเกรนาดีนส์"
	},
	{
		"COU_CODE" : 674,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Saint-Marin"
	},
	{
		"COU_CODE" : 674,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "San Marino"
	},
	{
		"COU_CODE" : 674,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "San Marino"
	},
	{
		"COU_CODE" : 674,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "San Marino"
	},
	{
		"COU_CODE" : 674,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "San Marino"
	},
	{
		"COU_CODE" : 674,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "San Marino"
	},
	{
		"COU_CODE" : 674,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "San Marino"
	},
	{
		"COU_CODE" : 674,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "San Marino"
	},
	{
		"COU_CODE" : 674,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Сан-Марино"
	},
	{
		"COU_CODE" : 674,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "圣马力诺"
	},
	{
		"COU_CODE" : 674,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "サンマリノ"
	},
	{
		"COU_CODE" : 674,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "سان مارينو"
	},
	{
		"COU_CODE" : 674,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "산마리노"
	},
	{
		"COU_CODE" : 674,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ซานมารีโน"
	},
	{
		"COU_CODE" : 678,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Sao Tomé-et-Principe"
	},
	{
		"COU_CODE" : 678,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Sao Tome and Principe"
	},
	{
		"COU_CODE" : 678,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Sao Tomé en Principe"
	},
	{
		"COU_CODE" : 678,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Santo Tomé y Príncipe"
	},
	{
		"COU_CODE" : 678,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "São Tomé e Príncipe"
	},
	{
		"COU_CODE" : 678,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "São Tomé e Príncipe"
	},
	{
		"COU_CODE" : 678,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "São Tomé und Príncipe"
	},
	{
		"COU_CODE" : 678,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Wyspy Świętego Tomasza i Książęca"
	},
	{
		"COU_CODE" : 678,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Сан-Томе и Принсипи"
	},
	{
		"COU_CODE" : 678,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "圣多美和普林西比"
	},
	{
		"COU_CODE" : 678,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "サントメ・プリンシペ"
	},
	{
		"COU_CODE" : 678,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "ساو تومي وبرينسيب"
	},
	{
		"COU_CODE" : 678,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "상투메 프린시페"
	},
	{
		"COU_CODE" : 678,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เซาตูเมและปรินซีปี"
	},
	{
		"COU_CODE" : 682,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Arabie saoudite"
	},
	{
		"COU_CODE" : 682,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Saudi Arabia"
	},
	{
		"COU_CODE" : 682,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Saoedi-Arabië"
	},
	{
		"COU_CODE" : 682,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Arabia Saudita"
	},
	{
		"COU_CODE" : 682,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Arabia Saudita"
	},
	{
		"COU_CODE" : 682,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Arábia Saudita"
	},
	{
		"COU_CODE" : 682,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Saudi-Arabien"
	},
	{
		"COU_CODE" : 682,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Arabia Saudyjska"
	},
	{
		"COU_CODE" : 682,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Саудовская Аравия"
	},
	{
		"COU_CODE" : 682,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "沙特阿拉伯"
	},
	{
		"COU_CODE" : 682,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "サウジアラビア"
	},
	{
		"COU_CODE" : 682,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "السعودية"
	},
	{
		"COU_CODE" : 682,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "사우디아라비아"
	},
	{
		"COU_CODE" : 682,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ซาอุดีอาระเบีย"
	},
	{
		"COU_CODE" : 686,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Sénégal"
	},
	{
		"COU_CODE" : 686,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Senegal"
	},
	{
		"COU_CODE" : 686,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Senegal"
	},
	{
		"COU_CODE" : 686,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Senegal"
	},
	{
		"COU_CODE" : 686,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Senegal"
	},
	{
		"COU_CODE" : 686,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Senegal"
	},
	{
		"COU_CODE" : 686,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Senegal"
	},
	{
		"COU_CODE" : 686,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Senegal"
	},
	{
		"COU_CODE" : 686,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Сенегал"
	},
	{
		"COU_CODE" : 686,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "塞内加尔"
	},
	{
		"COU_CODE" : 686,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "セネガル"
	},
	{
		"COU_CODE" : 686,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "السنغال"
	},
	{
		"COU_CODE" : 686,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "세네갈"
	},
	{
		"COU_CODE" : 686,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เซเนกัล"
	},
	{
		"COU_CODE" : 688,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Serbie"
	},
	{
		"COU_CODE" : 688,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Serbia"
	},
	{
		"COU_CODE" : 688,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Servië"
	},
	{
		"COU_CODE" : 688,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Serbia"
	},
	{
		"COU_CODE" : 688,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Serbia"
	},
	{
		"COU_CODE" : 688,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Sérvia"
	},
	{
		"COU_CODE" : 688,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Serbien"
	},
	{
		"COU_CODE" : 688,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Serbia"
	},
	{
		"COU_CODE" : 688,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Сербия"
	},
	{
		"COU_CODE" : 688,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "塞尔维亚"
	},
	{
		"COU_CODE" : 688,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "セルビア"
	},
	{
		"COU_CODE" : 688,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "صربيا"
	},
	{
		"COU_CODE" : 688,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "세르비아"
	},
	{
		"COU_CODE" : 688,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เซอร์เบีย"
	},
	{
		"COU_CODE" : 690,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Seychelles"
	},
	{
		"COU_CODE" : 690,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Seychelles"
	},
	{
		"COU_CODE" : 690,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Seychellen"
	},
	{
		"COU_CODE" : 690,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Seychelles"
	},
	{
		"COU_CODE" : 690,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Seychelles"
	},
	{
		"COU_CODE" : 690,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Seicheles"
	},
	{
		"COU_CODE" : 690,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Seychellen"
	},
	{
		"COU_CODE" : 690,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Seszele"
	},
	{
		"COU_CODE" : 690,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Сейшельские Острова"
	},
	{
		"COU_CODE" : 690,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "塞舌尔"
	},
	{
		"COU_CODE" : 690,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "セーシェル"
	},
	{
		"COU_CODE" : 690,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "سيشل"
	},
	{
		"COU_CODE" : 690,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "세이셸"
	},
	{
		"COU_CODE" : 690,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เซเชลส์"
	},
	{
		"COU_CODE" : 694,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Sierra Leone"
	},
	{
		"COU_CODE" : 694,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Sierra Leone"
	},
	{
		"COU_CODE" : 694,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Sierra Leone"
	},
	{
		"COU_CODE" : 694,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Sierra Leona"
	},
	{
		"COU_CODE" : 694,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Sierra Leone"
	},
	{
		"COU_CODE" : 694,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Serra Leoa"
	},
	{
		"COU_CODE" : 694,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Sierra Leone"
	},
	{
		"COU_CODE" : 694,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Sierra Leone"
	},
	{
		"COU_CODE" : 694,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Сьерра-Леоне"
	},
	{
		"COU_CODE" : 694,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "塞拉利昂"
	},
	{
		"COU_CODE" : 694,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "シエラレオネ"
	},
	{
		"COU_CODE" : 694,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "سيراليون"
	},
	{
		"COU_CODE" : 694,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "시에라리온"
	},
	{
		"COU_CODE" : 694,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เซียร์ราลีโอน"
	},
	{
		"COU_CODE" : 702,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Singapour"
	},
	{
		"COU_CODE" : 702,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Singapore"
	},
	{
		"COU_CODE" : 702,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Singapore"
	},
	{
		"COU_CODE" : 702,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Singapur"
	},
	{
		"COU_CODE" : 702,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Singapore"
	},
	{
		"COU_CODE" : 702,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Singapura"
	},
	{
		"COU_CODE" : 702,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Singapur"
	},
	{
		"COU_CODE" : 702,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Singapur"
	},
	{
		"COU_CODE" : 702,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Сингапур"
	},
	{
		"COU_CODE" : 702,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "新加坡"
	},
	{
		"COU_CODE" : 702,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "シンガポール"
	},
	{
		"COU_CODE" : 702,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "سنغافورة"
	},
	{
		"COU_CODE" : 702,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "싱가포르"
	},
	{
		"COU_CODE" : 702,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "สิงคโปร์"
	},
	{
		"COU_CODE" : 703,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Slovaquie"
	},
	{
		"COU_CODE" : 703,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Slovakia"
	},
	{
		"COU_CODE" : 703,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Slowakije"
	},
	{
		"COU_CODE" : 703,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Eslovaquia"
	},
	{
		"COU_CODE" : 703,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Slovacchia"
	},
	{
		"COU_CODE" : 703,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Eslováquia"
	},
	{
		"COU_CODE" : 703,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Slowakei"
	},
	{
		"COU_CODE" : 703,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Słowacja"
	},
	{
		"COU_CODE" : 703,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Словакия"
	},
	{
		"COU_CODE" : 703,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "斯洛伐克"
	},
	{
		"COU_CODE" : 703,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "スロバキア"
	},
	{
		"COU_CODE" : 703,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "سلوفاكيا"
	},
	{
		"COU_CODE" : 703,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "슬로바키아"
	},
	{
		"COU_CODE" : 703,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "สโลวาเกีย"
	},
	{
		"COU_CODE" : 704,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Viêt Nam"
	},
	{
		"COU_CODE" : 704,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Viet Nam"
	},
	{
		"COU_CODE" : 704,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Vietnam"
	},
	{
		"COU_CODE" : 704,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Vietnam"
	},
	{
		"COU_CODE" : 704,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Vietnam"
	},
	{
		"COU_CODE" : 704,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Vietnã"
	},
	{
		"COU_CODE" : 704,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Vietnam"
	},
	{
		"COU_CODE" : 704,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Wietnam"
	},
	{
		"COU_CODE" : 704,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Вьетнам"
	},
	{
		"COU_CODE" : 704,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "越南"
	},
	{
		"COU_CODE" : 704,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ベトナム"
	},
	{
		"COU_CODE" : 704,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "فيتنام"
	},
	{
		"COU_CODE" : 704,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "베트남"
	},
	{
		"COU_CODE" : 704,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เวียดนาม"
	},
	{
		"COU_CODE" : 705,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Slovénie"
	},
	{
		"COU_CODE" : 705,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Slovenia"
	},
	{
		"COU_CODE" : 705,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Slovenië"
	},
	{
		"COU_CODE" : 705,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Eslovenia"
	},
	{
		"COU_CODE" : 705,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Slovenia"
	},
	{
		"COU_CODE" : 705,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Eslovênia"
	},
	{
		"COU_CODE" : 705,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Slowenien"
	},
	{
		"COU_CODE" : 705,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Słowenia"
	},
	{
		"COU_CODE" : 705,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Словения"
	},
	{
		"COU_CODE" : 705,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "斯洛文尼亚"
	},
	{
		"COU_CODE" : 705,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "スロベニア"
	},
	{
		"COU_CODE" : 705,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "سلوفينيا"
	},
	{
		"COU_CODE" : 705,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "슬로베니아"
	},
	{
		"COU_CODE" : 705,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "สโลวีเนีย"
	},
	{
		"COU_CODE" : 706,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Somalie"
	},
	{
		"COU_CODE" : 706,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Somalia"
	},
	{
		"COU_CODE" : 706,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Somalië"
	},
	{
		"COU_CODE" : 706,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Somalia"
	},
	{
		"COU_CODE" : 706,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Somalia"
	},
	{
		"COU_CODE" : 706,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Somália"
	},
	{
		"COU_CODE" : 706,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Somalia"
	},
	{
		"COU_CODE" : 706,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Somalia"
	},
	{
		"COU_CODE" : 706,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Сомали"
	},
	{
		"COU_CODE" : 706,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "索马里"
	},
	{
		"COU_CODE" : 706,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ソマリア"
	},
	{
		"COU_CODE" : 706,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "الصومال"
	},
	{
		"COU_CODE" : 706,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "소말리아"
	},
	{
		"COU_CODE" : 706,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "โซมาเลีย"
	},
	{
		"COU_CODE" : 710,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Afrique du Sud"
	},
	{
		"COU_CODE" : 710,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "South Africa"
	},
	{
		"COU_CODE" : 710,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Zuid-Afrika"
	},
	{
		"COU_CODE" : 710,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Sudáfrica"
	},
	{
		"COU_CODE" : 710,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Sudafrica"
	},
	{
		"COU_CODE" : 710,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "África do Sul"
	},
	{
		"COU_CODE" : 710,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Südafrika"
	},
	{
		"COU_CODE" : 710,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Południowa Afryka"
	},
	{
		"COU_CODE" : 710,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "ЮАР"
	},
	{
		"COU_CODE" : 710,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "南非"
	},
	{
		"COU_CODE" : 710,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "南アフリカ"
	},
	{
		"COU_CODE" : 710,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "جنوب أفريقيا"
	},
	{
		"COU_CODE" : 710,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "남아프리카 공화국"
	},
	{
		"COU_CODE" : 710,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "แอฟริกาใต้"
	},
	{
		"COU_CODE" : 716,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Zimbabwe"
	},
	{
		"COU_CODE" : 716,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Zimbabwe"
	},
	{
		"COU_CODE" : 716,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Zimbabwe"
	},
	{
		"COU_CODE" : 716,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Zimbabue"
	},
	{
		"COU_CODE" : 716,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Zimbabwe"
	},
	{
		"COU_CODE" : 716,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Zimbabwe"
	},
	{
		"COU_CODE" : 716,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Simbabwe"
	},
	{
		"COU_CODE" : 716,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Zimbabwe"
	},
	{
		"COU_CODE" : 716,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Зимбабве"
	},
	{
		"COU_CODE" : 716,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "津巴布韦"
	},
	{
		"COU_CODE" : 716,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ジンバブエ"
	},
	{
		"COU_CODE" : 716,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "زيمبابوي"
	},
	{
		"COU_CODE" : 716,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "짐바브웨"
	},
	{
		"COU_CODE" : 716,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ซิมบับเว"
	},
	{
		"COU_CODE" : 724,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Espagne"
	},
	{
		"COU_CODE" : 724,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Spain"
	},
	{
		"COU_CODE" : 724,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Spanje"
	},
	{
		"COU_CODE" : 724,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "España"
	},
	{
		"COU_CODE" : 724,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Spagna"
	},
	{
		"COU_CODE" : 724,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Espanha"
	},
	{
		"COU_CODE" : 724,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Spanien"
	},
	{
		"COU_CODE" : 724,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Hiszpania"
	},
	{
		"COU_CODE" : 724,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Испания"
	},
	{
		"COU_CODE" : 724,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "西班牙"
	},
	{
		"COU_CODE" : 724,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "スペイン"
	},
	{
		"COU_CODE" : 724,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "إسبانيا"
	},
	{
		"COU_CODE" : 724,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "스페인"
	},
	{
		"COU_CODE" : 724,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "สเปน"
	},
	{
		"COU_CODE" : 728,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Soudan du Sud"
	},
	{
		"COU_CODE" : 728,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "South Sudan"
	},
	{
		"COU_CODE" : 728,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Zuid-Soedan"
	},
	{
		"COU_CODE" : 728,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Sudán del Sur"
	},
	{
		"COU_CODE" : 728,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Sudan del Sud"
	},
	{
		"COU_CODE" : 728,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Sudão do Sul"
	},
	{
		"COU_CODE" : 728,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Südsudan"
	},
	{
		"COU_CODE" : 728,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Sudan Południowy"
	},
	{
		"COU_CODE" : 728,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Южный Судан"
	},
	{
		"COU_CODE" : 728,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "南苏丹"
	},
	{
		"COU_CODE" : 728,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "南スーダン"
	},
	{
		"COU_CODE" : 728,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "جنوب السودان"
	},
	{
		"COU_CODE" : 728,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "남수단"
	},
	{
		"COU_CODE" : 728,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เซาท์ซูดาน"
	},
	{
		"COU_CODE" : 729,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Soudan"
	},
	{
		"COU_CODE" : 729,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Sudan"
	},
	{
		"COU_CODE" : 729,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Soedan"
	},
	{
		"COU_CODE" : 729,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Sudán"
	},
	{
		"COU_CODE" : 729,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Sudan"
	},
	{
		"COU_CODE" : 729,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Sudão"
	},
	{
		"COU_CODE" : 729,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Sudan"
	},
	{
		"COU_CODE" : 729,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Sudan"
	},
	{
		"COU_CODE" : 729,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Судан"
	},
	{
		"COU_CODE" : 729,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "苏丹"
	},
	{
		"COU_CODE" : 729,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "スーダン"
	},
	{
		"COU_CODE" : 729,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "السودان"
	},
	{
		"COU_CODE" : 729,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "수단"
	},
	{
		"COU_CODE" : 729,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ซูดาน"
	},
	{
		"COU_CODE" : 740,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Suriname"
	},
	{
		"COU_CODE" : 740,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Suriname"
	},
	{
		"COU_CODE" : 740,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Suriname"
	},
	{
		"COU_CODE" : 740,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Surinam"
	},
	{
		"COU_CODE" : 740,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Suriname"
	},
	{
		"COU_CODE" : 740,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Suriname"
	},
	{
		"COU_CODE" : 740,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Suriname"
	},
	{
		"COU_CODE" : 740,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Surinam"
	},
	{
		"COU_CODE" : 740,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Суринам"
	},
	{
		"COU_CODE" : 740,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "苏里南"
	},
	{
		"COU_CODE" : 740,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "スリナム"
	},
	{
		"COU_CODE" : 740,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "سورينام"
	},
	{
		"COU_CODE" : 740,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "수리남"
	},
	{
		"COU_CODE" : 740,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ซูรินาม"
	},
	{
		"COU_CODE" : 748,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Eswatini"
	},
	{
		"COU_CODE" : 748,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Eswatini"
	},
	{
		"COU_CODE" : 748,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Swaziland"
	},
	{
		"COU_CODE" : 748,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Suazilandia"
	},
	{
		"COU_CODE" : 748,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Swaziland"
	},
	{
		"COU_CODE" : 748,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Essuatíni"
	},
	{
		"COU_CODE" : 748,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Swasiland"
	},
	{
		"COU_CODE" : 748,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Eswatini"
	},
	{
		"COU_CODE" : 748,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Эсватини"
	},
	{
		"COU_CODE" : 748,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "斯威士兰"
	},
	{
		"COU_CODE" : 748,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "エスワティニ"
	},
	{
		"COU_CODE" : 748,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "إسواتيني"
	},
	{
		"COU_CODE" : 748,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "에스와티니"
	},
	{
		"COU_CODE" : 748,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เอสวาตีนี"
	},
	{
		"COU_CODE" : 752,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Suède"
	},
	{
		"COU_CODE" : 752,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Sweden"
	},
	{
		"COU_CODE" : 752,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Zweden"
	},
	{
		"COU_CODE" : 752,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Suecia"
	},
	{
		"COU_CODE" : 752,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Svezia"
	},
	{
		"COU_CODE" : 752,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Suécia"
	},
	{
		"COU_CODE" : 752,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Schweden"
	},
	{
		"COU_CODE" : 752,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Szwecja"
	},
	{
		"COU_CODE" : 752,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Швеция"
	},
	{
		"COU_CODE" : 752,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "瑞典"
	},
	{
		"COU_CODE" : 752,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "スウェーデン"
	},
	{
		"COU_CODE" : 752,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "السويد"
	},
	{
		"COU_CODE" : 752,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "스웨덴"
	},
	{
		"COU_CODE" : 752,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "สวีเดน"
	},
	{
		"COU_CODE" : 756,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Suisse"
	},
	{
		"COU_CODE" : 756,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Switzerland"
	},
	{
		"COU_CODE" : 756,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Zwitserland"
	},
	{
		"COU_CODE" : 756,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Suiza"
	},
	{
		"COU_CODE" : 756,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Svizzera"
	},
	{
		"COU_CODE" : 756,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Suíça"
	},
	{
		"COU_CODE" : 756,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Schweiz"
	},
	{
		"COU_CODE" : 756,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Szwajcaria"
	},
	{
		"COU_CODE" : 756,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Швейцария"
	},
	{
		"COU_CODE" : 756,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "瑞士"
	},
	{
		"COU_CODE" : 756,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "スイス"
	},
	{
		"COU_CODE" : 756,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "سويسرا"
	},
	{
		"COU_CODE" : 756,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "스위스"
	},
	{
		"COU_CODE" : 756,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "สวิตเซอร์แลนด์"
	},
	{
		"COU_CODE" : 760,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Syrie"
	},
	{
		"COU_CODE" : 760,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Syrian Arab Republic"
	},
	{
		"COU_CODE" : 760,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Syrië"
	},
	{
		"COU_CODE" : 760,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Siria"
	},
	{
		"COU_CODE" : 760,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Siria"
	},
	{
		"COU_CODE" : 760,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Síria"
	},
	{
		"COU_CODE" : 760,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Syrien"
	},
	{
		"COU_CODE" : 760,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Syria"
	},
	{
		"COU_CODE" : 760,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Сирия"
	},
	{
		"COU_CODE" : 760,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "叙利亚"
	},
	{
		"COU_CODE" : 760,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "シリア・アラブ共和国"
	},
	{
		"COU_CODE" : 760,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "سوريا"
	},
	{
		"COU_CODE" : 760,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "시리아"
	},
	{
		"COU_CODE" : 760,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ซีเรีย"
	},
	{
		"COU_CODE" : 762,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Tadjikistan"
	},
	{
		"COU_CODE" : 762,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Tajikistan"
	},
	{
		"COU_CODE" : 762,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Tadzjikistan"
	},
	{
		"COU_CODE" : 762,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Tayikistán"
	},
	{
		"COU_CODE" : 762,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Tagikistan"
	},
	{
		"COU_CODE" : 762,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Tajiquistão"
	},
	{
		"COU_CODE" : 762,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Tadschikistan"
	},
	{
		"COU_CODE" : 762,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Tadżykistan"
	},
	{
		"COU_CODE" : 762,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Таджикистан"
	},
	{
		"COU_CODE" : 762,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "塔吉克斯坦"
	},
	{
		"COU_CODE" : 762,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "タジキスタン"
	},
	{
		"COU_CODE" : 762,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "طاجيكستان"
	},
	{
		"COU_CODE" : 762,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "타지키스탄"
	},
	{
		"COU_CODE" : 762,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ทาจิกิสถาน"
	},
	{
		"COU_CODE" : 762,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Taiwan"
	},
	{
		"COU_CODE" : 118,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Taiwan"
	},
	{
		"COU_CODE" : 118,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Taiwan"
	},
	{
		"COU_CODE" : 118,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Taiwan"
	},
	{
		"COU_CODE" : 118,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Taiwan"
	},
	{
		"COU_CODE" : 118,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Taiwan"
	},
	{
		"COU_CODE" : 118,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Taiwan"
	},
	{
		"COU_CODE" : 118,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Taiwan"
	},
	{
		"COU_CODE" : 118,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Taiwan"
	},
	{
		"COU_CODE" : 118,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "台湾"
	},
	{
		"COU_CODE" : 118,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "台湾"
	},
	{
		"COU_CODE" : 118,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "تايوان"
	},
	{
		"COU_CODE" : 118,
		"COU_LANGUAGE" : 15,
		"COU_NAME" : "Taiwan"
	},
	{
		"COU_CODE" : 118,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "Taiwan"
	},
	{
		"COU_CODE" : 118,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "Taiwan"
	},
	{
		"COU_CODE" : 118,
		"COU_LANGUAGE" : 18,
		"COU_NAME" : "Taiwan"
	},
	{
		"COU_CODE" : 118,
		"COU_LANGUAGE" : 20,
		"COU_NAME" : "Taiwan"
	},
	{
		"COU_CODE" : 118,
		"COU_LANGUAGE" : 21,
		"COU_NAME" : "Taiwan"
	},
	{
		"COU_CODE" : 764,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Thaïlande"
	},
	{
		"COU_CODE" : 764,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Thailand"
	},
	{
		"COU_CODE" : 764,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Thailand"
	},
	{
		"COU_CODE" : 764,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Tailandia"
	},
	{
		"COU_CODE" : 764,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Thailandia"
	},
	{
		"COU_CODE" : 764,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Tailândia"
	},
	{
		"COU_CODE" : 764,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Thailand"
	},
	{
		"COU_CODE" : 764,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Tajlandia"
	},
	{
		"COU_CODE" : 764,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Таиланд"
	},
	{
		"COU_CODE" : 764,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "泰国"
	},
	{
		"COU_CODE" : 764,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "タイ"
	},
	{
		"COU_CODE" : 764,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "تايلاند"
	},
	{
		"COU_CODE" : 764,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "태국"
	},
	{
		"COU_CODE" : 764,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ไทย"
	},
	{
		"COU_CODE" : 768,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Togo"
	},
	{
		"COU_CODE" : 768,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Togo"
	},
	{
		"COU_CODE" : 768,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Togo"
	},
	{
		"COU_CODE" : 768,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Togo"
	},
	{
		"COU_CODE" : 768,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Togo"
	},
	{
		"COU_CODE" : 768,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Togo"
	},
	{
		"COU_CODE" : 768,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Togo"
	},
	{
		"COU_CODE" : 768,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Togo"
	},
	{
		"COU_CODE" : 768,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Того"
	},
	{
		"COU_CODE" : 768,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "多哥"
	},
	{
		"COU_CODE" : 768,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "トーゴ"
	},
	{
		"COU_CODE" : 768,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "توغو"
	},
	{
		"COU_CODE" : 768,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "토고"
	},
	{
		"COU_CODE" : 768,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "โตโก"
	},
	{
		"COU_CODE" : 776,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Tonga"
	},
	{
		"COU_CODE" : 776,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Tonga"
	},
	{
		"COU_CODE" : 776,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Tonga"
	},
	{
		"COU_CODE" : 776,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Tonga"
	},
	{
		"COU_CODE" : 776,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Tonga"
	},
	{
		"COU_CODE" : 776,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Tonga"
	},
	{
		"COU_CODE" : 776,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Tonga"
	},
	{
		"COU_CODE" : 776,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Tonga"
	},
	{
		"COU_CODE" : 776,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Тонга"
	},
	{
		"COU_CODE" : 776,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "汤加"
	},
	{
		"COU_CODE" : 776,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "トンガ"
	},
	{
		"COU_CODE" : 776,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "تونغا"
	},
	{
		"COU_CODE" : 776,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "통가"
	},
	{
		"COU_CODE" : 776,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ตองงา"
	},
	{
		"COU_CODE" : 780,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Trinité-et-Tobago"
	},
	{
		"COU_CODE" : 780,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Trinidad and Tobago"
	},
	{
		"COU_CODE" : 780,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Trinidad en Tobago"
	},
	{
		"COU_CODE" : 780,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Trinidad y Tobago"
	},
	{
		"COU_CODE" : 780,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Trinidad e Tobago"
	},
	{
		"COU_CODE" : 780,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Trinidad e Tobago"
	},
	{
		"COU_CODE" : 780,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Trinidad und Tobago"
	},
	{
		"COU_CODE" : 780,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Trynidad i Tobago"
	},
	{
		"COU_CODE" : 780,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Тринидад и Тобаго"
	},
	{
		"COU_CODE" : 780,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "特立尼达和多巴哥"
	},
	{
		"COU_CODE" : 780,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "トリニダード・トバゴ"
	},
	{
		"COU_CODE" : 780,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "ترينيداد وتوباغو"
	},
	{
		"COU_CODE" : 780,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "트리니다드 토바고"
	},
	{
		"COU_CODE" : 780,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ตรินิแดดและโตเบโก"
	},
	{
		"COU_CODE" : 784,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Émirats arabes unis"
	},
	{
		"COU_CODE" : 784,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "United Arab Emirates"
	},
	{
		"COU_CODE" : 784,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Verenigde Arabische Emiraten"
	},
	{
		"COU_CODE" : 784,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Emiratos Árabes Unidos"
	},
	{
		"COU_CODE" : 784,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Emirati Arabi Uniti"
	},
	{
		"COU_CODE" : 784,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Emirados Árabes Unidos"
	},
	{
		"COU_CODE" : 784,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Vereinigte Arabische Emirate"
	},
	{
		"COU_CODE" : 784,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Zjednoczone Emiraty Arabskie"
	},
	{
		"COU_CODE" : 784,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "ОАЭ"
	},
	{
		"COU_CODE" : 784,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "阿联酋"
	},
	{
		"COU_CODE" : 784,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "アラブ首長国連邦"
	},
	{
		"COU_CODE" : 784,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "الإمارات العربية المتحدة"
	},
	{
		"COU_CODE" : 784,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "아랍에미리트"
	},
	{
		"COU_CODE" : 784,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "สหรัฐอาหรับเอมิเรตส์"
	},
	{
		"COU_CODE" : 788,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Tunisie"
	},
	{
		"COU_CODE" : 788,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Tunisia"
	},
	{
		"COU_CODE" : 788,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Tunesië"
	},
	{
		"COU_CODE" : 788,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Túnez"
	},
	{
		"COU_CODE" : 788,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Tunisia"
	},
	{
		"COU_CODE" : 788,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Tunísia"
	},
	{
		"COU_CODE" : 788,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Tunesien"
	},
	{
		"COU_CODE" : 788,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Tunezja"
	},
	{
		"COU_CODE" : 788,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Тунис"
	},
	{
		"COU_CODE" : 788,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "突尼斯"
	},
	{
		"COU_CODE" : 788,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "チュニジア"
	},
	{
		"COU_CODE" : 788,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "تونس"
	},
	{
		"COU_CODE" : 788,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "튀니지"
	},
	{
		"COU_CODE" : 788,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ตูนิเซีย"
	},
	{
		"COU_CODE" : 792,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Turquie"
	},
	{
		"COU_CODE" : 792,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Turkey"
	},
	{
		"COU_CODE" : 792,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Turkije"
	},
	{
		"COU_CODE" : 792,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Turquía"
	},
	{
		"COU_CODE" : 792,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Turchia"
	},
	{
		"COU_CODE" : 792,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Turquia"
	},
	{
		"COU_CODE" : 792,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Türkei"
	},
	{
		"COU_CODE" : 792,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Turcja"
	},
	{
		"COU_CODE" : 792,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Турция"
	},
	{
		"COU_CODE" : 792,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "土耳其"
	},
	{
		"COU_CODE" : 792,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "トルコ"
	},
	{
		"COU_CODE" : 792,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "تركيا"
	},
	{
		"COU_CODE" : 792,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "터키"
	},
	{
		"COU_CODE" : 792,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ตุรกี"
	},
	{
		"COU_CODE" : 795,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Turkménistan"
	},
	{
		"COU_CODE" : 795,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Turkmenistan"
	},
	{
		"COU_CODE" : 795,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Turkmenistan"
	},
	{
		"COU_CODE" : 795,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Turkmenistán"
	},
	{
		"COU_CODE" : 795,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Turkmenistan"
	},
	{
		"COU_CODE" : 795,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Turquemenistão"
	},
	{
		"COU_CODE" : 795,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Turkmenistan"
	},
	{
		"COU_CODE" : 795,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Turkmenistan"
	},
	{
		"COU_CODE" : 795,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Туркмения"
	},
	{
		"COU_CODE" : 795,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "土库曼斯坦"
	},
	{
		"COU_CODE" : 795,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "トルクメニスタン"
	},
	{
		"COU_CODE" : 795,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "تركمانستان"
	},
	{
		"COU_CODE" : 795,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "투르크메니스탄"
	},
	{
		"COU_CODE" : 795,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เติร์กเมนิสถาน"
	},
	{
		"COU_CODE" : 798,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Tuvalu"
	},
	{
		"COU_CODE" : 798,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Tuvalu"
	},
	{
		"COU_CODE" : 798,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Tuvalu"
	},
	{
		"COU_CODE" : 798,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Tuvalu"
	},
	{
		"COU_CODE" : 798,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Tuvalu"
	},
	{
		"COU_CODE" : 798,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Tuvalu"
	},
	{
		"COU_CODE" : 798,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Tuvalu"
	},
	{
		"COU_CODE" : 798,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Tuvalu"
	},
	{
		"COU_CODE" : 798,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Тувалу"
	},
	{
		"COU_CODE" : 798,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "图瓦卢"
	},
	{
		"COU_CODE" : 798,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ツバル"
	},
	{
		"COU_CODE" : 798,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "توفالو"
	},
	{
		"COU_CODE" : 798,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "투발루"
	},
	{
		"COU_CODE" : 798,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ตูวาลู"
	},
	{
		"COU_CODE" : 800,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Ouganda"
	},
	{
		"COU_CODE" : 800,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Uganda"
	},
	{
		"COU_CODE" : 800,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Oeganda"
	},
	{
		"COU_CODE" : 800,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Uganda"
	},
	{
		"COU_CODE" : 800,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Uganda"
	},
	{
		"COU_CODE" : 800,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Uganda"
	},
	{
		"COU_CODE" : 800,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Uganda"
	},
	{
		"COU_CODE" : 800,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Uganda"
	},
	{
		"COU_CODE" : 800,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Уганда"
	},
	{
		"COU_CODE" : 800,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "乌干达"
	},
	{
		"COU_CODE" : 800,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ウガンダ"
	},
	{
		"COU_CODE" : 800,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "أوغندا"
	},
	{
		"COU_CODE" : 800,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "우간다"
	},
	{
		"COU_CODE" : 800,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ยูกันดา"
	},
	{
		"COU_CODE" : 804,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Ukraine"
	},
	{
		"COU_CODE" : 804,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Ukraine"
	},
	{
		"COU_CODE" : 804,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Oekraïne"
	},
	{
		"COU_CODE" : 804,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Ucrania"
	},
	{
		"COU_CODE" : 804,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Ucraina"
	},
	{
		"COU_CODE" : 804,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Ucrânia"
	},
	{
		"COU_CODE" : 804,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Ukraine"
	},
	{
		"COU_CODE" : 804,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Ukraina"
	},
	{
		"COU_CODE" : 804,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Украина"
	},
	{
		"COU_CODE" : 804,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "乌克兰"
	},
	{
		"COU_CODE" : 804,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ウクライナ"
	},
	{
		"COU_CODE" : 804,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "أوكرانيا"
	},
	{
		"COU_CODE" : 804,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "우크라이나"
	},
	{
		"COU_CODE" : 804,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ยูเครน"
	},
	{
		"COU_CODE" : 807,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Macédoine du Nord"
	},
	{
		"COU_CODE" : 807,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "North Macedonia"
	},
	{
		"COU_CODE" : 807,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Noord-Macedonië"
	},
	{
		"COU_CODE" : 807,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Macedonia del Norte"
	},
	{
		"COU_CODE" : 807,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Macedonia del Nord"
	},
	{
		"COU_CODE" : 807,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Macedônia do Norte"
	},
	{
		"COU_CODE" : 807,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Nordmazedonien"
	},
	{
		"COU_CODE" : 807,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Macedonia Północna"
	},
	{
		"COU_CODE" : 807,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Северная Македония"
	},
	{
		"COU_CODE" : 807,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "北马其顿"
	},
	{
		"COU_CODE" : 807,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "北マケドニア"
	},
	{
		"COU_CODE" : 807,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "مقدونيا"
	},
	{
		"COU_CODE" : 807,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "북마케도니아"
	},
	{
		"COU_CODE" : 807,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "นอร์ทมาซิโดเนีย"
	},
	{
		"COU_CODE" : 818,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Égypte"
	},
	{
		"COU_CODE" : 818,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Egypt"
	},
	{
		"COU_CODE" : 818,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Egypte"
	},
	{
		"COU_CODE" : 818,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Egipto"
	},
	{
		"COU_CODE" : 818,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Egitto"
	},
	{
		"COU_CODE" : 818,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Egito"
	},
	{
		"COU_CODE" : 818,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Ägypten"
	},
	{
		"COU_CODE" : 818,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Egipt"
	},
	{
		"COU_CODE" : 818,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Египет"
	},
	{
		"COU_CODE" : 818,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "埃及"
	},
	{
		"COU_CODE" : 818,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "エジプト"
	},
	{
		"COU_CODE" : 818,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "مصر"
	},
	{
		"COU_CODE" : 818,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "이집트"
	},
	{
		"COU_CODE" : 818,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "อียิปต์"
	},
	{
		"COU_CODE" : 826,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Royaume-Uni"
	},
	{
		"COU_CODE" : 826,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "United Kingdom of Great Britain and Northern Ireland"
	},
	{
		"COU_CODE" : 826,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Verenigd Koninkrijk"
	},
	{
		"COU_CODE" : 826,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Reino Unido"
	},
	{
		"COU_CODE" : 826,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Regno Unito"
	},
	{
		"COU_CODE" : 826,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Reino Unido"
	},
	{
		"COU_CODE" : 826,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Vereinigtes Königreich"
	},
	{
		"COU_CODE" : 826,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Wielka Brytania"
	},
	{
		"COU_CODE" : 826,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Великобритания"
	},
	{
		"COU_CODE" : 826,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "英国"
	},
	{
		"COU_CODE" : 826,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "イギリス"
	},
	{
		"COU_CODE" : 826,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "المملكة المتحدة"
	},
	{
		"COU_CODE" : 826,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "영국"
	},
	{
		"COU_CODE" : 826,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "สหราชอาณาจักร"
	},
	{
		"COU_CODE" : 834,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Tanzanie"
	},
	{
		"COU_CODE" : 834,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Tanzania, United Republic of"
	},
	{
		"COU_CODE" : 834,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Tanzania"
	},
	{
		"COU_CODE" : 834,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Tanzania"
	},
	{
		"COU_CODE" : 834,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Tanzania"
	},
	{
		"COU_CODE" : 834,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Tanzânia"
	},
	{
		"COU_CODE" : 834,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Tansania"
	},
	{
		"COU_CODE" : 834,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Tanzania"
	},
	{
		"COU_CODE" : 834,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Танзания"
	},
	{
		"COU_CODE" : 834,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "坦桑尼亚"
	},
	{
		"COU_CODE" : 834,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "タンザニア"
	},
	{
		"COU_CODE" : 834,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "تنزانيا"
	},
	{
		"COU_CODE" : 834,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "탄자니아"
	},
	{
		"COU_CODE" : 834,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "แทนซาเนีย"
	},
	{
		"COU_CODE" : 840,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "États-Unis"
	},
	{
		"COU_CODE" : 840,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "United States of America"
	},
	{
		"COU_CODE" : 840,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Verenigde Staten"
	},
	{
		"COU_CODE" : 840,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Estados Unidos"
	},
	{
		"COU_CODE" : 840,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Stati Uniti"
	},
	{
		"COU_CODE" : 840,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Estados Unidos"
	},
	{
		"COU_CODE" : 840,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Vereinigte Staaten"
	},
	{
		"COU_CODE" : 840,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Stany Zjednoczone"
	},
	{
		"COU_CODE" : 840,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "США"
	},
	{
		"COU_CODE" : 840,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "美国"
	},
	{
		"COU_CODE" : 840,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "アメリカ合衆国"
	},
	{
		"COU_CODE" : 840,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "الولايات المتحدة"
	},
	{
		"COU_CODE" : 840,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "미국"
	},
	{
		"COU_CODE" : 840,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "สหรัฐ"
	},
	{
		"COU_CODE" : 854,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Burkina Faso"
	},
	{
		"COU_CODE" : 854,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Burkina Faso"
	},
	{
		"COU_CODE" : 854,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Burkina Faso"
	},
	{
		"COU_CODE" : 854,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Burkina Faso"
	},
	{
		"COU_CODE" : 854,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Burkina Faso"
	},
	{
		"COU_CODE" : 854,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Burkina Faso"
	},
	{
		"COU_CODE" : 854,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Burkina Faso"
	},
	{
		"COU_CODE" : 854,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Burkina Faso"
	},
	{
		"COU_CODE" : 854,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Буркина-Фасо"
	},
	{
		"COU_CODE" : 854,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "布基纳法索"
	},
	{
		"COU_CODE" : 854,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ブルキナファソ"
	},
	{
		"COU_CODE" : 854,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "بوركينا فاسو"
	},
	{
		"COU_CODE" : 854,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "부르키나파소"
	},
	{
		"COU_CODE" : 854,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "บูร์กินาฟาโซ"
	},
	{
		"COU_CODE" : 858,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Uruguay"
	},
	{
		"COU_CODE" : 858,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Uruguay"
	},
	{
		"COU_CODE" : 858,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Uruguay"
	},
	{
		"COU_CODE" : 858,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Uruguay"
	},
	{
		"COU_CODE" : 858,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Uruguay"
	},
	{
		"COU_CODE" : 858,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Uruguai"
	},
	{
		"COU_CODE" : 858,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Uruguay"
	},
	{
		"COU_CODE" : 858,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Urugwaj"
	},
	{
		"COU_CODE" : 858,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Уругвай"
	},
	{
		"COU_CODE" : 858,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "乌拉圭"
	},
	{
		"COU_CODE" : 858,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ウルグアイ"
	},
	{
		"COU_CODE" : 858,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "الأوروغواي"
	},
	{
		"COU_CODE" : 858,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "우루과이"
	},
	{
		"COU_CODE" : 858,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "อุรุกวัย"
	},
	{
		"COU_CODE" : 860,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Ouzbékistan"
	},
	{
		"COU_CODE" : 860,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Uzbekistan"
	},
	{
		"COU_CODE" : 860,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Oezbekistan"
	},
	{
		"COU_CODE" : 860,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Uzbekistán"
	},
	{
		"COU_CODE" : 860,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Uzbekistan"
	},
	{
		"COU_CODE" : 860,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Uzbequistão"
	},
	{
		"COU_CODE" : 860,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Usbekistan"
	},
	{
		"COU_CODE" : 860,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Uzbekistan"
	},
	{
		"COU_CODE" : 860,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Узбекистан"
	},
	{
		"COU_CODE" : 860,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "乌兹别克斯坦"
	},
	{
		"COU_CODE" : 860,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ウズベキスタン"
	},
	{
		"COU_CODE" : 860,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "أوزبكستان"
	},
	{
		"COU_CODE" : 860,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "우즈베키스탄"
	},
	{
		"COU_CODE" : 860,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "อุซเบกิสถาน"
	},
	{
		"COU_CODE" : 862,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Venezuela"
	},
	{
		"COU_CODE" : 862,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Venezuela (Bolivarian Republic of)"
	},
	{
		"COU_CODE" : 862,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Venezuela"
	},
	{
		"COU_CODE" : 862,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Venezuela"
	},
	{
		"COU_CODE" : 862,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Venezuela"
	},
	{
		"COU_CODE" : 862,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Venezuela"
	},
	{
		"COU_CODE" : 862,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Venezuela"
	},
	{
		"COU_CODE" : 862,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Wenezuela"
	},
	{
		"COU_CODE" : 862,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Венесуэла"
	},
	{
		"COU_CODE" : 862,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "委内瑞拉"
	},
	{
		"COU_CODE" : 862,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ベネズエラ・ボリバル共和国"
	},
	{
		"COU_CODE" : 862,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "فنزويلا"
	},
	{
		"COU_CODE" : 862,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "베네수엘라"
	},
	{
		"COU_CODE" : 862,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เวเนซุเอลา"
	},
	{
		"COU_CODE" : 882,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Samoa"
	},
	{
		"COU_CODE" : 882,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Samoa"
	},
	{
		"COU_CODE" : 882,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Samoa"
	},
	{
		"COU_CODE" : 882,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Samoa"
	},
	{
		"COU_CODE" : 882,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Samoa"
	},
	{
		"COU_CODE" : 882,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Samoa"
	},
	{
		"COU_CODE" : 882,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Samoa"
	},
	{
		"COU_CODE" : 882,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Samoa"
	},
	{
		"COU_CODE" : 882,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Самоа"
	},
	{
		"COU_CODE" : 882,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "萨摩亚"
	},
	{
		"COU_CODE" : 882,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "サモア"
	},
	{
		"COU_CODE" : 882,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "ساموا"
	},
	{
		"COU_CODE" : 882,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "사모아"
	},
	{
		"COU_CODE" : 882,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "ซามัว"
	},
	{
		"COU_CODE" : 887,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Yémen"
	},
	{
		"COU_CODE" : 887,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Yemen"
	},
	{
		"COU_CODE" : 887,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Jemen"
	},
	{
		"COU_CODE" : 887,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Yemen"
	},
	{
		"COU_CODE" : 887,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Yemen"
	},
	{
		"COU_CODE" : 887,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Iêmen"
	},
	{
		"COU_CODE" : 887,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Jemen"
	},
	{
		"COU_CODE" : 887,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Jemen"
	},
	{
		"COU_CODE" : 887,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Йемен"
	},
	{
		"COU_CODE" : 887,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "也门"
	},
	{
		"COU_CODE" : 887,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "イエメン"
	},
	{
		"COU_CODE" : 887,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "اليمن"
	},
	{
		"COU_CODE" : 887,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "예멘"
	},
	{
		"COU_CODE" : 887,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "เยเมน"
	},
	{
		"COU_CODE" : 894,
		"COU_LANGUAGE" : 1,
		"COU_NAME" : "Zambie"
	},
	{
		"COU_CODE" : 894,
		"COU_LANGUAGE" : 2,
		"COU_NAME" : "Zambia"
	},
	{
		"COU_CODE" : 894,
		"COU_LANGUAGE" : 3,
		"COU_NAME" : "Zambia"
	},
	{
		"COU_CODE" : 894,
		"COU_LANGUAGE" : 4,
		"COU_NAME" : "Zambia"
	},
	{
		"COU_CODE" : 894,
		"COU_LANGUAGE" : 5,
		"COU_NAME" : "Zambia"
	},
	{
		"COU_CODE" : 894,
		"COU_LANGUAGE" : 8,
		"COU_NAME" : "Zâmbia"
	},
	{
		"COU_CODE" : 894,
		"COU_LANGUAGE" : 9,
		"COU_NAME" : "Sambia"
	},
	{
		"COU_CODE" : 894,
		"COU_LANGUAGE" : 10,
		"COU_NAME" : "Zambia"
	},
	{
		"COU_CODE" : 894,
		"COU_LANGUAGE" : 11,
		"COU_NAME" : "Замбия"
	},
	{
		"COU_CODE" : 894,
		"COU_LANGUAGE" : 12,
		"COU_NAME" : "赞比亚"
	},
	{
		"COU_CODE" : 894,
		"COU_LANGUAGE" : 13,
		"COU_NAME" : "ザンビア"
	},
	{
		"COU_CODE" : 894,
		"COU_LANGUAGE" : 14,
		"COU_NAME" : "زامبيا"
	},
	{
		"COU_CODE" : 894,
		"COU_LANGUAGE" : 16,
		"COU_NAME" : "잠비아"
	},
	{
		"COU_CODE" : 894,
		"COU_LANGUAGE" : 17,
		"COU_NAME" : "แซมเบีย"
	}
];
  
