import * as React from 'react';
import { FooterWrapper, ListFooterLink } from './styled';
import { Link } from 'react-router-dom';

import {FormattedMessage, useIntl} from 'react-intl';


const Footer:React.FunctionComponent = () => {

  const intl = useIntl();
  
    return <FooterWrapper>
      <ListFooterLink>
        <li><span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'gate_txt_abusive'})}}></span></li>
      </ListFooterLink>
      <ListFooterLink className="right">
        <li><a href="https://www.meemento.com" target="_blank"><FormattedMessage id="global_txt_footer_editedby"/></a></li>
        <li><a href="https://www.meemento.com/docs/ml_mc.pdf" target="_blank"><FormattedMessage id="global_txt_footer_legal"/></a></li>
        <li><a href="https://www.meemento.com/docs/cgu_mc.pdf" target="_blank"><FormattedMessage id="global_txt_footer_tandc"/></a></li>
        <li><a href="https://www.meemento.com/docs/pc_mc.pdf" target="_blank"><FormattedMessage id="global_txt_footer_privacy"/></a></li>
      </ListFooterLink>
      
    </FooterWrapper>;
}

export default Footer;