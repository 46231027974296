import * as React from 'react';
import Header from '../widgets/header';
import Footer from '../widgets/footer';
import Main from '../widgets/main';
import Wrapper from '../widgets/wrapper';
import Help from '../widgets/help';
import Languages from '../widgets/languages';
import { useIntl } from 'react-intl';
import {Helmet} from "react-helmet";



const Layout:React.FunctionComponent<{pureBG?: boolean}> = ({children, pureBG = false}) => {
    const intl = useIntl();
    return <Wrapper>
        <Helmet>
            <title>{intl.formatMessage({id: 'global_txt_titlepage'})}</title>
        </Helmet>
        <Main pureBG={pureBG}>
            <Help />
            <Languages />
            <Header />
            {children}
        </Main>
        <Footer />
    </Wrapper>;
}

export default Layout;