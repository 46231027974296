import styled from "styled-components";

export const FooterWrapper = styled.footer`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0px;
  background-color: rgba(255,255,255,1);
  img{
    height: 30px;
    width: auto;
    vertical-align: middle;
  }
  @media (max-width: 991px){
    position: relative;
    left: inherit;
    bottom: inherit;
    margin-top: 0px;
    text-align: center;
    display: inline-block;
    justify-content: inherit;
    width: 100%;
    background-color: #fff;
  }
`;

export const ListFooterLink = styled.ul`
  margin: 0px;
  padding: 0px 10px;
  @media (max-width: 991px){
    width: 100%;
    padding: 0px;
  }
  &.right{
    text-align: right;
    float: right;
    width: 50%;
    @media (max-width: 991px){
        float: inherit;
        text-align: right;
      }
    li{
      text-align: right;
      float: right;
      &:first-child{
        margin-bottom: 5px;
      }
      @media (max-width: 991px){
        float: inherit;
        text-align: right;
        display: inline-block;
      }
    }
  }
  span{
    color: #000;
    text-transform: uppercase;
    font-weight: 900;
  }
  li {
    list-style: none;
    float: left;
    padding: 0px 4px;
    font-size: 13px;
    color: #000;
    @media (max-width: 991px){
        float: inherit;
        text-align: center;
        display: inline-block;
      }
    &:last-child{
      border-right: 0px;
    }
    a{
      color: #000;
      text-decoration: inherit;
      margin: 0px;
    }
  }
`;