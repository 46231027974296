import styled from "styled-components";
import background from './images/background_mobile_pure.jpg';
import purePackground from './images/background_mobile_pure.jpg';
import backgroundDesktop from './images/background_desktop.jpg';
import pureBackgroundDesktop from './images/background_desktop_pure.jpg';

export const MainWrapper = styled.div`
  background-color: #f9f8f6;
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  
`;

export const VideoBackground = styled.video`
    position: absolute;
    right: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    background-size: cover;
    object-fit: cover;
  @media (max-width: 1400px){
    
  }
  @media (max-width: 991px){
    display: none;
  }
`;

export const PictureBackground = styled.div<{pureBG: boolean}>`
    position: absolute;
    left: 0;
    top: 0;
    min-width: 100%;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    background-size: cover;
    object-fit: cover;
    background-image: url(${props => props.pureBG ? pureBackgroundDesktop : backgroundDesktop});
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;
  @media (max-width: 991px){
    background-image: url(${props => props.pureBG ? purePackground : background});
    background-position: bottom center;
  }
`;


export const VideoBackgroundMobile = styled.div`
  position: absolute;
  background-image: url(${background});
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
    height: auto;
  min-width: 100%;
    min-height: 100%;
  object-fit: cover;
  @media (min-width: 992px){
    display: none;
  }
`;

