import styled, { css } from "styled-components";
import { colors } from './../../variables';

export const ConfirmWrapper = styled.div`
  width: 400px;
  background-color: #fff;
  border-radius: 20px;
  position: relative;
  margin-top: 120px;
  z-index: 30;
  left: 50%;
  margin-left: -200px;
  box-shadow: 0 0 80px -1px rgba(0,0,0,.4);
  @media (max-width: 991px){
    position: relative;
    margin-top: 80px;
  }
  @media (max-width: 767px){
    margin-top: 110px;
  }
  @media (max-width: 468px){
    position: relative;
    margin: 0 auto;
    margin-top: 90px;
    width: 95%;
    left: 50%;
    margin-left: -47.5%;
  }
`;

export const Sep = styled.div`
  height: 1px;
  width: 100%;
  margin: 25px 0px;
  background-color: #ccc;
`;

export const ConfirmContent = styled.div`
  padding: 40px 40px;
  color: #000;
  text-align: center;
  button{
    display: inline-block;
    @media (max-width: 991px){
      padding: 0px 50px;
    }
  }
`;

export const ConfirmTitle = styled.div`
  margin-top: 20px;
  font-size: 18px;
  text-transform: uppercase;
`;

export const ConfirmMessage = styled.div`
  margin-top: 20px;
  font-size: 12px;
  text-transform: uppercase;
`;

export const ResumeVideo = styled.div`
  background-color: #000;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  height: auto!important;
  
  margin: 0 auto;
  margin-top: 0px;
  border: 1px solid #aaa;
  @media (max-width: 767px){
    width: 100%;
    height: 200px;
  }
`;

export const ShareTitle = styled.div`
  font-size: 12px;
  font-weight: 300;
`;

export const ShareList = styled.ul`
  margin: 0px;
  padding: 0px;
  margin-top: 15px;
`;

export const ShareListItem = styled.li<{color: string}>`
  list-style: none;
  display: inline-block;
  margin: 5px 5px;
  color: #fff;
  
  a{
    background-color: ${props => props.color};
    color: #fff;
    display: flex;
    font-size: 11px;
    padding: 5px 10px;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
  }
  img{
    height: 15px;
    width: auto;
    margin-right: 5px;
  }
`;