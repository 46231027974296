import * as React from 'react';
import Layout from '../layout';
import {FieldsRequired, Terms, SubtitleHide, InputDateIcon, MessageMobileTitle, MessageWrapper, MessageResumeVideo, MessageFormContainer, MessageResumeVideoContainer, MessageForm, InputGroup, InputField, Sep, FormInfo, SendType, SendTypeItem, InputFieldSpecial, AdditionalMessage, AdditionalMessageCancel, InputTextarea, InputGroupArea, Checkbox, CheckboxLabel, CheckboxGroup, PlanWrapper, PlanSelect, PlanOption, PlanSelectElement, PlanSelectTitle, ButtonGroup, PreviewMobile, PreviewClose, PreviewTitle, ErrorInfo, PreviewMessage, PreviewVideo, InputInfo, InputInfoIcon, StepNumber, SendSelect, SendSelectElement, SendOption, SendReinsurance} from './styled';
import { Button, ButtonAddMessage, AlignCenter, AlignRight } from '../../widgets/globaleStyledWidget';
import { GateSelect, GateOption } from '../gate/styled';
import { colors } from '../../variables';
import { getYear, getMonth, differenceInYears } from 'date-fns';
import DatePicker, { ReactDatePickerProps } from "react-datepicker";

import { Link, useHistory } from 'react-router-dom';

import icoMail from './images/ico-mail.svg';
import icoPhone from './images/ico-phone.svg';
import icoAdd from './images/ico-add.svg';
import icoClock from './images/ico-clock.svg';
import icoDate from './images/ico-calendar.svg';

import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import livstickApp, { language } from '../../models/livstick';

import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import manifest from '../../manifest';
import { COUNTRIES, COUNTRIES_DETAILS } from '../../lang/countries';
import { Observer } from 'mobx-react';



const videoJsOptions = {
  controls: true,
  bigPlayButton: true,
  fluid: true,
};

const range = (start, stop, step = 1) =>
  Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)

const MessagePage:React.FunctionComponent = () => {
  const history = useHistory();
  
  const intl = useIntl();

  const videoEl = React.useRef(null);
  const videoEl2 = React.useRef(null);

  const [gateDate, setGateDate] = React.useState<Date>();
  const [plannification, setPlannification] = React.useState<Date>();

  React.useEffect(() => {
    console.log('ref', videoEl)
    var _player = videojs(videoEl.current, videoJsOptions, () => {
      const version_info = 'Using video.js ' + videojs.VERSION;
      videojs.log(version_info);
      console.log('videoFile', livstickApp.videoFile)
      var fileUrl = URL.createObjectURL(livstickApp.videoFile);
      var fileType = livstickApp.videoFile.type;
      _player.src({ type: fileType, src: fileUrl });
      _player.load();
      //_player.play();
      _player.loop();
    });
    
  }, [])

  const [sendTypeIndex, setSendTypeIndex] = React.useState<number>(0);
  const [TextareaActive, setTextareaActive] = React.useState<boolean>(false);
  const [ScheduleActive, setScheduleActive] = React.useState<boolean>(false);
  const [gateCountry, setGateCountry] = React.useState<Number>();

  const [yearPicker, setYearPicker] = React.useState<boolean>(true);
  const [yearLimit, setYearLimit] = React.useState<number>(18);
  const [showSpecificDate, setShowSpecificDate] = React.useState(false);

  /* Checkbox Plannification envoi */
  const [isPlanToggled, setPlanToggled] = React.useState<boolean>(false);
  const togglePlanTrueFalse = () => {
    livstickApp.planificationTime = !isPlanToggled;
    setPlanToggled(!isPlanToggled);
  }

  /* Checlbox CGU */
  const [isCGUToggled, setCGUToggled] = React.useState<boolean>(false);
  const toggleCGUTrueFalse = () => setCGUToggled(!isCGUToggled);

  /* Checlbox CGU */
  const [isCGU2Toggled, setCGU2Toggled] = React.useState<boolean>(false);
  const toggleCGU2TrueFalse = () => {
    livstickApp.checkbox2 = !isCGU2Toggled ? 1 : 0;
    setCGU2Toggled(!isCGU2Toggled);
  }
  
  /* Preview Mobile / Tablette */
  const [PreviewActive, setPreviewActive] = React.useState<boolean>(false);
  const openPreview = () => { setPreviewActive(true); }
  const closePreview = () => { setPreviewActive(false); }

  /* Info Input */
  const [InfoActive, setInfoActive] = React.useState<boolean>(false);
  const openInfo = () => { setInfoActive(true); }
  const closeInfo = () => { setInfoActive(false); }

  /* Info Input Number */
  const [InfoNumberActive, setInfoNumberActive] = React.useState<boolean>(false);
  const openNumberInfo = () => { setInfoNumberActive(true); }
  const closeNumberInfo = () => { setInfoNumberActive(false); }

  const [error, setError] = React.useState(false);
  const [error2, setError2] = React.useState(false);
  const [error3, setError3] = React.useState(false);
  const [error4, setError4] = React.useState(false);

  

  const openSendType = (index: number) => {
    setSendTypeIndex(index);
  }

  const openTextarea = () => {
    setTextareaActive(true);
  }

  const closeTextarea = () => {
    setTextareaActive(false);
  }

  const openSchedule = () => {
    setScheduleActive(true);
  }

  const closeSchedule = () => {
    livstickApp.planification = null;
    setScheduleActive(false);
  }

  const goToMessage = () => {
    history.push('/record');
  }

  const goToRecord = () => {
    history.push('/record');
  }

  const goToUpload = () => {
    console.log(livstickApp.senderEmail, isCGUToggled, livstickApp.senderEmail != null && isCGUToggled);
    if (!isCGUToggled) setError2(true); else setError2(false);
    if (!livstickApp.senderEmail) setError(true); else setError(false);
    if (gateCountry == null || gateDate == null) setError3(true); else setError3(false);
    if (gateDate && differenceInYears(new Date(), gateDate) < yearLimit){
      return setError4(true)
    } 
    if (livstickApp.senderEmail != null && gateCountry != null && gateDate != null && isCGUToggled) history.push('/upload');
    
  }


  const [startDate, setStartDate] = React.useState(new Date());
  const years = range(1900, getYear(new Date()) + 1, 1);
  const months = {
    'fr': [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Aout",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre"
    ],
    'en': [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    'en-US': [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    'en-UK': [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    'es': [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    'it': [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre"
    ],
    'de': [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    'zh': [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    'ja': [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
};
  return <Layout pureBG={livstickApp.currentCountry == 'FR'}>
    
    {/* <PreviewMobile active={PreviewActive == false}>
      <PreviewClose onClick={() => closePreview()}></PreviewClose>
      <PreviewTitle><FormattedMessage id="form_txt_title_preview_mobile"/></PreviewTitle>
      <PreviewVideo>
        <video id="myVideo" ref={videoEl2} className="video-js vjs-default-skin" playsInline></video>
      </PreviewVideo>
      <Button onClick={() => closePreview()} color={colors.secondary} hover="secondary"><FormattedMessage id="form_txt_close_preview_mobile"/></Button>
    </PreviewMobile> */}
    <Observer>{() => 
    <MessageWrapper>
      <MessageResumeVideoContainer>
        <h2><FormattedMessage id="form_txt_title_form"/></h2>
        <MessageResumeVideo>
          <video id="myVideo" ref={videoEl} className="video-js vjs-default-skin" playsInline></video>
        </MessageResumeVideo>
        <Button color={colors.black} hover="black" onClick={goToMessage}><FormattedMessage id="player_txt_button_retry"/></Button>
      </MessageResumeVideoContainer>
      <StepNumber><FormattedMessage id="global_txt_step"/> 2/2</StepNumber>
      <MessageMobileTitle><h2><FormattedMessage id="form_txt_complete_form"></FormattedMessage></h2></MessageMobileTitle>
      <MessageFormContainer>
        
        <MessageForm>

          <h3><FormattedMessage id="form_txt_from"/></h3>
          <InputGroup>
            <InputField placeholder={intl.formatMessage({id: "form_txt_from_fname"})} onChange={(e) => { livstickApp.firstname = e.target.value; }}></InputField> {/* variable : form_txt_from_fname */}
            <InputField placeholder={intl.formatMessage({id: "form_txt_from_lname"})} onChange={(e) => { livstickApp.lastname = e.target.value; }}></InputField> {/* variable : form_txt_from_lname */}
          </InputGroup>
          <InputGroup>
            <InputField placeholder={intl.formatMessage({id: "form_txt_from_email"})} onChange={(e) => { livstickApp.senderEmail = e.target.value; }}></InputField> {/* variable : form_txt_from_email */}
            <InputInfoIcon onMouseEnter={() => openInfo()} onMouseLeave={() => closeInfo()} active={InfoActive == true}></InputInfoIcon>
            {/* <InputInfo active={InfoActive == true}><FormattedMessage id="form_txt_to_reinsurance_email"/></InputInfo> */}
          </InputGroup>
          <InputGroup className="">
            <PlanSelectElement>
              <GateSelect onChange={e => {
                    setGateCountry(parseInt(e.currentTarget.value));
                    var _country = COUNTRIES_DETAILS.find(_d => parseInt(e.currentTarget.value) == _d.COD_ID);
                    livstickApp.country = _country.COD_CODE.toString();
                    setYearLimit(parseInt(_country.COD_AGE_ALCOOL));
                    setYearPicker(_country.COD_FULL_BIRTHDAY == 0);
                  }}>
                <GateOption>{intl.formatMessage({id: "form_txt_from_country"})}</GateOption>
                {COUNTRIES_DETAILS.map(_d => {
                  var _s = COUNTRIES.find(__s => __s.COU_CODE == _d.COD_CODE && language.codeInt == __s.COU_LANGUAGE );
                  return _s != null ? <GateOption value={_d.COD_ID}>{_s.COU_NAME}</GateOption> : null;
                })}
              </GateSelect>
            </PlanSelectElement>
            <PlanSelectElement>
              <DatePicker locale="fr-FR" dateFormat="dd/MM/yyyy" selected={gateDate} onChange={date => {
                livstickApp.birth = date.toString();
                setGateDate(date as Date);
              }} placeholderText={intl.formatMessage({id: "gate_txt_dateofbirth"})} withPortal
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    {/* <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                      {"<"}
                    </button> */}
                                        
                    <select
                      onChange={({ target: { value } }) =>
                        changeMonth((months[language.code] || months['en']).indexOf(value))
                      }
                    >
                      {(months[language.code] || months['en']).map(option => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <select
                      value={getYear(date)}
                      onChange={({ target: { value } }) => changeYear(parseInt(value))}
                    >
                      {years.map(option => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
          
                    {/* <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                      {">"}
                    </button> */}
                  </div>
                )}
              />
            </PlanSelectElement>
          </InputGroup>
          
          {error && <ErrorInfo><FormattedMessage id="form_txt_error1"/></ErrorInfo>}
          {error3 && <ErrorInfo><FormattedMessage id="form_txt_error3"/></ErrorInfo>}
          {error4 && <ErrorInfo><FormattedMessage id="gate_txt_denied"/></ErrorInfo>}
          { manifest.VM && livstickApp.answer != true && <>
          <h3><FormattedMessage id="form_txt_to" /></h3>
            <SendSelectElement>
              <SendSelect onChange={e => {
                if (e.currentTarget.value == "email"){
                  setSendTypeIndex(0);
                }else{
                  setSendTypeIndex(1);
                }
              }}>
                <SendOption value="email">{intl.formatMessage({id:'form_txt_to_bymail'})}</SendOption>
                <SendOption value="phone">{intl.formatMessage({id:'form_txt_to_byphone'})}</SendOption>
              </SendSelect>
            { sendTypeIndex == 0 && <InputFieldSpecial placeholder={intl.formatMessage({id: "form_txt_to_email"})} onChange={(e) => { livstickApp.email = e.target.value; }}></InputFieldSpecial> }
            { sendTypeIndex == 1 && <InputFieldSpecial placeholder={intl.formatMessage({id: "form_txt_to_phone"})} onChange={(e) => { livstickApp.phone = e.target.value; }}></InputFieldSpecial> }
            
            </SendSelectElement>
            {/* <FieldsRequired><FormattedMessage id="form_txt_to_reinsurance_sms" /></FieldsRequired> */}
            {/* 
              @MATTHIEU : POUR LE SEND BY SMS la variable placeholder est form_txt_to_phone
            <SendReinsurance>Texte réassurance</SendReinsurance>
          <SendType>
            <SendTypeItem active={sendTypeIndex == 0} onClick={() => openSendType(0)}><img src={icoMail} /><FormattedMessage id="form_txt_to_bymail" defaultMessage="Envoyer par mail"/></SendTypeItem>
            <SendTypeItem active={sendTypeIndex == 1} onClick={() => openSendType(1)}><img src={icoPhone} /><FormattedMessage id="form_txt_to_byphone" defaultMessage="Envoyer par SMS"/></SendTypeItem>
          </SendType>
          {sendTypeIndex == 0 && <div>
            <InputGroup>
              <InputFieldSpecial placeholder={intl.formatMessage({id: "form_txt_from_email"})} onChange={(e) => { livstickApp.email = e.target.value; }}></InputFieldSpecial> 
            </InputGroup>
          </div>}
          {sendTypeIndex == 1 && <div>
            <InputGroup>
              <InputFieldSpecial placeholder={intl.formatMessage({id: "form_txt_from_phone"})}></InputFieldSpecial> 
              <InputInfoIcon onMouseEnter={() => openNumberInfo()} onMouseLeave={() => closeNumberInfo()} active={InfoNumberActive == true}></InputInfoIcon>
              <InputInfo active={InfoNumberActive == true}><FormattedMessage id="form_txt_to_reinsurance_sms" defaultMessage="Reassurance Mobile (Default)"/></InputInfo>
            </InputGroup>
          </div>} */}
          </>}
          {/* <ErrorInfo>Veuillez saisir un ou plusieurs destinataires.</ErrorInfo> */}
          <Sep/>
          <ButtonAddMessage onClick={() => openTextarea()} active={TextareaActive == false}><FormattedMessage id="form_txt_add_message_title" /></ButtonAddMessage>
          <AdditionalMessage active={TextareaActive == true}>
            <h3 className="h3-add-message"><FormattedMessage id="form_txt_add_message_title"/></h3>
            <AdditionalMessageCancel onClick={() => closeTextarea()}><FormattedMessage id="form_txt_add_message_cancel"/></AdditionalMessageCancel>
          </AdditionalMessage>
          <InputGroupArea active={TextareaActive == true}>
            <InputTextarea placeholder={intl.formatMessage({id: "form_txt_add_message_placeholder"})} onChange={(e) => { livstickApp.message = e.target.value; } }></InputTextarea> {/* variable : form_txt_add_message_placeholder */}
          </InputGroupArea>
          { manifest.VM && <>
          <ButtonAddMessage onClick={() => openSchedule()} active={ScheduleActive == false}><FormattedMessage id="form_txt_schedule" /></ButtonAddMessage>
          <SubtitleHide active={ScheduleActive == true}>
            <h3 className="h3-add-message"><FormattedMessage id="form_txt_schedule"/></h3>
            <AdditionalMessageCancel onClick={() => closeSchedule()}><FormattedMessage id="form_txt_add_message_cancel"/></AdditionalMessageCancel>
          </SubtitleHide>
          <PlanWrapper active={ScheduleActive == true}>
            <PlanSelectElement>
              <PlanSelectTitle><img src={icoDate} /> <FormattedMessage id="form_txt_schedule_date" defaultMessage="Date"/></PlanSelectTitle>
                <InputGroup>
                  <DatePicker locale="fr-FR" dateFormat="dd/MM/yyyy" selected={plannification} withPortal onChange={date => {
                    setPlannification(date as Date);
                      livstickApp.planification = date as Date;
                    }
                      
                    }

                  />
                </InputGroup>
            </PlanSelectElement>
            <PlanSelectElement>
              <PlanSelectTitle><img src={icoClock} /> <FormattedMessage id="form_txt_schedule_time" defaultMessage="Heure"/></PlanSelectTitle>
              <PlanSelect onChange={(e) => {
                console.log(e.currentTarget.value);
                var date = plannification;
                date.setHours(parseInt(e.currentTarget.value));
                console.log(date);
                livstickApp.planification = date as Date;
                setPlannification(date);
              }}>
                <PlanOption value={8}>08:00</PlanOption>
                <PlanOption value={12}>12:00</PlanOption>
                <PlanOption value={16}>16:00</PlanOption>
                <PlanOption value={20}>20:00</PlanOption>
              </PlanSelect>
            </PlanSelectElement>
          </PlanWrapper>
          </>}
          <Sep/>
          

          <Terms dangerouslySetInnerHTML={{__html:intl.formatMessage({id: 'form_txt_terms_mc'})}}></Terms>
          <CheckboxGroup onClick={toggleCGUTrueFalse} active={isCGUToggled == true}>
            <Checkbox/>
            <CheckboxLabel  dangerouslySetInnerHTML={{__html:intl.formatMessage({id: 'form_txt_checkbox1'})}}></CheckboxLabel>
          </CheckboxGroup>
          <CheckboxGroup onClick={toggleCGU2TrueFalse} active={isCGU2Toggled == true}>
            <Checkbox/>
            <CheckboxLabel><FormattedMessage id="form_txt_checkbox2" /></CheckboxLabel>
          </CheckboxGroup>
          {/* <ButtonGroup>
            <Button color={colors.main} onClick={goToRecord}><FormattedMessage id="player_txt_button_retry"/></Button>
            <Button onClick={() => openPreview()} color={colors.main}><FormattedMessage id="form_txt_button_preview"/></Button>
          </ButtonGroup> */}
          {error2 && <ErrorInfo><FormattedMessage id="form_txt_error2" defaultMessage="Error 2"/></ErrorInfo>}
          <AlignRight>
            <Button color={colors.secondary} hover="secondary" onClick={ goToUpload }><FormattedMessage id="form_txt_send"/></Button>
          </AlignRight>
          <AlignRight>
            <FieldsRequired><FormattedMessage id="form_txt_mandatory"/></FieldsRequired>
          </AlignRight>
        </MessageForm>
      </MessageFormContainer>
    </MessageWrapper>
    }</Observer>
  </Layout>
}

export default injectIntl(MessagePage);