import styled, { css } from "styled-components";
import icoInfo from './images/ico-info.svg';
import icoArrowDown from './images/ico-down.svg';
import icoDate from './images/ico-calendar.svg';
import { colors } from './../../variables';

export const GateWrapper = styled.div`
  width: 500px;
  background-color: #fff;
  border-radius: 0px;
  position: relative;
  margin-top: 80px;
  z-index: 30;
  left: 50%;
  margin-left: -250px;
  box-shadow: 0 0 80px -1px rgba(0,0,0,.4);
  img{
    height: 50px;
    margin-top: 20px;
    width: auto;
    vertical-align: top;
  }
  .react-datepicker__year-wrapper {
    max-width: 205px;
  }

  .react-datepicker-year-header {
    height: 30px;
    padding-top: 13px;
  }

  @media (max-width: 991px){
    position: relative;
    margin-top: 80px;
    
  }
  @media (max-width: 767px){
    margin-top: 110px;
    width: 340px;
    margin-left: -170px;
    
  }
  @media (max-width: 468px){
    position: relative;
    margin: 0 auto;
    margin-top: 90px;
    width: 95%;
    left: 50%;
    margin-left: -47.5%;
  }
`;

export const Sep = styled.div`
  height: 1px;
  width: 100%;
  margin: 25px 0px;
  background-color: #ccc;
`;

export const GateContent = styled.div`
  padding: 40px 40px;
  color: #000;
  text-align: center;
  @media (max-width: 767px){
    padding: 20px;
    
  }
`;

export const GateTitle = styled.div`
  font-weight: normal;
  margin-top: 20px;
  font-size: 18px;
  padding: 0px 40px;
`;

export const GateMessage = styled.div`
  font-weight: 300;
  font-size: 12px;
  margin-top: 20px;
  padding: 10px 40px;
`;

export const GateAbusive = styled.div`
  font-weight: 300;
  font-size: 12px;
  padding: 10px 20px;
`;

export const GateFormContainer = styled.div`
  
`;

export const GateForm = styled.div`
  padding: 20px 40px;
  color: #000;
  @media (max-width: 767px){
      padding: 20px;
    }
  h3{
    margin-bottom: 20px;
  }
`;

export const ButtonGroup = styled.div`
  text-align: center;
    button{
      padding: 15px 20px;
      font-size: 14px;
      margin: 0px;
      margin-top: 10px;
      width: 100%;
      text-align: center;
      justify-content: center;
      @media (max-width: 991px){
        button{
          width: 100%;
        }
      }
      @media (max-width: 767px){
        flex: inherit;
        font-size: 12px;
        line-height: inherit;
      }
  }
`;

export const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  input {
    padding: 18px 20px;
    border-radius: 0px;
    color: #000;
    width: 100%;
    display: block;
    position: relative;
    font-size: 14px;
    border: 1px solid #ccc;
    &.error{
      border: 1px solid red;
    }
    span{
      position: absolute;
      right: 5px;
    }
    @media (max-width: 767px){
      padding: 10px 20px;
    }
    &:focus{
      outline: inherit;
    }
  }
  .react-datepicker-wrapper{
    width: 100%;
    
    input{
      box-sizing: border-box;
      @media (max-width: 767px){
      padding: 16px 20px;
    }
    }
  }
  .react-datepicker__tab-loop{
    .react-datepicker-popper{
      z-index: 40;
    }
  }
`;

export const InputField = styled.input`
  padding: 18px 20px;
  border-radius: 0px;
  color: #000;
  width: 100%;
  display: block;
  position: relative;
  font-size: 14px;
  border: 1px solid #ccc;
  &.error{
    border: 1px solid red;
  }
  span{
    position: absolute;
    right: 5px;
  }
  @media (max-width: 767px){
    padding: 10px 20px;
  }
  &:focus{
    outline: inherit;
  }
`;
export const InputDateIcon = styled.div`
  position: absolute;
  right: 20px;
  top: 16px;
  background-image: url(${icoDate});
  background-size: 20px auto;
  background-position: center center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  opacity: 0.4;
  @media (max-width: 767px){
    top: 12px;
  }
`;
export const InputDate = styled.div`
  position: absolute;
  right: 0px;
  bottom: -90px;
  background-color: #222;
  width: 200px;
  padding: 20px;
  border-radius: 0px;
  font-size: 11px;
  font-style: italic;
  color: #fff;
  z-index: 9999;
  -webkit-box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 56px 8px rgba(0,0,0,0.25);
  &:after{
    width: 0;
    height: 0;
    content: '';
    position: absolute;
    top: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #222;
    right: 17px;
    margin-left: 15px;
  }
`;

export const GateSelectTitle = styled.div`
  color: #000;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 5px;
  align-items: center;
  display: flex;
  img{
    height: 20px;
    margin-right: 10px;
    width: auto;
    @media (max-width: 991px){
      height: 15px;
    }
  }
`;
export const GateSelectElement = styled.div`
  width: 100%;
  margin: 5px;
  @media (max-width: 767px){
    margin-bottom: 10px;
  }
  &:first-child{
    margin-left: 0px;
  }
  &:last-child{
    margin-right: 0px;
  }
`;
export const GateSelect = styled.select`
    background-size: 14px auto;
    background-position: 92%;
    background-image: url(${icoArrowDown});
    background-repeat: no-repeat;
    background-color: transparent;
    color: #000;
    border-color: #e3e3e3;
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    height: 50px;
    font-size: 14px;
    position: relative;
    padding: 12px 10px 12px 20px;
    
    @media (max-width: 991px){
      font-size: 12px;
    }
    &:focus{
      outline: inherit;
    }
    &:before{
      
    }
    &:after{
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      right: 10px;
      top: 10px;
      background-size: 20px auto;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url(${icoArrowDown});
    }
    
`;
export const GateOption = styled.option`
  
`;


export const ListGateLink = styled.ul`
  margin: 0px;
  padding: 0px;
  li {
    display: block;
    padding: 0px 10px;
    font-size: 10px;
    color: #000;
    &:last-child{
      border-right: 0px;
    }
    a{
      color: #000;
      text-decoration: inherit;
    }
  }
`;