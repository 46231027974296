import { colors } from './../../variables';
import styled from "styled-components";

export const HeaderWrapper = styled.header`
  height: 50px;
  /* background-color: ${colors.back}; */
  position: relative;
  @media (max-width: 991px){
    text-align: center;
  }
`;

export const Logo = styled.img`
  width: 220px;
  height: auto;
  position: relative;
  top: 20px;
  left: 50%;
  margin-left: -110px;
  @media (max-width: 991px){
    width: 150px;
    position: relative;
    top: inherit;
    left: inherit;
    text-align: center;
    margin: 0 auto;
    top: 20px;
  }
`;

export const Catchline = styled.h1`
  position: relative;
  top: 50px;
  left: 260px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  @media (max-width: 991px){
    position: relative;
    text-align: center;
    left: inherit;
    font-size: 28px;
    top: 30px;
    position: relative;
  }
  @media (max-width: 767px){
    font-size: 20px;
    top: 20px;
  }
`;