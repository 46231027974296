import * as React from 'react';
import { MainWrapper, VideoBackground, VideoBackgroundMobile, PictureBackground } from './styled';
import videoMp4 from './images/vm-final.mp4';
import videoWebm from './images/vm-final.webm';
import videoMp4Mobile from './images/mobile.mp4';
import videoWebmMobile from './images/mobile.webm';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";


const Main:React.FunctionComponent<{pureBG: boolean}> = ({children, pureBG}) => {
    return <MainWrapper>
        <BrowserView>
        <PictureBackground pureBG={pureBG}></PictureBackground>
        </BrowserView>
        <MobileView>
            <VideoBackgroundMobile></VideoBackgroundMobile>
        </MobileView>
        {children}
    </MainWrapper>;
}

export default Main;