import * as React from "react";
import Layout from "../layout";
import {
  GateWrapper,
  GateMessage,
  GateTitle,
  GateContent,
  ListGateLink,
  GateFormContainer,
  GateForm,
  InputField,
  InputGroup,
  ButtonGroup,
  InputDateIcon,
  InputDate,
  GateSelectElement,
  GateSelect,
  GateOption,
  GateAbusive,
} from "./styled";
import { Button } from "../../widgets/globaleStyledWidget";
import { colors } from "../../variables";
import icoDate from "./images/ico-calendar.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { match, useHistory } from "react-router-dom";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { getYear, getMonth, differenceInYears, compareDesc } from "date-fns";
import { ErrorInfo, PlanSelectTitle } from "../message/styled";
import { COUNTRIES, COUNTRIES_DETAILS } from "../../lang/countries";
import { Observer } from "mobx-react";
import livstickApp, { language } from "../../models/livstick";
import manifest from "../../manifest";
import { table } from "console";

interface Props extends Omit<ReactDatePickerProps, "onChange"> {
  onClick?(): void;
  onChange?(): void;
}

interface DetailParams {
  id: string;
}

interface DetailsProps {
  match?: match<DetailParams>;
}

const range = (start, stop, step = 1) =>
  Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step);

const GatePage: React.FunctionComponent<DetailsProps> = ({ match }) => {
  const intl = useIntl();

  const [isPure, setIsPure] = React.useState<boolean>(true);

  const history = useHistory();

  const [gateDate, setGateDate] = React.useState<Date>();
  const [gateCountry, setGateCountry] = React.useState<Number>();
  const [yearPicker, setYearPicker] = React.useState<boolean>(true);
  const [yearLimit, setYearLimit] = React.useState<number>(18);
  const [error, setError] = React.useState(false);
  const [arr, setArr] = React.useState<any[]>([]);

  const [showSpecificDate, setShowSpecificDate] = React.useState(false);

  const [startDate, setStartDate] = React.useState(new Date());
  const years = range(1900, getYear(new Date()) + 1, 1);

  const start = async () => {
    const country = await livstickApp.getCountry();

    setIsPure(country.iso_code == "FR");
  };

  React.useState(() => {
    start();
  });

  const cou_fav = [
    840,
    826,
    392,
    250,
    724,
    276,
    380,
    756,
    56,
    203,
    40,
    156,
    643,
  ];

  const months = {
    fr: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Aout",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
    en: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    "en-US": [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    "en-UK": [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    es: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    it: [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ],
    de: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    zh: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    ja: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  };

  const Custom = ({ value, onClick, ...rest }) => {
    return (
      <InputField
        placeholder={intl.formatMessage({ id: "gate_txt_dateofbirth" })}
        onClick={onClick}
        value={value}
      ></InputField>
    );
  };

  let arr_fav = [];
  let arr_pas_fav = [];
  let countriesList = [];

  const getAllCountry = COUNTRIES.filter((_c) => {
    if (_c.COU_LANGUAGE === language.codeInt) {
      return _c;
    }
  });

  for (let i = 0; i < getAllCountry.length; i++) {
    let c = getAllCountry[i];

    if (cou_fav.includes(c.COU_CODE)) {
      arr_fav.push(c);
    } else {
      arr_pas_fav.push(c);
    }
  }

  const sortMergeCountries = (fav: any[], other: any[]) => {
    fav.sort((a, b) => {
      return new Intl.Collator(language.code).compare(a.COU_NAME, b.COU_NAME);
    });

    other.sort((a, b) => {
      return new Intl.Collator(language.code).compare(a.COU_NAME, b.COU_NAME);
    });

    return (countriesList = fav.concat(other));
  };

  sortMergeCountries(arr_fav, arr_pas_fav);

  const goToLanding = async () => {
    console.log(gateDate, differenceInYears(gateDate, new Date()));
    if (
      gateDate &&
      differenceInYears(new Date(), gateDate) > yearLimit &&
      gateCountry
    ) {
      if (match.params.id) livstickApp.code = match.params.id;
      else
        return manifest.VM ? history.push("/record") : history.push("/landing");

      const _res = await livstickApp.pull();
      if (_res.media_url != null) history.push("/view/" + match.params.id);
      else {
        livstickApp.code = _res.ref;
        return history.push("/record");
      }
    } else setError(true);
  };

  console.log("test", language.code);
  console.log("countriesList", countriesList);

  return (
    <Layout pureBG={isPure}>
      <Observer>
        {() => (
          <GateWrapper>
            <GateContent>
              <GateTitle>
                <FormattedMessage id="gate_txt_title1" />
              </GateTitle>
              <GateMessage>
                <FormattedMessage id="gate_txt_title2" />
              </GateMessage>
              <GateFormContainer>
                <GateForm>
                  <GateSelectElement>
                    <GateSelect
                      onChange={(e) => {
                        setGateCountry(parseInt(e.currentTarget.value));
                        var _country = COUNTRIES_DETAILS.find(
                          (_d) => parseInt(e.currentTarget.value) == _d.COD_ID
                        );
                        setYearLimit(parseInt(_country.COD_AGE_ALCOOL));
                        setYearPicker(_country.COD_FULL_BIRTHDAY == 0);
                      }}
                    >
                      <GateOption>
                        {intl.formatMessage({ id: "form_txt_from_country" })}
                      </GateOption>

                      {countriesList.map((c) => {
                        var c_details = COUNTRIES_DETAILS.find(_c => _c.COD_CODE === c.COU_CODE)
                        
                        return (
                          <GateOption value={c_details.COD_ID}>{c.COU_NAME}</GateOption>
                        );
                      })}
                    </GateSelect>
                  </GateSelectElement>
                  <InputGroup>
                    {yearPicker ? (
                      <DatePicker
                        locale="fr-FR"
                        dateFormat="yyyy"
                        showYearPicker
                        selected={gateDate}
                        onChange={(date) => {
                          setGateDate(date as Date);
                          if (
                            (date as Date).getFullYear() ==
                            2020 - yearLimit
                          ) {
                            setShowSpecificDate(true);
                          } else {
                            setShowSpecificDate(false);
                          }
                        }}
                        placeholderText={intl.formatMessage({
                          id: "form_txt_yob",
                        })}
                        withPortal
                      />
                    ) : (
                      <DatePicker
                        locale="fr-FR"
                        dateFormat="dd/MM/yyyy"
                        selected={gateDate}
                        onChange={(date) => setGateDate(date as Date)}
                        placeholderText={intl.formatMessage({
                          id: "gate_txt_dateofbirth",
                        })}
                        withPortal
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {/* <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                          {"<"}
                        </button> */}

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(
                                  months[language.code].indexOf(value)
                                )
                              }
                            >
                              {months[language.code].map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={getYear(date)}
                              onChange={({ target: { value } }) =>
                                changeYear(parseInt(value))
                              }
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            {/* <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                          {">"}
                        </button> */}
                          </div>
                        )}
                      />
                    )}

                    <InputDateIcon></InputDateIcon>
                  </InputGroup>
                  <InputGroup>
                    {showSpecificDate && (
                      <DatePicker
                        locale="fr-FR"
                        dateFormat="dd/MM/yyyy"
                        selected={gateDate}
                        onChange={(date) => setGateDate(date as Date)}
                        placeholderText={intl.formatMessage({
                          id: "gate_txt_dateofbirth",
                        })}
                        withPortal
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {/* <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                          {"<"}
                        </button> */}

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(
                                  months[language.code].indexOf(value)
                                )
                              }
                            >
                              {months[language.code].map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={getYear(date)}
                              onChange={({ target: { value } }) =>
                                changeYear(parseInt(value))
                              }
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            {/* <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                          {">"}
                        </button> */}
                          </div>
                        )}
                      />
                    )}
                  </InputGroup>
                  {error && (
                    <ErrorInfo>
                      <FormattedMessage id="gate_txt_denied" />
                    </ErrorInfo>
                  )}
                  <ButtonGroup>
                    <Button
                      color={colors.main}
                      hover="main"
                      onClick={goToLanding}
                    >
                      <FormattedMessage id="gate_txt_button" />
                    </Button>
                  </ButtonGroup>
                </GateForm>
              </GateFormContainer>
              <GateAbusive
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: "gate_txt_abusive" }),
                }}
              ></GateAbusive>
            </GateContent>
          </GateWrapper>
        )}
      </Observer>
    </Layout>
  );
};

export default GatePage;
